(function($, B) {
    'use strict';  

    B.showForm = $.extend(true, {}, B.basicForm, {
        options: {
            expBtns: true,
            formBind: {
                changed: '_eChange',
                submit: '_eSubmit'
            },
            selectors: {
                preview: '[data-preview]',
                links: '[data-link]'
            }
        },
        _createControls: function() {
            B.basicWidget._createControls.call(this);
            this._createFields();
            this._createElements(['preview', 'links']);
            return this;
        },
        _createLinks:function(){
            var $btns = this._findElement('links'),
                links; 
            if(B.obj.is$($btns)){
                links = {}; 
                $btns.each(function(){
                    var $this = $(this),
                        linkType= $this.data('link');
                    links[linkType] = $this;
                });
            }
            return links;
        },
        _eSubmit: function(e, data) {
            stopTrigger(e);
            var action = B.obj.is(data) && data.hasOwnProperty('action') ? data.action : 'submit';
            var that = this,
                o = this.options,
                $form = this.element,
                url = $form.attr('action'),
                fdata = B.obj.is(data) && B.obj.is(data.fdata) ? data.fdata : this.getFormData();
            if (this.state(action, data) !== 'submit') {
                return;                   
            }
            this.$preview.loadDataPreview({ ajax : {
                type: $form.attr('method'),
                url: url,
                data: fdata
            } }, function(entityData, ajaxData){
                that.setEntityData(entityData);
                that._refreshEntityUrls(ajaxData.entityUrls);
                that.state('submitSuccess');
                $form.trigger('submited', data);
            }, function(){
                that.state('submitError');                   
            });
        },
        _refreshEntityUrls:function(entityUrls){
            if(B.obj.is(entityUrls)){
                for(var i in this.$links){
                    if(entityUrls.hasOwnProperty(i)){
                        this.$links[i].attr('href', entityUrls[i]).show();
                    }else{
                        this.$links[i].hide();
                    }
                }
            }
        },
        _setState:function(state, data){
            this._state = state;
            switch ( this._state  ) {
                case 'form-serialize':
                    this.block(false);
                break;
                case 'init':
                    break;
                case 'start':
                    this.block(false);
                    this.$submit.popover('hide');
                    this._setChanged(false);
                    this._showExports();
                    break;
                case 'normal':
                    this.block(false);
                    this.$submit.popover('hide');
                    this._showExports();
                    break;
                case 'changing':
                    this._showExports('clear');
                    break;
                case 'validate':
                    break;
                case 'confirm':
                    this.changed = false;
                    this._setConfirm(true);
                    break;
                case 'submit':
                    this.block(true);
                    break;
                case 'submitSuccess':
                    this.block(false);
                    this._setChanged(false);
                    this._showExports();
                    break;
                case 'submitError':
                    this.block(false);
                    break;
            }
        }
    });

    $.widget(
        'bajt.showForm',
        $.extend(true, {}, B.showForm, {
            options: {}
        })
    );

    $.fn.initShowForm = function() {
        var $form = $(this).find('form[data-form=show]');
        if (B.obj.is$($form)) {
            $form.showForm();
        }
    };
})(jQuery, Bajt);
