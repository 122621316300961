var DT = {
    options: {
        infoKeys: [ 'error', 'danger', 'warning', 'info', 'success'],
        infoIcons: {
            info: '',
            warning: 'bell-o',
            danger: 'warning',
            error: 'error'
        },
        selTypes: {
            unselected: '0',
            selected: '1',
            added: '2',
            included: '3'
        },
        selName: 'sel',

        rowSelectors:{
        }
    },
    _getDataValue: function(data){
        return B.obj.is(data)
            ? Bajt.obj.getValueOneOfKeys(['v', 'id', 'value'], data, '')
            : data;
    },
    _getMetaOptions: function(meta){
        return meta.settings.oInit;
    },
    _getMetaECN: function(meta){
        return meta.settings.oInit.ecn;
    },
    _getMetaColumnOptions:function(meta){
        return $.extend(true, {
            name: null,
            empty: '',
            value: '',
            dataKey: null,
            key: 'n',
            separator: ' ',
            prefix: '',
            suffix: '',
            tmpl: false,
            class: null,
            icon: null,
            title: '__v__',
            titleKey: 'n',
            action: null

        }, meta.settings.oInit.columns[meta.col] || {});
    },
    _checkInfo:function(value, infoTable){
        var info;
        for(var i in DT.options.infoKeys){
            var key = DT.options.infoKeys[i];
            if(infoTable.hasOwnProperty(key) && ((info = B.checkLimits(infoTable[key], value)) !== null)){
                info.type = info.type || key;
                if(info.icon && !B.str.is(info.icon)){
                    info.icon = DT.options.infoIcons[info.type] || null;
                }               
                info.class = info.class || 'disp-' + info.type;
                break;
            }
        }
        return info;
    },
    clearFilters: function(table) {
        table.settings()[0].oInit.filters = {};
        table.ajax.reload();
    },
    setTableSettings:function(table, name, value){
        var settings = DT.getTableSettings(table);
        settings[name]=value;
    },
    getTableSettings:function(table){
        return table.settings()[0].oInit;
    },
    getEntityClassName:function(table){
        return DT.getTableSettings(table).ecn;
    },
    getFromElement:function($element){
        return (B.obj.is$($element))
            ? $element.data('dataTable') || dataTables[$element.data('table')] || null
            : null;
    },
    getInfoFromRowElement:function($el){
        var $row = $el.closest('tr'),
            $table = $row.closest('table'),
            dataTable = $table.DataTable(),
            tableSettings = dataTable.settings()[0].oInit,
            entitySettings = Bajt.obj.is(tableSettings.entitySettings) ? tableSettings.entitySettings : Bajt.getEntitySettings(tableSettings.ecn);
        return {
            dataTable: dataTable,
            tableSettings : tableSettings,
            entitySettings: entitySettings,
            ecn: entitySettings.ecn,
            en: entitySettings.en,
            rowData: dataTable.row($row).data()
        };
    },
    convertRowsData: function(rows, options, customConvert) {
        var that = this,
            importFields = options.importFields || ['id'],
            convertRows = [];
        rows.every(function(rowIdx, tableLoop, rowLoop) {
            convertRows.push(B.entity.export.apply(that, [this.data(), options.ecn, importFields, customConvert]));
        });
        return convertRows;
    },
    exportRowsColumnData: function(rows, columnName){
        var eRows = [];
        rows.every(function(rowIdx, tableLoop, rowLoop) {
            var rowData = this.data();
            eRows.push(rowData[columnName]);
        });
        return eRows;
    },
    exportRowsData: function(rows, exportOptions){
        if(!B.obj.is(exportOptions)){
            exportOptions = { };
        }
        exportOptions.ecn = DT.getEntityClassName(rows);
        var eRows = [];
        rows.every(function(rowIdx, tableLoop, rowLoop) {
            eRows.push(B.entity.export(this.data(), exportOptions));
        });
        return eRows;
    },
    setFilter: function(table, name, filter) {
        var topt = DT.getTableSettings(table);
        if (filter !== undefined) {
            if (!Bajt.obj.is(topt.filters)) {
                topt.filters = {};
            }
            if (!Bajt.obj.is(topt.filters[name])) {
                topt.filters[name] = { name: name };
            }
            if (Bajt.obj.is(filter) && filter.hasOwnProperty('value')) {
                $.extend(true, topt.filters[name], filter);
            } else {
                topt.filters[name].value = filter;
            }
        } else if (Bajt.obj.is(topt.filters) && topt.filters.hasOwnProperty(name)) {
            delete topt.filters[name];
        }
        table.ajax.reload();
    },
    setFilters: function(table, filters) {
        var topt = DT.getTableSettings(table);
        if (Bajt.obj.is(filters)) {
            topt.filters = $.extend({}, topt.filters || {});
            //     , {
            //     client: {
            //         value: client.id
            //     }
            // });
        } else if (topt.filters && topt.filters.hasOwnProperty('client')) {
            delete topt.filters['client'];
        }
    },
    setClient: function(table, clientId){
        table.settings()[0].oInit.clientId = clientId || null;
    },
    getClient: function(table){
        return table.settings()[0].oInit.clientId || null;
    },
    getFilterBar: function(table) {
        return filterBars[DT.getTableName(table)] || null;
    },
    getToolBar: function(table) {
        return toolBars[DT.getTableName(table)] || null;
    },
    getTableName:function(table){
        return DT.getTableSettings(table).name;
    },
    get$FilterBar: function(table) {
        var filter=DT.getFilterBar(table);
        return B.obj.is(filter) ? filter.element : null;
    },
    rows: function(table, rowSelector, options) {
        return B.isFunction(table.every) ? table : DT.getRows(table, rowSelector, options);
    },
    getRows:function(table, rowSelector, options) {
        var rowSelectors = $.extend( {}, DT.options.rowSelectors, Bajt.obj.getValue('rowSelectors', options, {}));
        if (Number.isInteger(rowSelector) || Array.isArray(rowSelector)) {
            rowSelector = DT.genRowsIdSelector(table, rowSelector, options);
        } else if (typeof rowSelector === 'string' && rowSelectors.hasOwnProperty(rowSelector)) {
            rowSelector = rowSelectors[rowSelector];
        }
        return rowSelector ? table.rows( rowSelector !== 'all' ? rowSelector : null ) : null;
    },
    genRowsIdSelector:function(table, values, options) {
        var rowPrefix = table.settings()[0].oInit.rowPrefix || Bajt.obj.getValue('rowPrefix', options, 'id_'),
            ids = Array.isArray(values) ? values : [values],
            s = [];
        for (var i = 0; i < ids.length; i++) {
            s.push('#' + rowPrefix + ids[i]);
        }
        return s.join();   
    },
    getRowsByColumnValue:function(table, values, column, not){
        column = column || 'id';
        not = not !== undefined ? not : false;
        var r=table.rows(function(idx, data, node){
            var i = B.array.findValue(values, data[column]);
            return not ? i < 0 : i >=0;
        });
        return table.rows(function(idx, data, node){
            var i = B.array.findValue(values, data[column]);
            return not ? i < 0 : i >=0;
        });  
    },
    filter: function(data, settings) {
        var o = settings.oInit,
            filterBar = filterBars[o.name],
            filters = filterBar ? $.extend(filterBar.value(), o.filters || {}) : o.filters || {};
        return $.isPlainObject(filters) && !$.isEmptyObject(filters)
            ? {
                  f: JSON.stringify(filters)
              }
            : null;
        //    var $filterbar=$(this.filterbar),
        //        filters={};
        //    if (typeof(this.filters) != 'undefined'){
        //        $.extend(true, filters, this.filters);
        //    }
        //    $filterbar.find('.form-control').each(function(index, element){
        //        var field=getFieldName(element),
        //            filter=$(element).val();
        //        if (filter !== ''){
        //            filters[field]=filter;
        //        }
        //    });
        //    return filters;
    },
    select: function(table, selType, rowSelector, options) {
        return DT.toggleRowSelect(DT.rows(table, rowSelector, options), selType || 'selected' );
    },
    unselect: function(table, rowSelector, options) {
        return DT.toggleRowSelect(DT.rows(table, rowSelector, options), 'unselected');
    },
    toggleRowSelect: function(rows, selType) {
        if(B.obj.is(rows)){
            rows.every(function(rowIdx, tableLoop, rowLoop) {
                var data = this.data();
                data.sel = selType;
                data.pos_sel = selType;
                this.data(data);
            });
        }   
        return rows;
    },
    hide: function(table, rowSelector, options) {
        DT.toggleRowVisibility(DT.rows(table, rowSelector, options), true);    
    },
    unhide: function(table, rowSelector, options) {
        DT.toggleRowVisibility(DT.rows(table, rowSelector, options), false);    
    },
    toggleRowVisibility:function(rows, hide){
        if(B.obj.is(rows)){
            rows.nodes().to$().css({ display: hide ? 'none' : 'table-row' });
        }
        return rows;
    },
    getTable:function(name){
        return dataTables[name] || null;
    },
    reloadTables:function(names){
        var en = Array.isArray(names) ? names : [names];
        for(var i in en){
            var dt = this.getTable(en[i]);
            if(dt){
                dt.ajax.reload();
            }    
        }
    },
    _checkAction:function($action, actionsFilter, rowData){
        var o = $action.data();
        if(actionsFilter[o.actionName]){
            var limit = B.checkLimits(actionsFilter[o.actionName], null, rowData);
            if(limit){
                switch(limit.do){
                    case 'disable':
                        $action.after(
                            $('<span>')
                                .attr('data-action-name', $action.attr('data-action-name'))
                                .addClass($action.attr('class'))
                                .addClass(limit.do)
                        )
                        .remove();
                    break;
                    case 'hide':
                        $action.remove();
                    break; 
                }
            }
        }
    },
    _isActionPermitted:function(name, tableOptions, rowData){
        var permited = true,
            actions = tableOptions.customActions || {};
        if(actions.hasOwnProperty(name)){

        }
        return permited;
    },
    rowCallBack: {
        select: function(row, data, index, options) {
            var DTo = $.extend(true, {}, DT.options, options || {} ),
                selType = B.obj.getValue(DTo.selName, data, 'unselected'),
                $row = $(row);
            $row.removeClassSearch(DTo.selClassPrefix);
            if (selType !== 'unselected') {
                $row.attr('data-' + DTo.selName, selType);
                $row.addClass(DTo.selClasses[selType]);
            } else {
                $row.removeAttr('data-' + DTo.selName);
            }
        }
    },   
    renders: {
        _dispTitle: function(value, titleOptions) {
            var title = $.extend({
                    valueKey: 'n'                
                },
                B.obj.is(titleOptions) ? titleOptions : { tmpl: titleOptions });
            if(title.msg){
                return title.msg;
            }
            if (Bajt.obj.is(value)) {
                value = value.hasOwnProperty(title.valueKey) ? value[title.valueKey] : JSON.stringify(value);
            }
            return B.str.dispValue(value || '', title.tmpl || '');
        },    
        _display: function(data, options, meta, name) {
            name = options.name || B.getFullFieldName(options.data, DT._getMetaECN(meta));
            var 
                _disp = function(val) {
                    var disp = options.empty,
                        id = options.value;
                    if (Bajt.obj.is(val)) {
                        disp = val.hasOwnProperty(options.key) ? val[options.key] : JSON.stringify(val);
                        id = DT._getDataValue(val);
                    }else if(isSet(val)){
                        disp = val;
                    }
                    disp = options.prefix + disp + options.suffix;
                    if (options.tmpl) {
                        var tmpl = typeof options.tmpl === 'string' ? options.tmpl : '<span class="label"></span>',
                            $el = $(tmpl);
                        $el.html(disp);
                        if (options.dispTitle) {
                            $el.attr('title', DT.renders._dispTitle(val, options.dispTitle));
                        }
                        if (Bajt.str.is(options.icon)) {
                            $('<i class="material-icons" >' + options.icon + '</i').prependTo($el);
                        }
                        $el.addClass((
                            B.str.is(options.class) 
                                ? options.class 
                                : options.class === false 
                                    ? '' 
                                    : 'disp-' + name
                            ) + ' disp');
                        $el.attr('data-value', id);
                        return $el[0].outerHTML;
                    }
                    return disp;
                };
            if (Array.isArray(data)) {
                var shows = [];
                for (var i = 0; i < data.length; i++) {
                    shows.push(_disp(data[i]));
                }
                return shows.join(options.separator);
            }
            return _disp(data);
        },   
        generic: function(data, type, full, meta) {
            var 
                val, 
                co = DT._getMetaColumnOptions(meta),
                _convert = function(value){
                    return (undefined === value || null === value ) 
                        ? ''
                        : co.dataKey
                            ? B.obj.getValue(co.dataKey, value, '')
                            : value;
                };
            if (Array.isArray(data)) {
                val = [];
                for (var i = 0; i < data.length; i++) {
                    val.push(_convert(data[i]));
                }
            } else {
                val = _convert(data);
            }
            return DT.renders._display(val, co, meta);
        },
        concat: function(data, type, full, meta) {
            return '';
        },
        sel: function(data, type, full, meta) {
        },
        area: function(data, type, full, meta) {
            return B.str.fixed(data, 3);
        },
        empty: function(data, type, full, meta) {
            return isSet(data) ? data : '';
        },
        active: function(data, type, full, meta) {
            return B.html.get('icon', { icon: data ? 'check_box' : 'check_box_outline_blank' });
        },
        notnull: function(data, type, full, meta) {
            return B.html.get('icon', { icon: data !== null ? 'check_box' : 'check_box_outline_blank' });
        },
        price: function(data, type, full, meta) {
            return (
                '<span class="netto">' +
                B.str.fixed(data, 2) +
                '</span><span class="brutto">' +
                B.str.fixed(data * 1.23, 2) +
                '</span>'
            );
        },
        netto: function(data, type, full, meta) {
            return (
                '<span class="netto">' +
                B.str.fixed(data, 2) +
                '</span>'
            );
        },
        brutto: function(data, type, full, meta) {
            return (
                '<span class="brutto">' +
                B.str.fixed(data * 1.23, 2) +
                '</span>'
            );
        },
        date: function(data, type, full, meta) {
            var co = DT._getMetaColumnOptions(meta);
                // info = B.obj.getValue('entitySettings.info.term', o, { error: [{ condition: { c: 'lt', v: 0 }, msg: 'Upłynął termin' }] }),
            return DT.renders._display(B.str.date(data, co), co, meta);
        },
        time: function(data, type, full, meta) {
            var co = DT._getMetaColumnOptions(meta);
            co.showTime = true;
            return DT.renders._display(B.str.date(data, co), co, meta);
        },
        term: function(data, type, full, meta) {
            return DT.renders._dispTerm(data, DT._getMetaColumnOptions(meta), meta);
        },
        termDays: function(data, type, full, meta) {
            return DT.renders._dispTerm(data, $.extend({dispDays: true }, DT._getMetaColumnOptions(meta)), meta);
        },
        _dispTerm: function(data, co, meta){
            if (isSet(data)) {
                var o = DT._getMetaOptions(meta),
                    inf,
                    info = B.obj.getValue('entitySettings.info.term', o, {
                        error: [{ condition: { c: 'lt', v: 0 }, msg: 'Upłynął termin' }],
                        danger: [{ condition: { c: 'lt', v: 1 }, msg: 'Jutro upływa termin' }],
                        warning: [{ condition: { c: 'lt', v: 5 }, msg: 'Zbliża się termin' }]
                    }),
                    days = B.moment(data).diff(moment(), 'days');
                co.tmpl = co.tmpl !== false ? co.tmpl : true;
                data = co.dispDays ? days : B.str.date(data, co);
                if (Bajt.obj.is(info)) {
                    if (inf = DT._checkInfo(days, info)) {
                        co.icon = inf.icon;
                        co.dispTitle = { msg: inf.msg };
                        co.value = inf.type;
                        co.class = B.str.addUnique(B.str.is(co.class) ? co.class : '' , inf.class);
                    }
                }
            }else{
                data = '';
            }
            return DT.renders._display(data, co, meta);
        },
        action: function(data, type, full, meta){
            return '';
        },
        actions: function(data, type, full, meta) {
            var o = DT._getMetaOptions(meta),
                co = DT._getMetaColumnOptions(meta),
                re = new RegExp(co.idPrototype || '__id__', 'g'),
                tmpl = co.tmpl.replace(re, full.id),
                $actions = $('<div/>');
                if(o.clientId){
                    tmpl = B.url.modifyClient(tmpl, o.clientId);
                }
                $actions.html(tmpl);
                $actions.find('a').each(function(){
                    DT._checkAction($(this), o.cactions || {}, 
                        function(fieldName){
                            return B.getFieldValue(full, fieldName, o.ecn);
                        }
                    );
                });
            //     _customAction = function(action, type) {
            //         var $a = $actions.find('.btn-' + action);
            //         if (Bajt.obj.is$($a)) {
            //             $a
            //                 .after(
            //                     $('<span>')
            //                         .addClass($a.attr('class'))
            //                         .addClass(type)
            //                 )
            //                 .remove();
            //         }
            //     },
            //     _checkConditions = function(check, value) {
            //         var ok = false;
            //         if (Array.isArray(check.condition)) {
            //             var ok1 = !check.hasOwnProperty('logical') || check.logical === 'and';
            //             ok = ok1;
            //             for (var k = 0; ok === ok1 && k < check.condition.length; k++) {
            //                 ok = B.compareValues(check.condition[k].c, value, check.condition[k].v);
            //             }
            //         } else {
            //             ok = B.compareValues(check.condition.c, value, check.condition.v);
            //         }
            //         return ok;
            //     },
            //     _check = function(custom) {
            //         var ok1 = !custom.hasOwnProperty('logical') || custom.logical === 'and',
            //             ok = ok1;
            //         for (var l = 0; ok === ok1 && l < custom.fields.length; l++) {
            //             var val = B.getFieldValue(full, custom.fields[l].name, o.ecn);
            //             ok = _checkConditions(custom.fields[l].check, val);
            //         }
            //         return ok;
            //     };
            // if (Bajt.obj.is(o.customActions)) {
            //     for (var c in o.customActions) {
            //         for (var i = 0; i < o.customActions[c].length; i++) {
            //             var ca = o.customActions[c][i];
            //             if (_check(ca)) {
            //                 if (Array.isArray(ca.actions)) {
            //                     for (var j = 0; j < ca.actions.length; j++) {
            //                         _customAction(ca.actions[j], c);
            //                     }
            //                 } else {
            //                     _customAction(ca.actions, c);
            //                 }
            //             }
            //         }
            //     }
            // }
            return $actions.html();
        },
        // actions: function(data, type, full, meta) {
        //     var o = DT._getMetaOptions(meta),
        //         co = DT._getMetaColumnOptions(meta),
        //         re = new RegExp(co.idPrototype || '__id__', 'g'),
        //         $actions = $('<div/>').html(co.tmpl.replace(re, full.id)),
        //         _customAction = function(action, type) {
        //             var $a = $actions.find('.btn-' + action);
        //             if (Bajt.obj.is$($a)) {
        //                 $a
        //                     .after(
        //                         $('<span>')
        //                             .addClass($a.attr('class'))
        //                             .addClass(type)
        //                     )
        //                     .remove();
        //             }
        //         },
        //         _checkConditions = function(check, value) {
        //             var ok = false;
        //             if (Array.isArray(check.condition)) {
        //                 var ok1 = !check.hasOwnProperty('logical') || check.logical === 'and';
        //                 ok = ok1;
        //                 for (var k = 0; ok === ok1 && k < check.condition.length; k++) {
        //                     ok = B.compareValues(check.condition[k].c, value, check.condition[k].v);
        //                 }
        //             } else {
        //                 ok = B.compareValues(check.condition.c, value, check.condition.v);
        //             }
        //             return ok;
        //         },
        //         _check = function(custom) {
        //             var ok1 = !custom.hasOwnProperty('logical') || custom.logical === 'and',
        //                 ok = ok1;
        //             for (var l = 0; ok === ok1 && l < custom.fields.length; l++) {
        //                 var val = B.getFieldValue(full, custom.fields[l].name, o.ecn);
        //                 ok = _checkConditions(custom.fields[l].check, val);
        //             }
        //             return ok;
        //         };
        //     if (Bajt.obj.is(o.customActions)) {
        //         for (var c in o.customActions) {
        //             for (var i = 0; i < o.customActions[c].length; i++) {
        //                 var ca = o.customActions[c][i];
        //                 if (_check(ca)) {
        //                     if (Array.isArray(ca.actions)) {
        //                         for (var j = 0; j < ca.actions.length; j++) {
        //                             _customAction(ca.actions[j], c);
        //                         }
        //                     } else {
        //                         _customAction(ca.actions, c);
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     return $actions.html();
        // },
        progress: function(data, type, full, meta) {
            return DT.renders._dispProgress(data, DT._getMetaColumnOptions(meta), meta);
        },
        _dispProgress: function(data, options, meta) {
            var 
                v = B.obj.getValue('value', data, data),
                max = B.obj.getValue('max', data, 100),
                progress = B.obj.getValue('progress', data, 100 * v / max),
                progressColor = options.progressColor,
                progressText = B.obj.getValue('progressText', options),
                $bar = $('<div class="progress"><div class="progress-bar"></div></div'),
                $progress = $bar.find('.progress-bar');   
            $progress.css('width', progress + '%');
            if (!isSet(progressText)) {
                switch (options.progressType) {
                    case 'of':
                        progressText = v + ' z ' + max;
                        break;
                    default:
                        progressText = parseInt(progress, 10) + '%';
                }
            }
            if (isSet(progressText)) {
                $progress.text(progressText);
            }
            if (progressColor) {
                if (Bajt.obj.is(progressColor)) {
                    var range = Object.keys(progressColor),
                        key = range[0];
                    for (var i = 1; i < range.length; i++) {
                        if (range[i] > progress) {
                            break;
                        }
                        key = range[i];
                    }
                    progressColor = progressColor[key];
                }
                $progress.addClass('bg-' + progressColor);
            }
            return $bar[0].outerHTML;
        },    
        childParams: function(data, type, full, meta) {
            var 
                ecn = DT._getMetaECN(meta),
                co = $.extend({
                    paramName: '',
                    childName: '',
                    key: 's'
                }, DT._getMetaColumnOptions(meta)),
                child_ecn = B.entity.getChildClass(co.childName, ecn),
                childs = B.getFieldValue(full, co.childName, ecn),
                html = '',
                params = {};
            if (Array.isArray(childs)) {
                var dic = B.dic.get(co.dicName || co.paramName, child_ecn);
                for (var i = 0; i < childs.length; i++) {
                    var param = DT._getDataValue(B.getFieldValue(childs[i], co.paramName, child_ecn));
                    if (params.hasOwnProperty(param)) {
                        params[param]++;
                    } else {
                        params[param] = 1;
                    }
                }
                for (var k in params) {
                    var val = B.dic.from(dic, k);
                    html += DT.renders._display(
                        val,
                        $.extend(co, { dispTitle: DT.renders._dispTitle(val, co.dispTitle) + ' - pozycji ' + params[k] }),
                        meta,
                        co.paramName
                    );
                }
            }
            return html;
        },
        dic: function(data, type, full, meta) {
            var val = '',
                co = DT._getMetaColumnOptions(meta),
                ecn = DT._getMetaECN(meta),
                name = co.name || B.getFullFieldName(co.data, ecn);
            if (isSet(data)) {
                val = B.findInDictionary(co.dicName || name, ecn, B.str.toArray(data));
            }
            return DT.renders._display(val, co, meta, name);
        },
        entity: function(data, type, full, meta) {
            var val = '',
                co =  DT._getMetaColumnOptions(meta),
                tmpl = co.tmpl || '<a class="btn btn-default" href="__url__" target="_blank">__v__</a>',
                ecn = DT._getMetaECN(meta),
                name = co.name || B.getFullFieldName(co.data, ecn);
            co.tmpl = null;
            if (isSet(data)) {
                var rows = B.str.toArray(data);
                val =[];
                for(var i=0; i<rows.length; i++){
                    var r = rows[i].split(' ');
                    var url = B.entity.getUrl(co.ecn, r[0], co.action);
                    var str = tmpl.replace('__url__', url).replace('__v__', r[1] || 'zamowienie');
                    val.push(str);
                }
                val = val.join(co.separator);
            }
            return co.prefix + val + co.suffix;
        },
        detail: function(data, type, full, meta) {
            return '';
        },
        precent: function(data, type, full, meta) {
            return DT.renders._display( B.str.fixed(data || 0, 2), $.extend({ suffix: '%' }, DT._getMetaColumnOptions(meta)), meta, name);
        }
    }
};

$.extend(
    true,
    DT.options,
    (function(o) {
        var exto = {
            selClassPrefix: o.selName + '-',
            selClasses: {},
            selectors: {}
        };
        for (var type in o.selTypes) {
            var types = Array.isArray(o.selTypes[type]) ? o.selTypes[type] : [o.selTypes[type]],
                classes = [],
                selectors = [],
                selPrefix = '.' + exto.selClassPrefix;
            for (var j = 0; j < types.length; j++) {
                classes.push(exto.selClassPrefix + types[j]);
                selectors.push(selPrefix + types[j]);
            }
            exto.selClasses[type] = classes.join(' ');
            exto.selectors[type] = selectors.join();
        }
        return exto;
    })(DT.options)
);

function tabDetailOrders(data, tmpl, entityClassName) {
    var $detail = $(tmpl),
        filled = B.entity.fill(data, entityClassName, {
            // checkDiff: ordersCheckParameters(data)
        });
    $detail.fill(filled, 'Orders', {
        full: true
    });
    return $detail;
}

(function($, B) {
    //    console.log($.fn.dataTableExt.oStdClasses);
    // var _dtClasses = {
    //     sWrapper: ['flex-auto', 'flex-box'],
    //     sScrollWrapper: ['flex-auto', 'flex-box'],
    //     sScrollBody: ['flex-auto']
    // };
    // function _dtAddClass(settings, name, classes) {
    //     console.log('_dtAddClass');
    //     if (Bajt.obj.is(name)) {
    //         $.each(name, function(key, val) {
    //             _dtAddClass(settings, key, val);
    //         });
    //     } else if (typeof name === 'string' && typeof classes !== undefined) {
    //         var c = (settings[name] || '').split(' '),
    //             c1 = Array.isArray(classes) ? classes : classes.split('');
    //         settings[name] = c.concat(c1).join(' ');
    //         console.log(settings[name]);
    //     }
    // }
    //    _dtAddClass($.fn.dataTableExt.oStdClasses, _dtClasses);


    $.extend($.fn.dataTable.ext.classes, {
        sProcessing: 'dataTables_processing loader'
    });

    $.fn.initDataTable = function() {
        var $this = $(this),
            processingSelector = '#' + $this.attr('id') + '_processing',
            tableOptions = $this.data(),
            ecn = tableOptions.ecn,
            name = tableOptions.name,
            rowPrefix = tableOptions.rowPrefix || 'dt_' + name + '_',
            fAjaxError = function (xhr, error, thrown) {
                $(processingSelector).hide();
                if(xhr.status == 403){
                    B.app.onLogin = function(){
                        $this.DataTable().ajax.reload(); 
                    };
                    $('body').trigger('logout', xhr);
                }
                console.log('fAjaxError');
                console.log(xhr);
            },
            defaults = {
                language: {
                    processing: '<div class="animate"></div><div class="msg">Poczekaj, wczytuję ...</div>',
                    search: 'Szukaj:',
                    print: 'Drukuj',
                    lengthMenu: 'Pokaż _MENU_ pozycji',
                    info: 'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
                    infoEmpty: 'Pozycji 0 z 0 dostępnych',
                    infoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
                    infoPostFix: '',
                    loadingRecords: 'Wczytywanie...',
                    zeroRecords: 'Nie znaleziono pasujących pozycji',
                    emptyTable: 'Brak danych',
                    select: {
                        rows: { _: ' (zaznaczono %d wierszy)', 0: '', 1: ' (zaznaczony wiersz' },
                        columns: { _: ' (zaznaczono %d kolumn)', 0: '', 1: ' (zaznaczono 1 kolumnę)' },
                        cells: { _: ' (zaznaczono %d komórek)', 0: '', 1: ' (zaznaczono 1 komórkę)' }
                    },
                    buttons: {
                        selected: 'Zanaczono',
                        selectedSingle: 'Zaznaczenie pojedyńcze',
                        selectNone: 'Odznacz',
                        selectAll: 'Zaznacz',
                        selectRow: 'Zaznaczono wiersz',
                        selectRows: 'Zaznaczono wiersze',
                        selectColumn: 'Zaznaczono kolumnę',
                        selectColumns: 'Zaznaczono kolumny',
                        selectCell: 'Zaznaczono komórkę',
                        selectCells: 'Zaznaczono komórki'
                    },
                    paginate: {
                        first: 'Pierwsza',
                        previous: 'Poprzednia',
                        next: 'Następna',
                        last: 'Ostatnia'
                    },
                    aria: {
                        sortAscending: ': aktywuj, by posortować kolumnę rosnąco',
                        sortDescending: ': aktywuj, by posortować kolumnę malejąco'
                    }
                },
                processing: true,
                autoWidth: false,
                dom:
                    '<"d-flex flex-wrap my-1 justify-content-between dataTables_utils"Bf>' +
                    'tr' +
                    '<"d-flex flex-wrap my-1 justify-content-between  dataTables_utils"ip>',
                buttons: [
                    {
                        extend: 'colvis',
                        text: 'Widoczne kolumny'
                    },
                    {
                        extend: 'pdf',
                        text: 'PDF'
                    },
                    {
                        extend: 'csv',
                        text: 'CSV'
                    },
                    {
                        extend: 'excel',
                        text: 'Excel'
                    }
                ],
                ajax: {
                    type: 'post',
                    data: DT.filter,
                    error: fAjaxError
                },
                pageLength: 100,
                scrollY: true,
                scrollCollapse: true,
                order: [],
                rowPrefix: rowPrefix,
                classes: {
                    scrollBody: 'vvvc'
                },
                createdRow: function(row, data, index) {
                    $(row).attr('id', rowPrefix + data.id);
                }
            };
        tableOptions.entitySettings = Bajt.getEntitySettings(ecn);
        $.each(tableOptions.columns, function(idx, column) {
            var fn,
                fullname = B.getFullFieldName(column.data || '', ecn),
                align = column.align || 'center',
                className = column.className ? [column.className] : [];
            if (fullname) {
                column.name = fullname;
            }
            if (column.render !== undefined) {
                fn = column.render;
            } else {
                //default renders
                switch (fullname) {
                    case 'area':
                    case 'areaLack':
                        fn = 'area';
                        break;
                    default:
                        fn = 'generic';
                }
            }
            if (fn !== undefined) {
                var fn_cc = B.str.camelCase(fn, '_');
                if (typeof DT.renders[fn_cc] === 'function') {
                    column.render = DT.renders[fn_cc];
                } else {
                    fn = 'tabRender' + B.str.capitalise(fn);
                    if (typeof window[fn] === 'function') {
                        column.render = window[fn];
                    }
                }
            }
            if (typeof align === 'string') {
                className.push('dt-' + align);
            } else {
                className.push('dt-head-' + (align.head || 'center'));
                className.push('dt-body-' + (align.body || 'center'));
            }
            column.className = className.join(' ');
        });
        if (Bajt.obj.is(tableOptions.details)) {
            var fn = tableOptions.details.render;
            if (fn) {
                fn = 'tabDetail' + B.str.capitalise(fn);
                if (typeof window[fn] !== 'undefined') {
                    tableOptions.details.render = window[fn];
                } else {
                    delete tableOptions.details['render'];
                }
            }
        }
        if(Bajt.obj.getValue(['select', 'style'], tableOptions) == 'multi'){
            defaults.buttons.unshift( 'selectAll', 'selectNone');
        }
        // if(Bajt.obj.is(tableOptions.select)){
        //     defaults.buttons.unshift( 'selectAll', 'selectNone');
        // }
        if (tableOptions.hasOwnProperty('copyTextarea')) {
            var $textarea = $(tableOptions.copyTextarea);
            if (Bajt.obj.is$($textarea)) {
                console.log('dt copy area init');
                $textarea.initCopyTextarea({
                    entitySettings: tableOptions.entitySettings
                });
            }
        }
        $this.data($.extend(true, {}, defaults, tableOptions));
        dataTables[name] = $this.DataTable();
        $this.on('click', 'tr td.dt-detail', function() {
            var tr = $(this).closest('tr'),
                row = dataTables[name].row(tr),
                opt = dataTables[name].settings()[0].oInit;
            if (typeof opt.details.render === 'function') {
                if (row.child.isShown()) {
                    row.child.hide();
                    tr.removeClass('shown');
                    tr.trigger('details-hidden.dt', [dataTables[name], row]);
                } else {
                    row.child(opt.details.render(row.data(), opt.detailTmpl, opt.ecn)).show();
                    tr.addClass('shown');
                    tr.trigger('details-shown.dt', [dataTables[name], row]);
                }
            }
        });     
        // $this
        //     .on( 'error.dt', function ( e, settings, techNote, message ) {
        //         console.log( 'An error has been reported by DataTables: ', message );
        //         alert('błąd datatable');
        // } );  
    };

    $.fn.redrawDataTables = function() {
        var $dt = $(this).find('.datatable');
        if (Bajt.obj.is$($dt)) {
            $dt.each(function() {
                $(this)
                    .DataTable()
                    .draw();
            });
        }
    };

    $.fn.initDataTables = function() {
        $(this)
            .find('.datatable')
            .each(function() {
                var $table = $(this);
                $table.initDataTable();
                //            $table.parents('.dataTables_wrapper').find('.dt-buttons, .dataTables_filter').addClass('col-xs').wrapAll('<div class="flex-on"></div>');
                //            $table.parents('.dataTables_wrapper').find('.dataTables_info, .dataTables_paginate').addClass('col-xs').wrapAll('<div class="flex-on"></div>');
                //                $(this).DataTable({
                //                language: {
                //                    "processing":     "Przetwarzanie...",
                //                    "search":         "Szukaj:",
                //                    "print":    "Drukuj",
                //                    "lengthMenu":     "Pokaż _MENU_ pozycji",
                //                    "info":           "Pozycje od _START_ do _END_ z _TOTAL_ łącznie",
                //                    "infoEmpty":      "Pozycji 0 z 0 dostępnych",
                //                    "infoFiltered":   "(filtrowanie spośród _MAX_ dostępnych pozycji)",
                //                    "infoPostFix":    "",
                //                    "loadingRecords": "Wczytywanie...",
                //                    "zeroRecords":    "Nie znaleziono pasujących pozycji",
                //                    "emptyTable":     "Brak danych",
                //                    "paginate": {
                //                            "first":      "Pierwsza",
                //                            "previous":   "Poprzednia",
                //                            "next":       "Następna",
                //                            "last":       "Ostatnia"
                //                    },
                //                    "aria": {
                //                            "sortAscending": ": aktywuj, by posortować kolumnę rosnąco",
                //                            "sortDescending": ": aktywuj, by posortować kolumnę malejąco"
                //                    }
                //            },
                //                autoWidth : false,
                //                dom: "Bfrtip",
                //                buttons: [
                //                    { extend:  'colvis', text : 'Widoczne kolumny'},
                //                    { extend:  'pdf', text : 'PDF'},
                //                    { extend:  'excel', text : 'Excel'},
                //                    { extend:  'print', text : 'Drukuj'}
                //                ],
                //                pageLength: 100
                //            });
            });
    };

    var _getRowActionOptions=function($el){
        var
            tab = DT.getInfoFromRowElement($el),
            o = B.getActionOptions($el),
            url = B.str.is(o.url) && o.url !='#'
                ? o.url 
                : (tab.tableSettings.entityUrls[o.actionName] || tab.tableSettings.entityUrls.data).replace(
                    tab.entitySettings.eid || '__id__', tab.rowData.id
                );
        return {
            tab: tab,
            action: o,
            url: url
        };
    };

    $.fn.dtAjaxAction = function() {
        var o = B.getRowActionOptions(this);
        B.ajax.send.apply(this, [
            $.extend({
                    url: o.url,
                    data: {
                        id: o.rowData.id
                    }
                },
                B.getActionAjaxOptions(this)
            ),
            $.extend({
                    dataTable: o.dataTable,
                }, 
                B.getActionMsgOptions(this)
            )
        ]);                
    };

    $.fn.dtBrowserAction = function() {
        var o = _getRowActionOptions(this);       
         switch(o.action.browserAction){
            case 'export':
                Export[o.action.actionName].action(o.tab.tableSettings.containedShowData || o.tab.tableSettings.details ? o.tab.rowData : null, {
                    $btn: this,
                    eSettings: o.tab.entitySettings,
                    ecn: o.tab.ecn,
                    en: o.tab.en,
                    ajax : {
                        type: 'GET',
                        data: {},
                        url : o.url
                    },
                    exp: o.action.exp,
                    copyTextarea: o.tab.tableSettings.copyTextarea || '#exp_' + o.tab.en + '_copy',
                    showModal: o.tab.tableSettings.showModal || '#show_' + o.tab.en + '_modal',
                    config: {
                    }
                });
            break;
        }
        return this;
    };

})(jQuery, Bajt);



// function queryParams(params) {
//     var
//         $filterbar = $(Bajt.html.validateSelector(this.filterbar)),
//         filters = isSet($filterbar) ? $filterbar.filterbar('value') : {};
//     if (typeof (this.filters) != 'undefined') {
//         $.extend(true, filters, this.filters);
//     }
//     return {
//         filters: JSON.stringify(filters)
//     };
// };

// function tabRenderDic(data, type, full, meta){

//     var o=meta.settings.oInit,
//         co=o.columns[meta.col],
//         name=B.getFullFieldName(co.data, o.ecn),
//         dispClass=co.dispClass || 'disp-'+name,
//         dispKey=co.dispKey || 'n',
//         titleKey=co.titleKey || 'd',
//         separator=co.separator || ',',
//         _convertData=function(find){
//             if(typeof find === 'string'){
//                 if(Bajt.json.isArray(find)){
//                     return JSON.parse(find);
//                 }else if(find.indexOf(separator)>0){
//                     return find.split(separator);
//                 }
//             }
//             return find;
//         },
//         _disp=function(val){
//             var disp=val,
//                 title='',
//                 id='';
//             if(Bajt.obj.is(val)){
//                 var strVal=JSON.stringify(val);
//                 disp= val.hasOwnProperty(dispKey) ? val[dispKey] : JSON.stringify(val);
//                 title= val.hasOwnProperty(titleKey) ? val[titleKey] : disp;
//                 id= val.v || val.id || val.value;
//             }
//             if(co.tmpl){
//                 var $el=$(typeof co.tmpl === 'string' ? co.tmpl :  '<span class="label"></span>');
//                 $el.text(disp).attr('title', title );
//                 $el.addClass(dispClass+ " disp");
//                 $el.attr('data-value', id);
//                 return $el[0].outerHTML;
//             }
//             return disp;
//         },
//         value=B.getFromDictionary(co.dicName || name, o.ecn, _convertData(data), {name: null});
//     if(Array.isArray(value)){
//         var shows=[];
//         for(var i=0; i<value.length; i++){
//             shows.push(_disp(value[i]));
//         }
//         return shows.join(separator);
//     }else if(Bajt.obj.is(value)){
//         return _disp(value);
//     }
//     return co.dispEmpty || '';
// }
// function tabRenderDic(data, type, full, meta){
//     var o=meta.settings.oInit,
//         co=o.columns[meta.col],
//         disp=data,
//         title=data,
//         find=data,
//         value;
//         value=B.getFromDictionary(co.dicName || co.name, o.ecn, data);
//     if(Bajt.obj.is(value)){
//         var
//             dispKey=co.dispKey || 'n',
//             titleKey=co.titleKey || 'd';
//         if(value.hasOwnProperty(dispKey)) disp=value[dispKey];
//         if(value.hasOwnProperty(titleKey)) title=value[titleKey];
//     }
//     if(co.tmpl){
//         var $el=$(typeof co.tmpl === 'string' ? co.tmpl :  '<span class="label"></span>');
//         $el.text(disp).addClass((co.dispClass || co.name) +'-'+data).attr('title', title );
//         return $el[0].outerHTML;
//     }
// //        console.log(co);
//     return disp;
// }
