
(function ($, B) {
    'use strict';

    $.widget(
        'bajt.formStep',
        $.extend(true, {}, B.basicWidget, {
            options: {
                nr: null,
                parent: null,
                enabled: false,
                selectors: {
                },
                bind: { 'change' : '_eChange' },
                initData:{
                    value: null
                }
            },
            _createBasicOptions: function(){
                B.basicWidget._createBasicOptions.call(this);
                if(Number.isNaN(Number.parseInt(this.options.nr))){
                    this.options.nr = Number.parseInt(this.element.data('wizard-step'));
                    if(Number.isNaN(this.options.nr)){
                        this.options.nr = 0;
                    }
                }
                return this;
            },
            _createBasicControls: function () {
                B.basicWidget._createBasicControls.call(this);
                this._createElements(['tab', 'field']);
                return this;
            }, 
            _createTab:function(){
                return $('#' + this.element.attr('aria-labelledby'));
            },
            _createField:function(){
                return this.element.find('[name="' + this.options.parent.id + '[' + this.options.fieldName + ']"]');
            },
            _createControls: function () {
                B.basicWidget._createControls.call(this);
                this._formField = this.options.form.field(this.options.fieldName);
                this._formFieldType = this._formField.option('type');
                return this;
            },           
            _createData: function () {
                B.basicWidget._createData.call(this);
            return this;
            },    
            _bind:function(){
                var that=this;
                B.basicWidget._bind.call(this);
                this.$tab.on('shown.bs.tab', function(e){
                    that._trigger('shownStep');
                });
                return this;
            },            
            _initialize :function(){
                B.basicWidget._initialize.call(this);
                this.reset();
                return this;
            },
            getNr:function(){
                return this.options.nr;
            },
            activate:function(){
                if(this.options.enabled){
                    this.$tab.click();
                }
            },
            toggle: function(enable){
                if(undefined === enable){
                    enable = this.options.nr == 0;
                }
                this.options.enabled = enable;
                this.$tab[(enable ? 'remove' : 'add') + 'Class']('disabled');
            },
            isDone:function(){
                return this._data.value !== null;
            },
            reset: function(){
                this._data.value = this.options.defValue || null;
                this._decode(this._formField.getDefaultValue());
                this._refreshTab();
                this._write();
                this.toggle();
            },
            getValue:function(){
                return this._data.value;
            },
            _eChange: function(e){
                stopTrigger(e);
                if (this._read() > 0) {
                    this._formField.value(this._encode());
                    this._refreshTab();
                    this._trigger('changed');
                }
            },
            _refreshTab:function(){
                this.$tab[(this.isDone() ? 'add': 'remove') + 'Class']('done');
                return this;
            },
            _decode: function(value) {
                this._data.value = value;
                return this._data.value;
            },
            _encode: function() {
                return this._data.value;
            },
            _read: function() {
                var newValue = this.$field.exportField(this._formFieldType);
                if(this._data.value != newValue){
                    this._data.value = newValue;
                    return 1;
                }
                return 0;
            },
            _write: function() {
                this.$field.writeField(this._data.value);
            }  

        })
    );


    $.widget(
        'bajt.formWizard',
        $.extend(true, {}, B.basicWidget, {
            options: {
                bind: { 'changed' : '_eChanged' },
                actions: ['next', 'previous'],
                parent: null,
                selectors: {
                    messages: '.wizard-messages',
                    errors: '.wizard-errors',
                    steps: '.form-step',
                    tabs: '[data-toggle=pill]'
                },
                initData: {
                    values: {}
                },
                effects: {
                    container: {
                        show: { fn: 'slideDown' }, 
                        hide: { fn: 'slideUp' }
                    }
                },
                autoNext: true
            },
            _currentStep: null,
            _createBasicControls: function () {
                B.basicWidget._createBasicControls.call(this);
                this._createElements(['messages', 'errors', 'steps', 'tabs']);
                return this;
            }, 
            _createControls:function(){
                B.basicWidget._createControls.call(this);
                this.steps = this._createStepsWidget();
                this.currentStep = this.steps[0];
                return this;
            },
            _createStepsWidget:function(){
                var that = this,
                    nr = 0,
                    steps = [];
                this.$steps.each(function(){
                     steps.push( 
                        $(this).formStep({
                            parent : that,
                            form: that.options.parent,
                            nr: nr++
                        })
                        .data('bajtFormStep')
                    );
                });
                return steps;
            },
            _bind:function(){
                B.basicWidget._bind.call(this);
                this._on(this.$steps, { 'shownStep': this._eShownStep});
                return this;
            },
            _initialize :function(){
                this
                    ._refreshSteps()
                    ._refreshNavigationButtons();
                return this;
            },
            _refreshSteps:function(){
                var nextEnable = true;
                for(var i=0; i < this.steps.length; i++){
                    this.steps[i].toggle(nextEnable);
                    nextEnable = this.steps[i].isDone();
                }
                return this;
            },
            _refreshNavigationButtons:function(){
                this._toggleActionBtn('previous', this.currentStep.getNr() > 0);
                return this;
            },
            _resetSteps:function(){
                for(var i=0; i < this.steps.length; i++){
                    this.steps[i].reset();
                }
                return this;
            },
            _read:function(){
                for(var i = 0; i< this.steps.length ; i++){
                    this._data.values[this.steps[i].name] = this.steps[i].getValue();
                }
            },
            _eShownStep:function(e, data){
                this.currentStep = data.caller;
                this._refreshNavigationButtons();
            },
            _eChanged: function(e, data){
                stopTrigger(e);
                this._read();
                this._refreshSteps();
                if(this.options.autoNext){
                    this.nextStep();
                }
            },
            _eActionNext:function(e, data){
                this.nextStep();
            },
            _eActionPrevious:function(e){
                this.previousStep();
            },
            nextStep:function(){
                if(!this.currentStep.isDone()){
                    this._actionButtons['next'].tooltipMessage({
                        title: 'Nie wybrano opcji'
                    });
                }else{
                    var nextNr =this.currentStep.getNr() + 1;
                    if( nextNr < this.steps.length){
                        this._activateStep(nextNr);
                        console.log('nextstep');
                    }else{
                        this._trigger('doneWizard');
                        console.log('done');
                    }
                }
                return this;
            },
            previousStep:function(){
                console.log('previousstep');
                this._activateStep(this.currentStep.getNr() -1 );

            },
            show:function(){
                this._toggleElement(this.$container, true, 'container');
            },
            hide:function(){
                this._toggleElement(this.$container, false, 'container');
            },
            _activateStep:function(nr){
                if(nr >= 0 && nr < this.steps.length ){
                    this.steps[nr].activate();            
                }
                return this;
            },
            
        })
    );

})(jQuery, Bajt);