(function($, B) {
    'use strict';
    $.extend(true, B, {
        vat: 23,
        convert: function(value, convert){
            var newVal = value;
            if(B.obj.is(convert) && B.isFunction(B.converters[convert.name]) ){
                newVal = B.converters[convert.name](value, convert.options || {});
            } else if (B.isFunction(B.converters[convert])) {
                newVal = B.converters[convert](value);
            } else if (B.isFunction(window[convert])) {
                newVal = window[convert](value);
            }
            return newVal;
        },
        converters: {
            area: function(value, options){
                return B.converters.fixed(value, options);
            },
            cm: function(value, options){
                return B.converters.fixed(value / 10, $.extend({
                    precision : 2
                }, options || {}));
            },
            fixed: function(value, options){
                var o = $.extend({
                        empty : '-',
                        sufix : '',
                        precision : 3,
                        round : 0
                    }, options || {});
                return $.isNumeric(value) ? B.str.fixed(value, o.precision, o.round) + o.sufix : o.empty;
            },
            price: function(price, options){
                var o = $.extend({
                        empty : '-',
                        sufix : '',
                        precision : 2,
                        round : 0,
                        tmpl: null
                    }, options || {}),
                    val={
                        netto: o.empty,
                        brutto: o.empty
                    };
                if(price > 0){
                    val.netto = B.str.fixed(price, o.precision, o.round) + o.sufix ;
                    val.brutto = B.str.fixed(price*(100 + B.vat)/100, o.precision, o.round) + o.sufix;
                }    
                return o.tmpl
                    ? $('<div>').append($(B.obj.is(o.tmpl) ? B.html.tmpl[o.tmpl.name] : o.tmpl )).fill(val, null, { propertyName: 'price' } ).html()
                    : val;
            },
            fileSize:function(size, options){
                return B.str.fileSize(size, options.precision || null, options.unit || null);
            },
            toJSON:function(value, dataType){

            },
            boolLabel:function(value, options){
                var labels= options.labels || ['nie', 'tak'];
                return labels[ !value || value == '0' ? 0 : 1]; 
            },
            bool:function(value){
                return value == '1' ? true : false;
            },
            number:function(value, options){
                var out=parseInt(value, 10);
                return isNaN(out) ? null : out;
            },
            float:function(value, options){
                var out= B.str.toFloat(value, options.precision, options.round);
                return isNaN(out) ? null : out;
            },
            astring:function(value){
                return B.astring.toArray(value);
            },
            json: function(value){
                return B.json.isObj(value) ? JSON.parse(value) : { v: value };
            },
            ajson: function(value){
                var out = [];
                if(Array.isArray(value)){
                    for(var i=0; i < value.length; i++){
                        out.push(B.converters.json(value[i]));
                    }
                }
                return out;
            },
            cjson:function(value){
                var out = B.json.isArray(value) ? JSON.parse(value) : [];
                if(Array.isArray(out)){
                    for(var i=0; i<out.length; i++){
                        if(!B.obj.is(out[i])){
                            out[i] = { v: null };
                        }
                    }
                }
                return out;
            },

        }
    });
})(jQuery, Bajt);
