(function($, B) {
    // $.fn.initWidgetMultiselect=function()
    // {

    // };
    
$.extend(true, B.events.body, {
    toBind: {
        app: ['click_toggle_ajax', 'click_menu_ajax', 'logout', 'logon']
    },
    click_toggle_ajax:[
        'click',
        '.btn[data-toggle=ajax][href!="#"]', 
        function(e) {
            stopTrigger(e);
            Bajt.ajax.load(this);
        }            
    ],
    click_menu_ajax:[
        'click', 
        '[data-menu=ajax] .nav-link[href!="#"]', 
        function(e) {
            var $link = $(this),
                // url = $link.attr('href'),
                target = $link.attr('target');
            if (['_blank', '_self'].indexOf(target) >= 0) {
                return;
            }
            stopTrigger(e);
            Bajt.ajax.load(this, null, function(){
                B.menu.activate($link);
                // var 
                //     $item = $link.parent(),
                //     $dropdownMenu = $item.closest('.dropdown-menu'),
                //     $dropdown = $dropdownMenu.closest('.dropdown'),
                //     $menu = $item.closest('[data-menu=ajax]');
                // $menu.find('.active').removeClass('active');
                // $item.addClass('active');
                // if (Bajt.obj.is$($dropdown)) {
                //     $dropdownMenu.removeClass('show');
                //     $dropdown.removeClass('show');
                //     $dropdown.find('[aria-expanded=true]').attr('aria-expanded', false);
                // }
            });
        }        
    ],
    logout: [
        'logout', 
        function(e, jqXHR){
            console.log(jqXHR);
            var $modal = $('#my_modal'),
                data = jqXHR.responseJSON || JSON.parse(jqXHR.responseText);
            $modal.showContent(data.html);
        }
    ],
    logon: [
        'logon',
        function(e, data){
            console.log('logon');
            if(B.app.onLogin){
                var f = B.app.onLogin;
                B.app.onLogin = null;
                f();
            }
        }
    ]
});


})(jQuery, Bajt);