function stopTrigger(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
}

(function($, B) {
    'use strict';

    $.extend(true, B, {
        events: {
            init:function(name, type){
                type = type || 'basic';
                var o = B.events[name],
                    $element = $(o.selector);
                for(var k in o.toBind[type]){
                    var args = o[o.toBind[type][k]];
                    $.fn.on.apply($element, args);
                }
            },
            body:{
                selector: 'body',
                toBind: {
                    basic: ['close_bs_alert', 'click_alert_close', 
                        'click_btn_close', 'click_btn_value', 'click_function', 'click_toggle_window', 
                        'click_dismiss_tooltip', 'click_toggle_details','click_showchild',
                        'click_upload', 'click_upload_remove',
                        'keydown_navi', 'keynavigate_navi'
                    ]
                },
                close_bs_alert: [
                    'close.bs.alert',
                    '.alert', 
                    function() {
                        $(this).slideUp();
                    }
                ],
                click_alert_close: [
                    'click', 
                    '.alert .close', 
                    function() {
                        var $alert = $(this).closest('.alert');
                        $alert.slideUp(function() {
                            $alert.find('.content').empty();
                        });
                    }                
                ],
                click_btn_close:[
                    'click',
                    '#btn_close',
                    function() {
                        console.log('window close');
                        console.log(window);
                        window.close();
                    }
                ],
                click_btn_value:[
                    'click', 
                    '.btn[data-value]', 
                    function(e) {
                        var $this = $(this),
                            btnData = $this.data();
                        //            value=$this.attr('data-value');
                        if (btnData.value && !btnData.hasOwnProperty('bs.popover')) {
                            stopTrigger(e);
                            var tmpl = btnData['tmpl'],
                                value = btnData.value,
                                options = {
                                    trigger: 'click'
                                };
                            if (tmpl) {
                                var $container = $this.closest('.show-container'),
                                    templates = $container.data('templates'),
                                    esettings = $container.data('entity-settings');
                                console.log(esettings);
                                tmpl = Bajt.obj.getValue(tmpl, templates);
                                if (tmpl) {
                                    var $view;
                                    if ($.isArray(value)) {
                                        $view = $('<div class="table"></div>');
                                        for (var i in value) {
                                            $view.append($(tmpl).fill(value[i], 'PositionsLackers'));
                                        }
                                    } else {
                                        $view = $(tmpl);
                                        $view.fill(value, 'Lackers');
                                    }
                                    options['html'] = true;
                                    value = $view.html();
                                }
                            }
                            if (Bajt.str.is(value)) {
                                options['content'] = value;
                                $this.popover(options);
                                $this.click();
                            }
                        }
                    }
                ],
                click_function:[
                    'click', 
                    '[data-function]',
                    function(e) {
                        stopTrigger(e);
                        var $btn = $(this);
                        $btn[$btn.data('function')]();
                    }
                ],
                click_toggle_window:[
                    'click', 
                    '[data-toggle=window]', 
                    function(e){
                        var $btn = $(this),
                            o = $btn.data(),
                            url = o.url || $btn.attr('href');
                        stopTrigger(e);
                        window.open(url, '_blank');               
                    }
                ],
                click_dismiss_tooltip:[
                    'click', 
                    '.btn[data-dismiss=tooltip]', 
                    function(){
                        var $element = $('[aria-describedby='+ $(this).closest('.tooltip').attr('id') + ']');
                        $element.tooltip('dispose');
                    }
                ],
                click_toggle_details:[
                    'click', 
                    '[data-toggle-details]', 
                    function() {
                        var $this = $(this),
                            type = String($this.data('toggle-details')),
                            target = $this.data('target'), 
                            expanded = $this.attr('data-expanded') === '1' ? true : false,
                            fn = expanded ? 'slideUp' : 'slideDown' ,
                            _fnCallBack = expanded 
                                ? function(){
                                    $(this).removeClass('show');
                                }
                                : function(){
                                    $(this).addClass('show');
                                },
                            $container;    
            
                        switch(type){
                            case 'all':
                                $container = $('body');
                            break;
                            case 'childs':
                                $container = $this;
                            break;
                            default:
                                $container = $this.closest(type);
                        }
                        if(type === 'childs'){
                            $container.children(target)[fn](_fnCallBack);
                        }else{
                            $container.find(target)[fn](_fnCallBack);
                        }
                        // $this.attr('data-expanded', !expanded);
                        var ll=$container.find('[data-target="' + target + '"]');
                        $container.find('[data-target="' + target + '"]').each(function(){
                            var $this=$(this),
                                o=$this.data();
                            if(Array.isArray(o.icons)){
                                $this.changeIcon(o.icons[expanded ? 0 : 1]);
                            }
                            $this.attr('data-expanded', expanded ? '0' : '1');
                        });
                    }
                ],
                click_showchild:[
                    'click',
                    '[data-show-child]',
                    function() {
                        var $this = $(this),
                            $rowpos = $this.closest('.row-pos'),
                            show = $this.data('show-child');
                        if (show) {
                            if (show === '1') {
                                $rowpos.children('.row-child').slideToggle();
                            } else if (show === 'all') {
                                $('.row-child').slideToggle();
                            } else {
                                $rowpos
                                    .closest('.' + show)
                                    .find('.row-child.' + show)
                                    .slideToggle();
                            }
                        }
                    }
                ],
                click_upload:[
                    'click',
                    '.btn-upload',
                    function() {
                        var maxupload = 4,
                            uploadSelector = $(this).data('field') ? $(this).data('field') : Bajt.html.selectorControl($(this).attr('id')),
                            $uploadField = $(uploadSelector),
                            count = $uploadField.find('.form-control').length;
                        if (count < maxupload) {
                            var $fileUpload = $('#fileupload'),
                                fileUpload = $fileUpload.data('blueimp-fileupload') || $fileUpload.data('fileupload');
                            fileUpload.options.uploadContainer = $uploadField;
                            $fileUpload.click();
                        }
                    }
                ],
                click_upload_remove:[
                    'click',
                    '.upload .btn-remove',
                    function() {
                        var $upload = $(this).closest('.upload');
                        //            $position=$upload.closest('.position');
                        $upload.slideUp('fast', function() {
                            var data = $upload.data();
                            if (data.hideStatus) {
                                data.hideStatus();
                            }
                            $upload.remove();
                            //            Order.attachDel($position);
                        });
                    }
                ],
                keydown_navi:[
                    'keydown',
                    '[data-navi]',
                    keyPress.navi
                ], 
                keynavigate_navi:[
                    'keyNavigate',
                    '[data-navi]',
                    function(e, data) {
                        var $this = $(this),
                            $container = $this.closest(Bajt.obj.is(data) && data.container ? data.container : $this.data('navi'));
                        if (Bajt.obj.is$($container)) {
                            $container.naviElement($this, data);
                        }
                        console.log('navigate');
                    }
                ]

            }
        }
    });

 

    // $('body').on('close.bs.alert', '.alert', function() {
    //     $(this).slideUp();
    // });

    // $('body').on('click', '.alert .close', function() {
    //     var $alert = $(this).closest('.alert');
    //     $alert.slideUp(function() {
    //         $alert.find('.content').empty();
    //     });
    // });

    // $('body').on('click', '[data-toggle=window]', function(e){
    //     var $btn = $(this),
    //         o = $btn.data(),
    //         url = o.url || $btn.attr('href');
    //     stopTrigger(e);
    //     window.open(url, '_blank');

    // });
    
})(jQuery, Bajt);
