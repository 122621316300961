(function($) {
    $.widget('bajt.toolbar', $.extend( true, {}, B.basicWidget, {
        options: {
            buildActionBtn: false,
            selectors: {
                dtTools: '[data-dt-action]'
            },
            dtExport:{
                fnExport: null,
                fields: ['id']
            }
        },
        _createBasicControls: function() {
            B.basicWidget._createBasicControls.call(this);                
            this._createDataTable();
            return this;
        },
        _createControls: function() {
            B.basicWidget._createControls.call(this);
            this._createElements(['dtTools']);
            return this;
        },
        _createDataTable:function(){
            this.$dataTable = $(this.options.selectors.dataTable ||  '#' + this.id.replace('toolbar', 'table'));
        },
        _bind:function(){
            B.basicWidget._bind.call(this);
            this._bindDTTools();
            return this;
        },
        _bindDTTools:function(){
            var that = this;
            if(B.obj.is$(this.$dtTools)){
                $.each(this.$dtTools, function(){
                    that._on($(this), { click: that._eClickDTTool });
                });
            }
        },
        _eClickDTTool:function(e, data){
            stopTrigger(e);   
            var 
                selected = this._getSelectedRows();
            if(selected.count()){
                var $btn = $(e.delegateTarget),
                    o = B.getActionOptions($btn),
                    ids = DT.exportRowsColumnData(selected, 'id' );
                if(o.ajaxAction){
                    B.ajax.send.apply(this, [
                        $.extend({
                            url: o.url,
                            data: {
                                    entities: ids
                                }
                            },
                            B.getActionAjaxOptions($btn)
                        ),
                        $.extend({
                                dataTable: this.$dataTable.DataTable()
                            }, 
                            B.getActionMsgOptions($btn)
                        )
                    ]);                
                }else{
                    var $loader,
                        url = B.url.updateParameters(o.url, {
                            entities : JSON.stringify(ids)
                        });
                    if(o.toggle == 'modal'){
                        var $modal = $(o.target || '#my_modal');
                        $modal.ajaxModalLoad(url, o.modalOptions);

                    }else if(o.toggle == 'panel'){
                        var $panel = $(o.target);
                        $loader =  $panel.showLoader();
                        $panel.load(url, function(){
                            $loader.remove();
                        });
                    }else{
                        window.open(url);
                    }
                }
            }  
        },
        // _eClickDTTool:function(e, data){
        //     stopTrigger(e);   
        //     var selected = this._getSelectedRows();
        //     if(selected.count()){
        //         var $btn = $(e.delegateTarget),
        //             o = $.extend(true, {
        //                     url: $btn.attr('href'),
        //                     options: {
        //                         renderType: 'w'
        //                     }
        //                 },
        //                 $btn.data()
        //             ),
        //             ids = DT.exportRowsColumnData(selected, 'id' ),
        //             urlParameters = {
        //                 entities : JSON.stringify(ids)
        //             };
        //         if(o.options.renderType){
        //             urlParameters.type = o.options.renderType;
        //         }
        //         var url = B.url.updateParameters(o.url, urlParameters);
        //         window.open(url);
        //     }  

        //     var jj=1;
        // },
        // _eActionMultiedit:function(e, data){
        //     stopTrigger(e);   
        //     var selected = this._getSelectedRows();
        //     if(selected.count()){
        //         var ids = DT.exportRowsColumnData(selected, 'id' ),
        //             url = B.url.updateParameters(this._actionButtons.multiedit.attr('href'), {entities : JSON.stringify(ids)});
        //         console.log(url);
        //         window.open(url);
        //     }
        // },
        _getSelectedRows:function(){
            return this.$dataTable.DataTable().rows({ selected: true });
        },

    }));


    $.fn.initToolBar = function() {
        this.find('.toolbar').each(function() {
            var $this = $(this),
                name = $this.attr('id').replace('_toolbar', '');
            toolBars[name] = $this.toolbar().data('bajtToolbar');
        });
    };
})(jQuery);
