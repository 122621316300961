Export.createExporter('pdf', {
    defaultConfig: {
        exportOptions: {
        },
        extension: 'pdf',
        header: true,
        footer: false,
    },
    _pdfMake: function() {
        return typeof pdfmake !== 'undefined' || window.pdfMake;
    },
    emptyDoc: {
        orientation: 'portrait',
        pageSize: 'A4',
        pageMargins: [10, 15, 10, 15],
        content: [],
        styles: {
            diff: {
                bold: true,
                fillColor: 'silver'
            },
            tableDefault: {
                margin: [0, 5, 0, 0]
            },
            tableLackers: {
                fontSize: 8
            },
            tableHeader: {
                bold: true,
                fillColor: '#f3f3f3'
            },
            rowSpecial: {
                bold: true,
                fontSize: 11,
                fillColor: 'silver'
            },
            bold: {
                bold: true
            },
            comment: {
                italic: true
            },
            rowEven: {},
            rowOdd: {
                //                                            fillColor: '#f3f3f3'
            },
            tablePosComments: {
                fontSize: 10,
                italic: true
            },
            tableFooter: {
                bold: true,
                fontSize: 12,
                color: 'white',
                fillColor: '#2d4154'
            },
            justify: {
                alignment: 'justify'
            },
            title: {
                alignment: 'center',
                fontSize: 15,
                bold: true,
                margin: [0, 5]
            },
            indent: {
                margin: [5, 0, 0, 0]
            },
            small: {
                fontSize: 9
            },
            h1: {
                alignment: 'left',
                bold: true,
                fontSize: 15,
                margin: [0, 5]
            },
            h2: {
                alignment: 'left',
                bold: true,
                fontSize: 12,
                margin: [0, 3]
            },
            h3: {
                alignment: 'left',
                bold: true,
                fontSize: 11,
                margin: [0, 3]
            },
            h: {
                alignment: 'left',
                bold: true,
                margin: [0, 3]
            },
            message: {}
        },
        defaultStyle: {
            fontSize: 11,
            alignment: 'center'
        }
    },
    config: {
    },
    widths: {
        def: 'auto',
        generic: {
            lp: 15
        }
    },
    converters: {
        generic: function(value, name, options) {
            return value !== undefined ? value.toString() : '';
        },
        int: function(value, name, options) {
            return value.toString();
        },
        float: function(value, name, options) {
            return Bajt.str.fixed(value, Bajt.obj.getValue('precision', options, 3));
        },
        area: function(value, name, options) {
            return Bajt.str.fixed(value, 3);
        },
        price: function(value, name, options) {
            return Bajt.str.fixed(value, 2) + ' zł';
        },
        priceBrutto : function(value, name, options) {
            return this.getConverter('price').call(this, B.str.toFloat(value) * 1.23, name, options);
        },
        value : function(value, name, options) {
            return this.getConverter('price').call(this, value, name, options);
        },
        valueBrutto : function(value, name, options) {
            value = value || B.str.toFloat(B.obj.getValue('data.value', options)) * 1.23;
            return this.getConverter('price').call(this, value, name, options);
        },
        dic:function(value, name, options){
            return B.obj.getValue(B.obj.getValue('field', options, 'name'), value, '');
        },
        trims:function(value, name, options){
            var v=[];
            for(var i in value){
                v.push(Export.pdf.converters.dic.call(this, value[i], name, options));
            }
            return v.join(',');
        },
        diffDic:function(value, name, options){
            var cs = Export.pdf.converters;
            return cs.diff.call(this, cs.dic.call(this, value, name, options), name, options);
        },
        diff:function(value, name, options){
            var o = $.extend({
                        sameDisp: null, //disp if not diff ( null value display)
                        diffDisp: null, //object decorate if diff
                    },
                    options || {}
                ),
                disp = value;
            if(o.diff){
                if(B.obj.is(o.diffDisp)){
                    disp = $.extend({text: disp}, o.diffDisp);
                }
            }else{
                if(o.sameDisp){
                    disp = o.sameDisp;
                }
            }
            return disp;
        },
        diffTrims:function(value, name, options){
            var cs = Export.pdf.converters;
            return cs.diff.call(this, cs.trims.call(this, value, name, options), name, options);
        }
    },
    generators: {
        header: function(title) {
            return {
                text: title || 'nagłówek',
                style: 'title'
            };
        },
        standardTable: function(data, options){
            var 
                pdf = this.exporter,
                o = $.extend(true, {
                        ecn: this.ecn,
                        tableOptions: {
                            options: {
                                margin: [0, 5, 0, 0],
                                fontSize: 8,
                                layout: 'noBorders',
                            } 
                        } 
                    },
                    options || {}
                ),
                fields = Export.getFields(o.ecn, o.fieldsType);
            return pdf._table([
                    pdf._rowStyle(Export._labels(fields, o.ecn), 'tableHeader'),
                    pdf.convertFields(data, fields, $.extend( { ecn: o.ecn}, o.convertOptions|| {}) ),
                ], 
                $.extend(true, {
                    options: {
                        table: {
                            widths: pdf._cellsWidth(fields, { def: '*'})
                        }
                    }
                }, o.tableOptions)
            );    
        }
    },
    _cellsWidth: function(fields, options) {
        var o = $.extend({
                    tableName : 'generic'
                }, 
                options || {}
            ),
            widths = [];
        for (var i = 0, ien = fields.length; i < ien; i++) {
            widths.push(Bajt.obj.getValue([o.tableName, fields[i]], this.widths, o.def || this.widths.def));
        }
        return widths;
    },
    _fillEmptyCells: function(row, count) {
        for (var i = 0; i < count; i++) {
            row.push('');
        }
        return row;
    },
    _rowStyle: function(rowData, style) {
        var that=this;
        return $.map(rowData, function(cell) {
            return that._styleText(cell, style);
        });
    },
    _rowStripped: function(rowIndex, rowData, strippedStyles) {
        return this._rowStyle(rowData, strippedStyles[rowIndex % 2 ? 1 : 0]);
    },
    _styleText: function(text, style) {
        if(style){
            if(!B.obj.is(text)){
                text = {text: text};
            }
            var oldStyle = text.style ? B.str.is(text.style) ? [text.style] : text.style : null;
            text.style = oldStyle ? oldStyle.concat(B.str.is(style) ? [style]: style) : style;
        }
        return text;
    },
    _addPrefix: function(text, prefix) {
        return typeof text === 'string' && typeof prefix === 'string' ? prefix + text : text;
    },
    _addSufix: function(text, sufix) {
        return typeof text === 'string' && typeof sufix === 'string' ? text + sufix : text;
    },
    _table: function(rows, options) {
        var body = [],
            tab = {
                table: {
                    headerRows: 1
                }
            };
        if (!Bajt.obj.is(options)) {
            options = {};
        }
        if (Bajt.obj.is(options.options)) {
            $.extend(true, tab, options.options);
        }
        if (options.header) {
            body.push(
                $.map(options.header, function(val) {
                    return {
                        text: val,
                        style: 'tableHeader'
                    };
                })
            );
        }
        if (options.stripped) {
            var i, ien, stripped;
            if (Array.isArray(options.stripped)) {
                stripped = typeof options.stripped === 'string' ? ['rowOdd', options.stripped] : ['rowEven', 'rowOdd'];
            } else {
                stripped = options.stripped;
            }
            for (i = 0, ien = rows.length; i < ien; i++) {
                body.push(this._rowStripped(i, rows[i], stripped));
            }
        } else {
            for (i = 0, ien = rows.length; i < ien; i++) {
                body.push(rows[i]);
            }
        }
        if (options.footer) {
            body.push(options.footer);
        }
        tab.table.body = body;
        return tab;
    },
    addContent:function(content){
        this.doc.push(content);
        return this;
    },        
    generate: function() {
        var 
            gen = this.getGenerator();
        this.doc = $.extend(
            true,
            {},
            this.emptyDoc,
            Bajt.obj.is(gen.doc) ? gen.doc : {},
            Bajt.obj.is(this.config.doc) ? this.config.doc : {}
        );
        this.doc.content = gen.generate(this.data, this.getExportOptions()) || 'błąd generowania dokumentu';
        if (this.config.message) {
            this.doc.content.unshift({
                text:
                    typeof this.config.message === 'function'
                        ? this.config.message(this.o.$btn, this.data, this.config)
                        : this.config.message,
                style: 'message',
                margin: [0, 0, 0, 12]
            });
        }
        return this.doc;
    },
    createFile:function(data){
        this.setData(data);
        var filename = this.getFilename();
        var pdf = this._pdfMake().createPdf(this.generate());
        if (this.config.download === 'open' && !Export._isSafari()) {
            pdf.open();
        } else {
            pdf.getBuffer(function(buffer) {
                var blob = new Blob([buffer], {
                    type: 'application/pdf'
                });
                Export._saveAs(blob, filename);
            });
        }
    },
    action: function(data, options) {
        var that = this;
        this.setOptions(options);
        if(B.obj.is(data)){
            this.createFile(data);
        }else{
            B.ajax.data(options.ajax, function(data){
                that.createFile(data.entityData);
            });
        }
    }
});



