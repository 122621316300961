
(function($) {


    $.fn.initExpBtns = function(options) {
        var $this = $(this),
            ecn = $this.data('ecn'),
            eSettings = Bajt.obj.is(options.entitySettings) ? options.entitySettings : Bajt.getEntitySettings(ecn),
            copyTextarea = options.copyTextarea || '#exp_' + eSettings.en + '_copy',
            showModal = options.showModal || '#show_' + eSettings.en + '_modal';
        $(copyTextarea).initCopyTextarea({
            entitySettings: eSettings
        });
        $this.find('[data-exp]').each(function() {
            var $btn = $(this),
                btnData = $btn.data(),
                gg=eSettings.en + 'Action',
                action= btnData[eSettings.en + 'Action'] || 'copy';
            $btn.click(function() {
                Export[action].action($this.data('entity-data') , {
                    $btn: $btn,
                    eSettings: eSettings,
                    ecn: eSettings.ecn,
                    en: eSettings.en,
                    exp: btnData.exp,
                    copyTextarea: copyTextarea,
                    showModal: showModal,
                    config: {
                    }
                });   
            });
        });
    };

    $.fn.toggleExpBtns = function(show) {
        if (show === undefined) {
            show = Bajt.obj.is(this.data('entity-data'));
        }
        if (show) {
            this.find('.btn[data-exp]').show();
        } else {
            this.find('.btn[data-exp]').hide();
        }
    };
    
})(jQuery);
