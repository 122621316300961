(function ($, B) {
    'use strict';
    B.formMultiEdit = $.extend(true, {}, B.basicForm, {
        options: {
            formFields:{
                items: {
                    fieldWidget: 'formCollection'
                }
            }
        },
        _createFieldsOptions: function(){
            this.options.formFields.items.selector = '#' + this.options.formName;
            B.basicForm._createFieldsOptions.call(this);
            return this;
        },
        _createControls: function () {
            B.basicWidget._createControls.call(this);
            this._createFields();
            return this;
        }    
    });

    $.widget(
        'bajt.ajaxCollectionForm',
        $.extend(true, {}, B.formMultiEdit, {
        })
    );

    $.fn.initMultiEditForm = function () {
        var $form = $(this).find('form[data-form=ajaxcollection]');
        if (B.obj.is$($form)) {
            $form.ajaxCollectionForm();
        }
    };

    // $.widget(
    //     'bajt.ajaxCollectionForm',
    //     $.extend(true, {}, B.basicForm, {
    //         options: {},
    //         _createFields: function () {
    //             var f = $.extend({
    //                 name: 'items',
    //                 selector: '#' + this.options.formName,
    //                 fieldWidget: 'formCollection',
    //                 options : {}
    //             }, this.options.formFields['items']);
    //             this._addField(
    //                 f.name,
    //                 f.selector,
    //                 f.fieldWidget,
    //                 this._customFieldOptions(f.name, f.options)
    //             );
    //             return this;
    //         },
    //     })
    // );

})(jQuery, Bajt);




