(function($, B) {
    // $.fn.initWidgetMultiselect=function()
    // {

    // };


$.extend(true, B.events, {

    body: {
        toBind: {
            datatable: ['click_browseraction', 'click_ajaxaction', 'show_toggle_tab']
        },
        click_browseraction:[
            'click', 
            '.dataTable a[data-browser-action]', 
            function(e) {
                stopTrigger(e);
                $(this).dtBrowserAction();
            }   
        ],
        click_ajaxaction: [
            'click', 
            '.dataTable a[data-ajax-action]', 
            function(e) {
                stopTrigger(e);
                $(this).dtAjaxAction();
            }
        ],
        show_toggle_tab:[
            'shown.bs.tab', 
            'a[data-toggle="tab"]', 
            function(e) {
                $($(e.target).attr('href')).redrawDataTables();
            }
        ]   
    },
    window: {
        selector: window,
        toBind:{
            datatable: ['resize']
        },
        resize:[
            'resize', 
            function(e) {
                $('body').redrawDataTables();
                $('.table-responsive').calcScrollChildrenWidth();
            }       
        ]
    }
});



})(jQuery, Bajt);