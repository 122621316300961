(function($, B){
    $.widget(
        'bajt.nettoInput',
        $.extend(true, {}, B.basicWidget, {
            options: {
                bind: {
                    change: '_eChange',
                    to_focus: '_eFocus',
                    block: '_eBlock'   
                },
                selectors:{
                    container: 'div'
                },
                inputs: {
                    netto: {
                        symbol: 'N',
                        wrapperClass: 'pl-0 pr-1',
                        wrapperAttr: { title: 'Kwota netto'},
                        vat: 0,
                        navi: 0
                    },
                    brutto: {
                        symbol: 'B',
                        wrapperClass: 'pl-1 pr-0',
                        wrapperAttr: { title: 'Kwota brutto' },
                        vat: 23,
                        navi: 1
                    }
                },
                defaultFocus: 'netto',
                navi: true,
                vat: 23,
                precision: 2,
                edit: true,
                disp: false,
                field: null,
                templates: {
                    container: '<div class="d-flex justify-content-between"></div>',
                    wrapper: '<div class="input-group flex-nowrap col-6"><div class="input-group-prepend"><span class="input-group-text" id="basic-addon1">@</span></div></div>',
                    input: '<input type="text" class="form-control" style="width:1% !important;">',
                    disp: '<div class="form-control w-25"></div>'
                }
            },
            _createContainer: function() {
                return $(this.options.templates.container);
            }, 
            _createBasicOptions: function() {
                B.basicWidget._createBasicOptions.call(this);
                this.options.precision = this.options.field.option('precision');
                return this;
            },
            _build: function() {
                var o = this.options;
                this.$container.appendTo( this.element.parent() );
                this._fields={};
                for (var n in o.inputs) {
                    var iopt = o.inputs[n];
                    this._fields[n] = {
                        $wrapper: $(o.templates.wrapper).appendTo(this.$container),
                        $input: $(o.templates.input).attr('data-type', 'float'),
                        $disp: $(o.templates.disp)
                    };
                    this._fields[n].$wrapper.find('.input-group-text').html(iopt.symbol);
                    if (iopt.wrapperClass) {
                        this._fields[n].$wrapper.addClass(iopt.wrapperClass);
                    }
                    if (iopt.wrapperAttr) {
                        for (var a in iopt.wrapperAttr) {
                            this._fields[n].$wrapper.attr(a, iopt.wrapperAttr[a]);
                        }
                    }
                    if (o.edit) {
                        this._fields[n].$wrapper.append(this._fields[n].$input.data('inputName', n));
                    }
                    if (o.disp) {
                        this._fields[n].$wrapper.append(this._fields[n].$disp);
                    }               
                }
                this.element.hide();
                return B.basicWidget._build.call(this);
            },
            _createData: function() {
                B.basicWidget._createData.call(this);
                for (var n in this.options.inputs) {
                    this._fields[n].value = 0;
                }
                return this; 
            },
            _initialize:function(){
                this._read();
                return B.basicWidget._initialize.call(this);
            },
            _bind: function() {
                var o = this.options;
                B.basicWidget._bind.call(this);
                if (o.edit) {
                    for (var n in this._fields) {
                        var $input = this._fields[n].$input;
                        $input.on('keypress', keyPress.float);
                        this._bindElement($input, { blur: '_eInputBlur' } );
                        if (o.navi) {
                            $input.on('keydown', keyPress.navi);
                        }
                    }
                    this._bindElement(this.$container, { keyNavigate: '_eKeyNavigate' });
                }
                return this;
            },
            _eBlock:function(e, data) {
                this.block(data.block);
            },
            _eChange: function(e, data) {
                if(this._isOwnEvent(data) ){
                    return;
                }
                this._read();
            },
            _eKeyNavigate: function(e, data) {
                stopTrigger(e);
                var o = this.options,
                    name = $(e.target).data('inputName'),
                    inputNames = Object.keys(o.inputs),
                    fname=inputNames[o.inputs[name].navi + data.step];
                if ((data.step === -1 || data.step === 1) && this._fields[fname]) {
                    this._focus$input(fname);
                }else if (o.field.option('navi')) {
                    data.nettoInput = { name: name };
                    this._trigger('keyNavigate', this.element, data);
                }
            }, 
            _eFocus: function(e, data) {
                stopTrigger(e);
                var o=this.options,
                    inputNames = Object.keys(o.inputs);
                if (o.edit) {
                    var fname=B.obj.getValue('nettoInput.name', data),
                        step=B.obj.getValue('step', data);
                    if( !fname  && (step < 0 || step > 0)){
                        fname = step < 0 ? inputNames[inputNames.length -1 ] : inputNames[0];
                    }
                    this._focus$input(B.obj.getValue('nettoInput.name', data, fname || 'netto'));
                }
            },
            _eInputBlur: function(e) {
                stopTrigger(e);
                var iop = this.options.inputs,
                    $input = $(e.target),
                    name = $input.data('inputName'),
                    vat = iop[name].vat,
                    value = this._constrain($input.readField());
                this._setValue( vat === 0 ? value : value * 100 / (100 + vat));
            },
            _focus$input:function(name){
                if(this._fields[name]){
                    this._fields[name].$input.focus().select();
                }
            },
            _str:function(value){
                return B.str.fixed(value, this.options.precision);
            },
            _strValue: function(name) {
                return this._str(this._fields[name].value);
            },
            _disp: function(name) {
                var o=this.options,
                    f=this._fields[name],
                    str=this._str(f.value);               
                if (o.edit) {
                    f.$input.writeField(str);
                }
                if (o.disp) {
                    f.$disp.text(str);
                }
            },
            _read: function() {
                this._setValue(this.element.readField(), true);
            },
            _calc: function() {
                var iop = this.options.inputs,
                    netto = this.getValue('netto');
                for (var n in iop) {
                    if (n !== 'netto') {
                        this._fields[n].value = iop[n].vat === 0 ? netto : netto * (100 + iop[n].vat) / 100;
                    }
                    this._disp(n);
                }
            },
            value: function(value){
                if (undefined !== value) {
                    this._setValue(value, true);
                }
                return this.getValue();
            },
            getValue: function(name) {
                return this._fields[name || 'netto'].value;
            },
            _setValue:function(value, set){
                var newValue=this._constrain(value);
                if(set || this._fields.netto.value != newValue){
                    this._fields.netto.value = newValue;
                    this._calc();
                    this._write();                    
                }
            },
            _constrain: function(value) {
                return B.str.toFloat(value, this.options.precision);
            },
            _write:function(){
                this.element.writeField(this.getValue());
                this._trigger('change');
            },
            _destroy: function() {
                this.$container.remove();
                this.element.disp();
            },
            _block:function(){
                this._blockInputs();
                return B.basicWidget._block.call(this);
            },
            _blockInputs:function(){
                for(var n in this.inputs){
                    this.inputs[n].$input.prop('disabled', this._blockStatus);
                }
                return this;
            }
        })
    );
})(jQuery, Bajt);
