Export.show = {
    action: function(data, options) {
        var $showModal=$(options.showModal),
            $preview=$showModal.find('[data-preview]');
        if(Bajt.obj.isNotEmpty(data)){
            $preview.setDataPreview(data);
        }else{
            $preview.loadDataPreview(options);
        }
        $showModal.modal('show');
    }
};

