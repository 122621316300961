if ($.fn.bootstrapSwitch) {
    $.fn.bootstrapSwitch.Constructor.prototype._width = function() {
        var $handles, handleWidth, labelWidth, wrapperWidth;
        this.$wrapper.outerWidth(
            this.options.wigetWidth ? this.options.wigetWidth : this.$wrapper.closest('.form-group').innerWidth()
        ); //zablokowanie szerokości
        (wrapperWidth = this.$wrapper.innerWidth()), ($handles = this.$on.add(this.$off));
        $handles.add(this.$label).css('width', '');
        if (this.options.handleWidth === 'auto') {
            handleWidth = Math.max(this.$on.width(), this.$off.width());
        } else if (this.options.handleWidth.indexOf('%') > 0) {
            var precent = Math.min(90, parseInt(this.options.handleWidth, 10));
            handleWidth = Math.round(wrapperWidth * precent / 100);
        } else {
            handleWidth = this.options.handleWidth;
        }
        //        console.log('switch'+handleWidth);
        //        else
        //            handleWidth = Math.max(this.$on.width(), this.$off.width()) ;

        labelWidth = wrapperWidth - handleWidth;
        $handles.outerWidth(handleWidth);
        this.$label.outerWidth(labelWidth);
        this._handleWidth = this.$on.outerWidth();
        this._labelWidth = this.$label.outerWidth();
        this.$container.width(this._handleWidth * 2 + this._labelWidth + 2);
        return this.$wrapper;
    };
}
