(function($) {

    $.widget('bajt.filterbar', {
        options: {
            tmpl:
                '<div class="row-data"><div class="col-xs-4">__0__</div><div class="col-xs-8">__1__</div><div class="col-xs-8">__2__</div><div class="col-xs-4">__3__</div></div>'
        },
        _create: function() {
            var $e = this.element,
                o = this.options;
            $.extend(true, o, $e.data('o') || {}, $e.data('options') || {} );
                // settings = $.extend( $e.data('options') || {}, $e.data('o') || {} );
            this.fdata = {
                value: {},
                hiddens: o.hiddenFilters || {}
                // hiddens: settings ? settings.hiddenFilters || {} : {}
            };
            this.tableName = $e.attr('id').replace('_filterbar', '');
            this.$filters = $e.find('.filter');
            this.$submit = $e.find('.btn-filter');
            this.$save = $e.find('.btn-save-filters');
            this._update();
            this.$save.prop('disabled', true);
            this._bind();
        },
        _bind: function() {
            var that = this;
            that._on(that.$submit, {
                click: that._submit
            });
            that._on(that.$save, {
                click: that._save
            });
        },
        _update: function() {
            this.fdata.value = this._read();
        },
        _readState:function(){
            var that = this,
                filters = {};
            this.$filters.each(function() {
                var $f = $(this);
                filters[$f.getFieldName()] = {
                    value: that._getFilterValue($f, { customRead: 1 })
                };
            });
            return filters;
        },
        _getFilterValue: function($filter, options){
            var value = $filter.readField(options);
            return Array.isArray(value) && value.length === $filter.find('option').length ? null : value;
        },
        _read: function() {
            var that = this,
                filters = {};
            this.$filters.each(function() {
                var $f = $(this),
                    f_opt = $f.data('filter-options'),
                    fname = $f.getFieldName(),
                    name = $f.data('name'),
                    value = that._getFilterValue($f);
                if (isSet(value)) {
                    filters[fname] = {
                        value: value,
                        options: f_opt
                    };
                    if (name) {
                        filters[fname].name = name;
                    }
                }
            });
            return filters;
        },
        value: function(value) {
            var d = this.fdata;
            if (value === undefined) {
                return $.extend({}, d.value, d.hiddens);
            }
            d.value = value;
        },
        //        hidden:function(value){
        //            var d=this.fdata;
        //            if ( value === undefined )
        //                return d.hiddens;
        //            d.hiddens=value;
        //        },
        hidden: function(filters) {
            var hf = this.fdata.hiddens;
            if (filters !== undefined) {
                for (var fn in hf) {
                    hf[fn] = filters[fn];
                }
            }
            return hf;
        },
        rmHidden: function(filterName) {
            var hf = this.fdata.hiddens;
            if (hf.hasOwnProperty(filterName)) {
                delete hf[filterName];
            }
        },
        getJSON: function() {
            return JSON.stringify(this.fdata.value);
        },
        _submit: function() {
            this._update();
            this.$save.prop('disabled', false);
            dataTables[this.tableName].ajax.reload(); 
        },
        _clear: function() {},
        _recognize: function() {},
        _save: function(e, data) {
            var that = this,
                o = this.options,
                ajaxData = B.obj.deepValueSet(['settings', 'filters', o.en, o.type], null, this._readState());
            B.ajax.data({
                    type: 'POST',
                    url: o.urls.save,
                    data: ajaxData
                },
                function(data, status, xhr){
                    that.$save.prop('disabled', true);
                    that.$save.tooltipMessage({ 
                        title: 'Zapisano ustawienia filtrów',
                        color: 'success'
                    });
                },
                function(jqXHR, textStatus, errorThrown) {
                    that.$save.tooltipMessage({ 
                        title: 'Błąd zapisu ustawień filtrów',
                        color: 'danger'
                    });
                }
            );


                // $.ajax({
            //     type: 'POST',
            //     url: o.urls.save,
            //     data: ajaxData,
            //     success:function(data, status, xhr){
            //         that.$save.prop('disabled', true);
            //         that.$save.tooltipMessage({ 
            //             title: 'Zapisano ustawienia filtrów',
            //             color: 'success'
            //         });
            //     },
            //     error: function(jqXHR, textStatus, errorThrown) {
            //         that.$save.tooltipMessage({ 
            //             title: 'Błąd zapisu ustawień filtrów',
            //             color: 'danger'
            //         });
            //     }
            // });
        },
        _change: function(e, data) {},
        _title: function() {},
        _view: function() {},
        show: function(order) {}
        //
    });

    $.fn.initFilterBar = function() {
        this.find('.filterbar').each(function() {
            var $this = $(this),
                name = $this.attr('id').replace('_filterbar', '');
            filterBars[name] = $this.filterbar().data('bajtFilterbar');
        });
    };
})(jQuery);

