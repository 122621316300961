$('#fileupload')
    .fileupload({
        dataType: 'json',
        autoUpload: false,
        //            autoStart: true,
        //            acceptFileTypes: /(\.|\/)(gif|jpe?g|png|pdf|doc?|txt|zip|rar)$/i,
        //            disableValidation: false,
        acceptFileTypes: /(\.|\/)(docx?|jpe?g|ods|odt|pdf|png|rar|rtf|txt|xlsx?|zip)$/i,
        maxFileSize: 1048576,
        // Enable image resizing, except for Android and Opera,
        // which actually support image resizing, but fail to
        // send Blob objects via XHR requests:
        disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
        previewMaxWidth: 150,
        previewMaxHeight: 150,
        imageMaxWidth: 1920,
        imageMaxHeight: 1440,
        previewCrop: false,
        formData: {},
        uploadField: null,
        //            $container:null,
        //            showPrototype: $(this).data('show-tmpl'),
        //            statusPrototype:$(this).data('status-tmpl'),
        messages: {
            maxNumberOfFiles: 'Maximum number of files exceeded',
            acceptFileTypes: 'Niedozwolony typ pliku',
            maxFileSize: 'Plik jest zbyt duży (max 1MB)',
            minFileSize: 'Plik jest zbyt mały'
        },
        add: function (e, data) {
            console.log('fileuploadadd');
            var
                $this = $(this),
                that = $this.data('blueimp-fileupload') || $this.fileInput.data('fileupload'),
                uw = that.options.uploadWidget;
            uw.addUpload(data);
        },
        progress: function (e, data) {
            console.log('fileuploadprogress');
            data.uploadProcess.fileProgress(data);
            var progress = parseInt(data.loaded / data.total * 100, 10);
            console.log(progress);
        }
    })
    .on('fileuploadsubmit', function (e, data) {
        console.log('fileuploadsubmit');
        data.uploadProcess.fileSubmit(data.files[0]);
    })
    .on('fileuploadalways', function (e, data) {
        console.log('fileuploadalways');
        //            var 
        //                file = data.files[data.index],
        //                $btnOk=data.context.find('.btn-ok');
        //            $btnOk.prop('disabled', !!data.files.error);
        // data.context.find('.btn-remove').prop('disabled', false);
    })
    .on('fileuploaddone', function (e, data) {
        console.log('fileuploaddone');
        $.each(data.result.files, function (index, file) {
            data.uploadProcess.fileSuccess(file);
        });
    })
    .on('fileuploadfail', function (e, data) {//??
        console.log('fileuploadfail');
        if (typeof data._response.jqXHR.responseJSON !== 'undefined') {
            $.each(data._response.jqXHR.responseJSON, function (index, file) {
                var upload = JSON.parse(file);
                data.context.find('input').remove();
                data.showError(upload.error);
            });
        } else {
            alert(data._response.jqXHR.responseText);
        }
        data.status('error');
    })
    .prop('disabled', !$.support.fileInput)
    .parent().addClass($.support.fileInput ? undefined : 'disabled');

(function($, B) {
    'use strict';
    $.extend(true, B, {
        formUploadProcess: $.extend( true, {}, B.basicWidget, {
            options: {
                actions: ['control', 'cancel'],
                ecn: 'Upload',
                // actionPrefix: 'upload_process',
                preview: null,
                selectors: {
                    actions: '.upload-process-actions',
                    preview: '.fpreview',
                    progress: '.upload-process-progress',
                    messages: '.upload-process-messages',
                    errors: '.upload-process-errors',
                    progressbar: '.progress-bar',
                },
                locale: {
                    btnControl: {
                        label:{
                            ok: 'Wyślij',
                            stop: 'Anuluj',
                            close: 'Zamknij'
                        },
                        title:{
                            ok: 'Wyślij',
                            stop: 'Anuluj',
                            close: 'Zamknij'
                        }
                    },
                    messages: {
                        upload : 'Wysyłam plik',
                        confirm : 'Zatwierdź wysłanie pliku',
                        success : 'Wysłano plik'
                    }
                },
                btnControl: {
                    ok: {
                        addClass: 'btn-success',
                        icon: 'check',
                    },
                    stop: {
                        addClass: 'btn-primary',
                        icon: 'stop'
                    },
                    close: {
                        addClass: 'btn-default',
                        icon: 'close'
                    }
                }
            },
            _createBasicControls: function () {
                B.basicWidget._createBasicControls.call(this);
                this._createElements(['preview', 'messages', 'errors', 'progress', 'progressbar']);
                return this;
            },
            _createBasicOptions: function () {
                B.basicWidget._createBasicOptions.call(this);
                this._createBtnControlOptions();
                return this;
            },
            _createBtnControlOptions:function(){
                for(var role in this.options.btnControl ){
                    this.options.btnControl[role].label=this._locale('btnControl.label.'+role);
                    this.options.btnControl[role].title=this._locale('btnControl.title.'+role);
                }
            },
            _createData: function () {
                B.basicWidget._createData.call(this);   
                this._state = null;
                this._fileupload = null;
                $.extend(true, this._data, {
                    file: null
                });
                return this;
            },
            setFileupload: function (fileupload) {
                if(B.obj.is(fileupload)){
                    fileupload.uploadProcess = this;
                    this._fileupload = fileupload;
                    this.setFile(this._fileupload.files[0]);
                    this.state('ready');
                }else{
                    if(B.obj.is(this._fileupload)){
                        this._fileupload.uploadProcess = null;
                        this._fileupload = null;
                    }
                    this.setFile();
                    this.state('empty');
                }
            },
            setFile:function(file){
                this._data.file=B.obj.is(file) ? 
                    {
                        file: {
                            original: file.name,
                            size: file.size,
                            type: file.type
                        }
                    }
                    : null;
            },
            state: function (state) {
                var o = this.options;
                if (state !== undefined) {
                    this._state = state;
                    switch (state) {
                        case 'error':
                            this._changeRoleBtnControl('close');
                        break;
                        case 'empty':
                            this._hideProcess();
                        break;
                        case 'ready':
                            this._showProcess();
                            break;
                        case 'upload':
                            this.showMessages(this._locale('messages.upload'));
                            this._toggleElement(this.$progress, true);
                            this._changeRoleBtnControl('stop');
                            this._toggleActionBtn('cancel', false);
                        break;
                        case 'success':
                            this.showMessages(this._locale('messages.success'));
                            this._toggleElement(this.$progress, false);
                            this._changeRoleBtnControl('close');
                        break;
                    }
                }
                console.log('uploadShow state: ' + this._state);
                return this._state;
            },       
            _showProcess:function()
            {
                this.$container.fill(this._data.file);
                this.showMessages(this._locale('messages.confirm'));
                this.showErrors();
                this._changeRoleBtnControl('ok');
                this._toggleActionBtn('cancel', true);
                this._toggleElement(this.$container, true);
                this._setProgress(0);
            },
            _hideProcess:function(){
                this._toggleElement(this.$progress, false);
                this._toggleElement(this.$container, false);
            },
            _changeRoleBtnControl: function (role) {
                this._actionButtons.control.changeBtn(role, this.options.btnControl);
            },
            _eActionControl:function(e){
                stopTrigger(e);
                switch(this._state){
                    case 'ready':
                        this._fileupload.submit();
                    break;
                    case 'upload':
                        this._stop();    
                    break;
                    case 'success':
                    case 'error':
                        this.setFileupload();
                    break;
                }
            },
            _eActionCancel:function(e){
                stopTrigger(e);
                this.setFileupload();           
            },
            fileSubmit: function (file) {
                if (file.error) {
                    this.showError(file.error);
                    this.state('error');
                }else{
                    this.state('upload');
                }
            },
            fileProgress: function (data) {
                this._setProgress(parseInt(data.loaded / data.total * 100, 10) || 0);
            },
            _setProgress:function(progress){
                var progressStr = progress + '%';
                this.$progressbar.css('width', progressStr).attr('aria-valuenow', progress).html(progressStr);
            },
            fileSuccess: function (file) {
                this.state('success');
                this._trigger('file-success', this.element, { file: file });
            },
            stop: function(){
                this._fileupload.abort();
                this.state('ready');
            },
            showErrors: function(errors) {
                this.$errors.showMessages(errors);
            },
            showMessages: function(messages) {
                this.$messages.showMessages(messages);
            }
        })
    });
    $.extend(true, B, {
        formUpload:  $.extend( true, {}, B.basicWidget, {
            options: {
                actions: ['load', 'clear'],
                autoStart: false,
                preview: false,
                fieldName: 'upload',
                ecn: 'Upload',
                selectors: {
                    container: '.form-group',
                    actionsContainer: '.upload-actions',
                    fileupload: '#fileupload',
                    preview: '.upload-preview',
                    show: '.upload-show',
                    process: '.upload-process',
                    field: '[data-class=Upload]'
                },
                bind: {
                    change: '_eFieldChange'
                },
                containerBind: {
                    change: '_eContainerChange'
                }
            },
            _createBasicControls: function(){
                B.basicWidget._createBasicControls.call(this);
                var $fileUpload = $(this.options.selectors.fileupload);
                this.fileUpload = $fileUpload.data('blueimp-fileupload') || $fileUpload.data('fileupload');
                this._createElements(['show', 'preview', 'process', 'field']);
                this.process = this.$process.uploadProcess().data('bajtUploadProcess');
                return this;
            },
            _createData: function () {
                B.basicWidget._createData.call(this);
                $.extend(true, this._data, { 
                    upload : null,
                    uploadId: null
                });
                return this;
            },
            _bind:function(){
                B.basicWidget._bind.call(this);
                this._bindProcess();
                return this;
            },
            _bindProcess:function(){
                this._on( this.$process, {
                        'file-success' : this._efileSuccess
                    }
                );
            },
            _initialize:function(){
                B.basicWidget._initialize.call(this);
                this._read();
                return this;
            },
            _eFieldChange:function(e, data){
                if(!this._isOwnEvent(data) ){
                    this._read();
                }
            },
            _eContainerChange:function(e){               
                var $target=$(e.target),
                    d=$target.data(),
                    name= d.name ? d.name.replace(this.options.fieldName+'.', '') : null;
                if(name){
                    stopTrigger(e);
                    this._setUploadValue(name, $target.readField());
                    this._write();
                }
            },
            _eActionLoad: function (e) {
                stopTrigger(e);
                this.fileUpload.option('uploadWidget', this);
                $(this.options.selectors.fileupload).click();
            },
            _eActionClear: function (e) {
                stopTrigger(e);
                this._setFile();
            },
            _efileSuccess:function(e, data){
                stopTrigger(e);
                this._setFile(this._decodeFile(data.file));
            },
            _decodeFile:function(fileData){
                return $.extend({}, fileData, {
                    fileUrl: fileData.url + fileData.name
                });
            },
            _transform:function(value){
                return B.json.isObj(value) ? JSON.parse(value) : null;                
            },
            _reverseTransform:function(value){
                return JSON.stringify(value);
            },
            _read:function(){
                this._setUploadData(this._transform(this.$field.readField()));
                this._data.uploadId=this._getUploadValue('id');
                this._showUpload();
            },
            _setUploadData:function(data){
                this._data.upload=$.extend(this._data.upload || {}, data );
            },
            _setUploadValue:function(name, value){
                B.setFieldValue(this._data.upload, name, this.options.ecn, value);
            },
            _getUploadValue:function(name){
                return B.getFieldValue(this._data.upload, name, this.options.ecn );
            },
            _showUpload:function(){
                var showData={ upload: this._data.upload };
                this.$preview.fill(showData, this.options.ecn);
                this.$show.fill(showData, this.options.ecn);
            },
            _setFile:function(file){
                var d=this._data;
                if(file){
                    this._setUploadData(file);
                    if(d.uploadId){
                        this._setUploadValue('id', d.uploadId);
                    }
                }else{
                    d.upload=null;
                }
                this._showUpload();
                this._write();
            }, 
            _write:function(){
                this.$field.writeField(this._reverseTransform(this._data.upload));
                this._trigger('change', this.$field );
            },
            addUpload: function (data) {
                this.process.setFileupload(data);
            }
        })
    });
    $.extend(true, B, {
        formUploads:  $.extend( true, {}, B.basicWidget, {
            options: {
                // actionPrefix: 'upload',
                actions: ['load', { name: 'clear', confirm: true }],
                autoStart: false,
                preview: false,
                maxUploads: 3,
                fieldName: 'upload',
                ecn: 'Upload',
                selectors: {
                    container: '.form-group',
                    actionsContainer: '.upload-actions',
                    fileupload: '#fileupload',
                    process: '.upload-process',
                    itemContainer: '.upload-item', 
                    itemField: '[data-item=Upload]',
                    itemPreview: '.upload-preview',
                    itemShow: '.upload-show'
                },
                bind: {
                    change: '_eFieldChange'
                },
                containerBind: {
                    change: '_eContainerChange'
                }
            },
            _createBasicControls: function(){
                B.basicWidget._createBasicControls.call(this);
                var o=this.options,
                    $fileUpload = $(o.selectors.fileupload);
                this.fileUpload = $fileUpload.data('blueimp-fileupload') || $fileUpload.data('fileupload');
                this._createElements(['process']);
                this.process = this.$process.uploadProcess().data('bajtUploadProcess');
                return this;
            },
            _createData: function () {
                B.basicWidget._createData.call(this);
                $.extend(true, this._data, { 
                    uploads: [],
                    $uploads: null,
                    count: 0,
                    index: 0,
                });
                return this;
            },
            _createControls:function(){
                B.basicWidget._createControls.call(this);
                var $btnLoad=this._actionButtons.load;
                $btnLoad
                    .attr('data-content', 'Maksymalna liczba załączników: ' + this.options.maxUploads)
                    .popover({ placement: 'auto', trigger: 'manual' })
                    .on('mouseleave', function() {
                        $btnLoad.popover('hide');
                    });
                return this;
            },
            _bind:function(){
                B.basicWidget._bind.call(this);
                this._bindProcess();
                return this;
            },
            _bindProcess:function(){
                this._on( this.$process, {
                        'file-success' : this._efileSuccess
                    }
                );
            },
            _initialize:function(){
                B.basicWidget._initialize.call(this);
                var that=this;
                this._get$Uploads().each(function(){
                    that._$uploadAddDeleteAction($(this));
                });
                this._read();
                return this;
            },
            _eFieldChange:function(e, data){
                if(!this._isOwnEvent(data) ){
                    this._read();
                }
            },
            _eContainerChange:function(e){               
                var $target=$(e.target),
                    d=$target.data(),
                    name= d.name ? d.name.replace(this.options.fieldName+'.', '') : null;
                if(name){
                    stopTrigger(e);
                    this._setUploadValue(name, $target.readField());
                    this._write();
                }
            },
            _eActionLoad: function (e) {
                stopTrigger(e);
                if(this._data.count < this.options.maxUploads){
                    this.fileUpload.option('uploadWidget', this);
                    $(this.options.selectors.fileupload).click();
                }else{
                    this._actionButtons.load.popover('show');
                }
            }, 
            _eActionClear: function (e) {
                stopTrigger(e);
                var that=this,
                    element=this.element,
                    $items=this.element.find(this.options.selectors.itemContainer);
                this._toggleElement($items, false, 'element', function(){ 
                    element.empty();
                    that._changed();
                } );
            },
            _efileSuccess:function(e, data){
                stopTrigger(e);
                this._newUpload(this._decodeFile(data.file));
            },
            _decodeFile:function(fileData){
                return $.extend({}, fileData, {
                    fileUrl: fileData.url + fileData.name
                });
            },
            _changed:function(){
                this._read();
                this._trigger('change');
            },
            _read:function(){
                var d=this._data,
                    $fields=this._get$Fields();
                d.count=$fields.length;
                d.index= d.index < d.count ? d.count : d.index; 
            },
            _newUpload:function(file){
                var $upload=this._$uploadNew(file);
                this.element.append($upload);
                this._toggleElement($upload, true);
                this._changed();
            }, 
            _get$Fields:function(){
                return this.element.find(this.options.selectors.itemField);
            },
            _get$Uploads:function(){
                return this.element.find(this.options.selectors.itemContainer);
            },
            _$uploadNew:function(uploadData){
                var $upload=this._$uploadCreate();
                this._$uploadWrite($upload, uploadData);
                this._$uploadFill($upload, uploadData);
                return $upload;
            },
            _$uploadCreate: function(){
                var $upload= this._createFromPrototype(this._data.index++);
                this._$uploadAddDeleteAction($upload);
                return $upload;
            },
            _$uploadAddDeleteAction:function($upload){
                var that = this,
                    $btn=$upload.find(this._getActionButtonSelector('delete', 'upload'));
                this._createConfirmation($btn, 'delete');
                $btn.on('click', function(e){
                    stopTrigger(e);
                    that._toggleElement($upload, false, null, function(){
                        $upload.remove();
                        that._trigger('change');
                    } );
                });
            },
            _$uploadWrite:function($upload, uploadData){
                $upload.find(this.options.selectors.itemField).writeField(uploadData);
            },
            _$uploadFill:function($upload, uploadData){
                var o=this.options,
                    showData={ upload: uploadData},
                    _show=function(selector){
                        var $element=$upload.find(selector);
                        if(B.obj.is($element)){
                            $element.fill(showData);
                        }
                    };
                _show(o.selectors.itemShow);
                _show(o.selectors.itemPreview);
            },
            addUpload: function (data) {
                this.process.setFileupload(data);
            }

        })
    });

    $.widget('bajt.upload', $.extend(true, {}, B.formUpload, {

    }));

    $.widget('bajt.uploads', $.extend(true, {}, B.formUploads, {

    }));

    $.widget('bajt.uploadProcess', $.extend(true, {}, B.formUploadProcess, {

    }));

    
})(jQuery, Bajt);

