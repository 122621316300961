(function($, B) {
    'use strict';

    B.formCollectionImportDT = $.extend( true, {}, B.formCollection, {
        options: {
            actions: [{ name:'dt_import', container: 'body' }, { name: 'add' }],
            dblClick: false,
            maxCount: false,
            showLp: false,
            dataTable: {
            },
            dataTableFilter: null,
            saveData: true,
            templates: {
                uniqueFilter:
                    '<div style="display:none;"><div class="form-check"><input type="checkbox" class="form-check-input" id="dt_filterbar_unique"><label class="form-check-label" for="dt_filterbar_unique">Ukryj dodane</label></div></div>'
            },
            confirmRemove: false,
            dtExport:{
                fnExport: null,
                fields: null 
            },
            selectors: {
                dataTable: null
            }
        },
        _createControls: function() {
            B.formCollection._createControls.call(this);                
            this._createElements(['dataTable']);
            return this;
        },
        _createDataTable: function(){
            var o = this.options,
                $dt = $(this.options.selectors.dataTable || ('#' + B.entity.getName(this.options.ecn) + '_table' ));
            this.dataTable= B.obj.is$($dt) ? $dt.DataTable() : null;
            var tabSettings = this.dataTable.settings()[0];
            tabSettings.aoRowCallback.push({
                fn: function(row, data, index) {
                    DT.rowCallBack.select(row, data, index, o.dataTable);
                },
                sName: 'user'
            });
            this._createUniqueFilter();
            return $dt;
        },
        _createUniqueFilter:function(){
            var $filterbar = DT.get$FilterBar(this.dataTable);
            if (this.options.unique.on && B.obj.is$($filterbar)) {
                var $uf = $(this.options.templates.uniqueFilter);
                $filterbar.find('.filters').append($uf);
                if (this.options.unique.showFilter) {
                    $uf.show();
                }
                this.$uniqueFilter = $uf.find('input');
                this.filterUnique = this.options.unique.filter ? true : false;
                this.$uniqueFilter.prop('checked', this.filterUnique);
            }
        },
        _changed:function(data){
            this.dataTable.draw();             
            B.formCollection._changed.call(this, data);
        },
        _bind:function(){
            var that=this,
                o=this.options;
            B.formCollection._bind.call(this);
            this._on(this.$uniqueFilter, {
                change: this._eChangeUniqueFilter
            });
            this.$dataTable.on({
                'user-select.dt': function(e, dt, type, cell, originalEvent) {
                    var DTo = $.extend(true, {}, DT.options, that.options.dataTable || {} ),
                        $row = $(cell.row(cell.indexes()[0].row).node()),
                        sel = $row.data(DTo.selName) || 'unselected';
                    console.log('user-select.dt');
                    if ( [ 'unselected', 'selected'].indexOf(sel) < 0 ) {
                        stopTrigger(e);
                    }
                },
                'preDraw.dt': function(e, settings) {
                    if (o.unique.on) {
                        that._dtUnselectNotUnique();
                        that._dtSelectUnique('included');
                        that._dtSelectUnique('added');
                        that._dtUniqueFilter();
                    }
                    console.log('preDraw');
                }
            });
            return this;
        },
        _eActionDtImport:function(e){
            stopTrigger(e);
            var selected = this.dataTable.rows({ selected: true });
            this.import(DT.exportRowsData(selected, this.options.dtExport ));
            selected.deselect();
            // this._dtChanged({ selType: 'added' });
        },

        _dtUniqueRows:function(uniqueType, not){
            return DT.getRowsByColumnValue(this.dataTable, this._getUniqueValues(uniqueType), this.options.unique.name, not);
        },
        _dtSelectUnique: function(uniqueType) {
            uniqueType = uniqueType || 'added';
            DT.select(this._dtUniqueRows(uniqueType), uniqueType);
        },
        _dtUnselectNotUnique: function(){
            DT.unselect(this._dtUniqueRows(null, true));
        },
        _eChangeUniqueFilter: function(e) {
            this.filterUnique = this.$uniqueFilter.is(':checked');
            this._dtUniqueFilter();
            console.log('uniquechange');
        },
        _dtUniqueFilter: function() {
            if (this.filterUnique) {
                DT.unhide(this._dtUniqueRows(null, true));
                DT.hide(this._dtUniqueRows(null));
            }else{
                DT.unhide(this.dataTable, 'all');
            }
        },
        setDTFilter:function(value, filter){
            filter = filter || this.options.dataTableFilter;
            if(filter){
                DT.setFilter(this.dataTable, filter, value );
            }
            return this;
        }
    });

    $.widget(
        'bajt.formCollectionImportDT',
        $.extend(true, {}, B.formCollectionImportDT, {

        })
    );
})(jQuery, Bajt);
