(function($, B) {
    'use strict';
    $.extend(true, B, {
        obj: {
            options:{
                valueNames: ['id', 'v', 'value']
            },
            clone: function(obj){
                return B.obj.is(obj) ? JSON.parse(JSON.stringify(obj)) : obj;
            },
            deepValueSet:function(keys, obj, value, overwrite, separator){
                if(!Array.isArray(keys)){
                    keys = keys.split(separator || '.');
                }
                if(!B.obj.is(obj)){
                    obj = {};
                }
                var current = obj,
                    lastKey = keys.pop();
                for(var i= 0; i < keys.length; i++){
                    if(!current.hasOwnProperty(keys[i])){
                        current[keys[i]] = {};
                    }
                    current = current[keys[i]];
                }
                if(lastKey && (!current.hasOwnProperty(lastKey) || overwrite) ){
                    current[lastKey] = value;
                }
                return obj;
            },
            compose: function(obj, separator){
                var newObj={};
                separator= separator || /[_.]/;
                for(var key in obj){
                    var subKeys=key.split(separator);
                    if(subKeys.length > 1){
                        if(!newObj.hasOwnProperty(subKeys[0])){
                            newObj[subKeys[0]]={};
                        }
                        newObj[subKeys[0]][subKeys[1]]=obj[key];
                    }else{
                        newObj[key]=obj[key];
                    }
                }
                return newObj;
            },
            getValue: function(key, obj, def) {
                def = def !== undefined ? def: null;
                if (!B.obj.is(obj) ) {
                    return def;
                }
                if (B.str.is(key) && obj.hasOwnProperty(key) ) {
                    return obj[key];
                }
                key = Array.isArray(key) 
                    ? B.obj.clone(key) 
                    : B.str.is(key) 
                        ? key.split('.')
                        : [];
                if(key.length > 1){
                    var current=obj;
                    while(typeof current === 'object' && key.length > 1){
                        var k=key.shift();
                        current = B.obj.getValue(k, current);
                    }
                    if(key.length === 1){
                        return B.obj.getValue(key.shift(), current, def);
                    }
                }
                return def;
            },
            getValueOneOfKeys:function(keys, obj, def){
                def = def !== undefined ? def : null;
                if (!B.obj.is(obj)) {
                    return def;
                }
                keys = Array.isArray(keys) ? B.obj.clone(keys) : [keys];
                var not_found = '__##',
                    current = not_found;
                while( keys.length > 0 && current === not_found ){
                    current=B.obj.getValue(keys.shift(), obj, not_found);
                }
                return current !== not_found ? current : def;
            },
            getValueIfObject:function(value, keys){
               return B.obj.getValueOneOfKeys( keys || B.obj.options.valueNames, value, value);
            },
            haveSameValues:function(val1, val2){
                return B.obj.getValueIfObject(val1) == B.obj.getValueIfObject(val2);
            },
            summary: function(dest, source) {
                var n;
                if (B.obj.is(dest) && Object.keys(dest).length > 0) {
                    for (n in dest) {
                        dest[n] += source[n] || 0;
                    }
                } else {
                    dest = {};
                    for (n in source) {
                        dest[n] = source[n];
                    }
                }
                return dest;
            },
            addSum: function(obj, sum, name) {
                name = undefined !== name ? name : 'sum';
                if (obj[name] === null) {
                    obj[name] = B.obj.is(sum) ? B.obj.summary({}, sum) : sum;
                } else {
                    if (B.obj.is(obj[name])) {
                        B.obj.summary(obj[name], sum);
                    } else {
                        obj[name] += sum;
                    }
                }
                return obj;
            },
            supplement: function(target, source) {
                if (B.obj.is(target) && B.obj.is(source)) {
                    for (var i in source) {
                        if (!target.hasOwnProperty(i)) {
                            target[i] = B.obj.is(source[i]) ? $.extend({}, source[i]) : source[i];
                        }
                    }
                    return target;
                }
                return {};
            },
            keys: function(obj, filter) {
                var keys = [];
                if (B.obj.is(obj)) {
                    for (var k in obj) {
                        var push = true;
                        if (B.obj.is(filter)) {
                            var v = B.obj.getValueIfObject(obj[k]);
                            push = filter.included
                                ? filter.values.indexOf(v) >= 0
                                : filter.values.indexOf(v) < 0;
                        }
                        if (push) {
                            keys.push(k);
                        }
                    }
                }
                return keys;
            },
            is: function(value) {
                return value != null && typeof value === 'object';
            },
            validate:function(obj){
                return B.obj.is(obj) ? obj : {};
            },
            isEmpty: function(value) {
                return value != null && typeof value === 'object'  && Object.keys(value).length === 0;
            },
            isNotEmpty: function(value) {
                return value != null && typeof value === 'object' && Object.keys(value).length > 0;
            },
            is$: function(value) {
                return B.obj.is(value) && value.jquery && value.length > 0;
            },
            isIterate: function(value) {
                return Array.isArray(value) && value.length > 0;
            },
            toArray:function(value){
                if(B.json.isArray(value)){
                    return( JSON.parse(value));
                }else if(!Array.isArray(value)){
                    return [ value ];
                }
            },
            arrayDiff:function(array1, array2){
                return array1.concat(array2).filter(function (e, i, array) {
                    return array.indexOf(e) === array.lastIndexOf(e);
                });
            },
            addToArray: function(target, source, unique) {
                var push = true;
                if (B.obj.is(target)) {
                    if (!Array.isArray(target)) {
                        target = [target];
                    }
                } else {
                    target = [];
                }
                if (unique) {
                    var unigueValue = B.obj.getValue(unique, source);
                    if (unigueValue) {
                        for (var i = 0; i < target.length && push; i++) {
                            push = target[i][unique] !== unigueValue;
                        }
                    }
                }
                if (push) {
                    target.push(source);
                }
                return target;
            }
        }
    });
})(jQuery, Bajt);
