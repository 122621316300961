var Bajt = {
    checkCode:function(file, fn, line){
        console.log('!!! do sprawdzenia - plik: ' + file + ', funkcja: '+ fn + ', linia: ' + line );
    }
    
};
var B = Bajt;
(function($, B) {
    'use strict';
    $.extend(true, B, {
        isFunction:function(fn){
            return typeof fn === 'function';
        },
        isNaturalNumber:function(n) {
            n = n.toString(); // force the value incase it is not
            var n1 = Math.abs(n),
                n2 = parseInt(n, 10);
            return !isNaN(n1) && n2 === n1 && n1.toString() === n;
        },
        toNaturalNumber:function(n) {
            return B.isNaturalNumber(n) ? parseInt(n) : Number.NaN;
        },
        getValue: function(obj, names) {
            var _compile=function(n){
                var i, j,
                    val=null, 
                    k=Object.keys(obj);
                
                for(i=0; i<n.length; i++){
                    var prefix=n[i]+'.';
                    for(j=0; j<k.length; j++){
                        if(k[j].indexOf(prefix) === 0){
                            val= val || {};
                            val[k[j].slice(prefix.length)] = obj[k[j]];
                        }
                    }
                }
                return val;
            };
            if (B.obj.is(obj) && !Array.isArray(obj)) {
                names = names || ['v', 'id', 'value'];
                if (typeof names === 'string') {
                    return obj.hasOwnProperty(names) ? obj[names] : _compile([names]);
                } else {                   
                    return obj.hasOwnProperty(names[0])
                        ? obj[names[0]]
                        : obj.hasOwnProperty(names[1])
                            ? obj[names[1]]
                            : _compile(names);
                }
            }else{
                return obj;
            }
        },
        checkValue: function(checked, value) {
            var val = B.getValue(checked);
            return String(val) === String(value);
        },
        // checkLimits: function(limits, value, externalValues, converter) {
        //     var result = null,
        //         _convert = function(val) {
        //             if (typeof converter === 'function') {
        //                 if (Array.isArray(val)) {
        //                     return val.map(function(v) {
        //                         return converter(v);
        //                     });
        //                 } else {
        //                     return converter(val);
        //                 }
        //             } else {
        //                 return val;
        //             }
        //         },
        //         _getExternalValue = function(name){
        //             return typeof externalValues === 'function'
        //                 ? externalValues(name)
        //                 : B.obj.getValue(name, externalValues);
        //         },
        //         _compare = function(condition){
        //             var val = condition.hasOwnProperty('ev')
        //                 ? _getExternalValue(condition.ev)
        //                 : value;
        //             return  B.compareValues(condition.c, val, _convert(condition.v));
        //         };

        //     if (Array.isArray(limits)) {
        //         for (var i = 0; i < limits.length; i++) {
        //             var limit = limits[i],
        //                 ok;
        //             if (Array.isArray(limit.condition)) {
        //                 var ok1 = !limit.hasOwnProperty('logical') || limit.logical === 'and';
        //                 ok = ok1;
        //                 for (var j = 0; ok === ok1 && j < limit.condition.length; j++) {
        //                     ok = _compare(limit.condition[j]);
        //                     // ok = this.compareValues(limit.condition[j].c, value, _convert(limit.condition[j].v));
        //                 }
        //             } else {
        //                 ok = _compare(limit.condition);
        //                 // ok = this.compareValues(limit.condition.c, value, _convert(limit.condition.v));
        //             }
        //             if (ok) {
        //                 result = limit;
        //                 break;
        //             }
        //         }
        //     }
        //     return result;
        // },

        confirmationOptions: {
            rootSelector: '[data-toggle=confirmation]',
            title: 'Czy napewno?',
            btnOkLabel: 'Tak',
            btnCancelLabel: 'Nie',
            btnOkClass: 'btn-info',
            popout: true
        },
        // checkLimits: function(limits, value, externalValues, converter) {
        //     var result = null;
        //     if (Array.isArray(limits)) {
        //         for (var i = 0; i < limits.length; i++) {
        //             var limit = limits[i],
        //                 ok;
        //             if (Array.isArray(limit.condition)) {
        //                 var ok1 = !limit.hasOwnProperty('logical') || limit.logical === 'and';
        //                 ok = ok1;
        //                 for (var j = 0; ok === ok1 && j < limit.condition.length; j++) {
        //                     ok = B.checkCondition(limit.condition[j], value, externalValues, converter);
        //                 }
        //             } else {
        //                 ok = B.checkCondition(limit.condition, value, externalValues, converter);
        //             }
        //             if (ok) {
        //                 result = limit;
        //                 break;
        //             }
        //         }
        //     }
        //     return result;
        // },
        checkLimits: function(limits, value, externalValues, converter) {
            var result = null;
            if (Array.isArray(limits)) {
                for (var i = 0; i < limits.length; i++) {
                    var limit = limits[i],
                        ok;
                    if (Array.isArray(limit.condition)) {
                        var ok1 = !limit.hasOwnProperty('logical') || limit.logical === 'and';
                        ok = ok1;
                        for (var j = 0; ok === ok1 && j < limit.condition.length; j++) {
                            ok = B.checkCondition(limit.condition[j], value, externalValues, converter);
                        }
                    } else {
// !!!!!!!!!!!!!!!!!!!!!!
                        ok = B.checkCondition(limit.condition, value, externalValues, converter);
                    }
                    if (ok) {
                        result = limit;
                        break;
                    }
                }
            }
            return result;
        },
        checkCondition:function(condition, value, externalValues, converter){
            var val = value,
                _convert = function(v) {
                    if (typeof converter === 'function') {
                        if (Array.isArray(v)) {
                            return v.map(function(v1) {
                                return converter(v1);
                            });
                        } else {
                            return converter(v);
                        }
                    } else {
                        return v;
                    }
                };
            if(condition.hasOwnProperty('ev')){
                val = typeof externalValues === 'function'
                    ? externalValues(condition.ev)
                    : B.obj.getValue(condition.ev, externalValues);
            }else if(condition.hasOwnProperty('key')){
                if(B.obj.is(value) && value.hasOwnProperty(condition.key)){
                    val = value[condition.key];
                }else{
                    return false;
                }
            }
            return  B.compareValues(condition.c, val, _convert(condition.v));
        },
        isEqual:function(val1, val2, options){
            var i=0,
                _isEqual={
                    def:function(v1, v2){
                        return (B.obj.is(v1) ? JSON.stringify(v1) : v1) ==
                            (B.obj.is(v2) ? JSON.stringify(v2) : v2);
                    },
                    options:function(v1, v2){
                        return B.array.haveSameValues(v1, v2);
                    },
                    astring:function(v1, v2){
                        return B.astring.isEqual(v1, v2);
                    },
                    json:function(v1, v2){
                        return B.json.getValue(v1) === B.json.getValue(v2);
                    },
                    array:function(v1, v2){
                        return B.array.isEqual(v1, v2);
                    }
                };
            options= B.obj.validate(options);
            if(B.isFunction(_isEqual[options.type])){
                return _isEqual[options.type](val1, val2);
            }
            return _isEqual.def(val1, val2);
        },
        compareValues: function(condition, val, valCheck) {
            var result = false;
            val = B.getValue(val); 
            switch (condition) {
                case 'eq': // val == valCheck
                    result = val === valCheck;
                    break;
                case 'neq': // val <> valCheck
                    result = val !== valCheck;
                    break;
                case 'lt': // val < valCheck
                    result = val < valCheck;
                    break;
                case 'lte': // val <= valCheck
                    result = val <= valCheck;
                    break;
                case 'gt': // val > valCheck
                    result = val > valCheck;
                    break;
                case 'gte': // val >= valCheck
                    result = val >= valCheck;
                    break;
                case 'isTrue'://val is true
                    result = val && val !== '0' && val !== 'false' ? true : false;
                    break;
                case 'isFalse'://val is false
                    result = !val || val === '0' || val === 'false' ? true : false;
                    break;
                case 'isNull': // val is null
                    result = val === null || val === '' || val === 0;
                    break;
                case 'isNotNull': // val is not null
                    result = val !== null && val !== '' && val !== 0;
                    break;
                case 'in':
                    result = val.indexOf(valCheck) >= 0;
                    break;
            }
            return result;
        },
        moment: function(value) {
            value = B.obj.is(value) && value.hasOwnProperty('date') ? value.date : value;
            var m = moment(value);
            return m.isValid() ? m : null;
        },
        momentRange: function(value, unit) {
            var startDate = value === 'current' ? moment().startOf(unit) : moment().subtract(value, unit + 's');
            return [startDate, moment()];
        },
        getActionOptions:function($el){
            return $.extend(true, {
                url: $el.attr('href')
            },
            $el.data());
        },
        getActionAjaxOptions:function($el){
            return $.extend({
                    method: 'GET',
                    dataType: 'json'
            },
            $el.data('ajax') || {});
        },
        getActionMsgOptions:function($el){
            var o = {
                    type: 'tip',
                    target: '#my_modal',
                    $modal: null,
                    $tip: null,
                    modalOptions: {}
                },
                msg = $el.data('msg') || {};
            msg = B.str.is(msg) ? { type: msg } : msg;
            $.extend(o, msg);
            switch(o.type){
                case 'tip':
                    o.$tip = $el;
                break;
                case 'modal':
                    o.$modal= $(o.target);                    
                break;
            }
            return o;
        }
        // ajax: {
        //     send: function(params, options) {
        //         options = options || {};
        //         var that = this,
        //             $form = options.$form,
        //             $submit = options.$submit || this.$submit,
        //             $content = options.$content || $form,
        //             $modal = options.$modal,
        //             $tip = options.$tip,
        //             dataTable = options.dataTable || this.dataTable,
        //             _block = function(block) {
        //                 if (typeof that.block === 'function') {
        //                     that.block(block);
        //                 } else if (B.obj.is$($submit)) {
        //                     $submit.prop('disabled', block);
        //                 }
        //             },
        //             _hideSubmit = function() {
        //                 if (B.obj.is$($submit) && B.obj.getValue('hideSubmit', options)) {
        //                     $submit.hide();
        //                 }
        //             },
        //             _showMessages = function(message) {
        //                 if (typeof that.showMessages === 'function') {
        //                     that.showMessages(message);
        //                 } else if (B.obj.is$($content)) {
        //                     $content.find('.messages').showMessages(message);
        //                 }
        //             },
        //             _showErrors = function(errors) {
        //                 if (typeof that.showErrors === 'function') {
        //                     that.showErrors(errors);
        //                 } else if (B.obj.is$($content)) {
        //                     $content.find('.errors').showMessages(errors);
        //                 }
        //             },
        //             _showTip = function(msg){
        //                 if($tip){
        //                     $tip.tooltipMessage({
        //                         msg: msg
        //                     });
        //                 }
        //             },
        //             _state = function(state) {
        //                 if (typeof that.state === 'function') {
        //                     that.state(state);
        //                 } else {
        //                     switch (state) {
        //                         case 'submit':
        //                             _block(true);
        //                             _showErrors();
        //                             _showMessages(
        //                                 B.obj.getValue('messages-submit', options, 'Wysyłam dane do serwera')
        //                             );
        //                             break;
        //                         case 'submitSuccess':
        //                             _showMessages();
        //                             _block(false);
        //                             break;
        //                         case 'submitError':
        //                             _showMessages();
        //                             _block(false);
        //                             break;
        //                     }
        //                 }
        //             },
        //             _success = function(data, textStatus, jqXHR) {
        //                 _state('submitSuccess');
        //                 if (B.obj.is$($form)) {
        //                     $form.trigger('submited', data);
        //                 } else if (B.obj.is$($content)) {
        //                     $content.trigger('submited', data);
        //                 }
        //                 if (dataTable) {
        //                     dataTable.ajax.reload();
        //                 }
        //                 if (B.obj.is$($modal) && !(data.show || data.edit)) {
        //                     $modal.modal('hide');
        //                 } else {
        //                     _showMessages(data.messages);
        //                     _hideSubmit();
        //                 }
        //                 _showTip(jqXHR.responseJSON.messages[0]);
        //                 if(options.afterSuccess){
        //                     options.afterSuccess(data, textStatus, jqXHR);
        //                 }
        //             },
        //             _error = function(jqXHR, textStatus, errorThrown) {
        //                 _state('submitError');
        //                 if (typeof jqXHR.responseJSON !== 'undefined') {
        //                     if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
        //                         if (B.obj.is$($form)) {
        //                             $form.find('.form_body').html(jqXHR.responseJSON.form_body);
        //                             $form.initFormWidgets();
        //                         }
        //                         // console.log('form_errors');
        //                     }
        //                     _showErrors(jqXHR.responseJSON.errors);
        //                     _showTip(jqXHR.responseJSON.errors[0]);
        //                 } else if (jqXHR.responseText) {
        //                     _showErrors(jqXHR.responseText);
        //                 } else {
        //                     alert(errorThrown);
        //                 }
        //                 if(options.afterSuccess){
        //                     options.afterError(jqXHR, textStatus, errorThrown);
        //                 }
        //             };
        //         _state('submit');
        //         $.ajax($.extend( {
        //             success: _success,
        //             error: _error,
        //             method: 'POST'
        //         }, params));
        //     },
        //     getData:function(ajaxOptions, successCallback, errorCallback){       
        //         $.ajax(ajaxOptions)
        //             .done(successCallback)
        //             .fail(function(jqXHR, textStatus, errorThrown) {
        //                 if (typeof jqXHR.responseJSON !== 'undefined') {
        //                    console.log(jqXHR.responseJSON.errors);
        //                 } else if (jqXHR.responseText) {
        //                    console.log(jqXHR.responseText);
        //                 } else {
        //                    console.log(errorThrown);
        //                 }
        //                 if( B.isFunction(errorCallback)){
        //                     errorCallback(jqXHR, textStatus, errorThrown);
        //                 }       
        //             });
        //     }
        
        // }
    });

    $.fn.getNaviElements=function(data){
        return this.find(B.obj.getValue('selector', data, '[data-navi]'));
    };

    $.fn.naviElement = function($element, data) {
        var $elements = this.getNaviElements(data),
            targetIndex = data.hasOwnProperty('target') 
                ? data.target 
                : $element 
                    ? $elements.index($element) + data.step
                    : 0;
        if (0 <= targetIndex && targetIndex < $elements.length) {
            $elements[targetIndex].focus();
        }
    };

    $.fn.getNaviIndex = function($element, data) {
        return $element 
            ? this.getNaviElements(data).index($element)
            : 0;
    };
 
    $.fn.tooltipMessage=function(options){
        options = $.extend(true, {
                color: 'info',
                timeout: 3000,
                hiding: 'dispose',
                html: true,
            }, 
            B.obj.is(options) ? options : { title: options }
        );
        if(options.msg){
            options.title = options.msg.message;
            options.color = options.msg.type == 'error' ? 'danger' : options.msg.type ;
        }
        var $this = $(this),
            oldTitle = $this.attr('title'),
            title = options.title,
            _hide=function(){
                $this.tooltip(options.hiding);
            };
        options.title = function(){
            return options.timeout 
                ? title
                : '<div class="d-flex align-items-center"><span>' + title + '</span><button type="button" class="btn btn-sm font-weight-bold" data-dismiss="tooltip">×</button></div>';
        };
        if(options.color){
            options.template = '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner bg-light border border-' + options.color + ' text-' + options.color + '"></div></div>';
        }
        $this.removeAttr('title');
        $this.tooltip(options);
        $this.attr('title', oldTitle);       
        $this.tooltip('show');
        if(options.timeout){
            setTimeout(_hide, options.timeout);
        }else{
            $this.on('click', _hide);
        }
    };



    // $('body').on('click', '.btn[data-dismiss=tooltip]', function(){
    //     var $element = $('[aria-describedby='+ $(this).closest('.tooltip').attr('id') + ']');
    //     $element.tooltip('dispose');
    // });
    
})(jQuery, Bajt);
