(function ($, B) {
    'use strict';
    $.extend(true, B, {
        dic: {
            options: {
                dispNames: ['n', 'name'],
                valueNames: ['id', 'v', 'value']
            },
            next: function (dictionary, value) {
                var result,
                    finded = false;
                $.each(dictionary, function (index, row) {
                    if (finded) {
                        result = row;
                        return false;
                    }
                    // finded = row.hasOwnProperty('v') ? row.v == value : row.value == value;
                    finded = B.checkValue(row, value);
                });
                return result ? result : dictionary[0];
            },
            getValue: function (dicName, entityClassName, value, options) {
                value = B.dic.from(B.dic.get(dicName, entityClassName), value, options);
                return value;
            },
            getRow: function (dicName, entityClassName, value) {
                value = B.dic.findRow(B.dic.get(dicName, entityClassName), value);
                return value;
            },
            rowField: function (row, field, def) {
                var value = B.json.isObj(row) ? JSON.parse(row) : row;
                field = field || B.dic.options.dispNames;
                if (B.obj.is(value)) {
                    def = def !== undefined ? def : null;
                    value = B.obj.getValueOneOfKeys(field, value, def);
                } else if (typeof val === 'boolean') {
                    value = value ? 1 : 0;
                }
                return value;
            },
            rowsFields: function (rows, field, def) {
                var values = [];
                field = field || B.dic.options.dispNames;
                for (var i in rows) {
                    values.push(String(B.dic.rowField(rows[i], field, def)));
                }
                return values;
            },
            rowValue: function (row) {
                return B.dic.rowField(row, B.dic.options.valueNames);
            },
            rowsValues: function (rows) {
                return B.dic.rowsFields(rows, B.dic.options.valueNames);
            },
            find: function (dictionary, value) {
                return Array.isArray(value) ? B.dic.findRows(dictionary, value) : B.dic.findRow(dictionary, value);
            },
            findRow: function (dictionary, value) {
                var found;
                if (B.obj.isIterate(dictionary)) {
                    var val = B.dic.rowValue(value);
                    for (var i in dictionary) {
                        if (val == B.dic.rowValue(dictionary[i])) {
                            found = dictionary[i];
                            break;
                        }
                    }
                }
                return found;
            },
            findRows: function (dictionary, value) {
                var found = [];
                if (B.obj.isIterate(dictionary) && B.obj.isIterate(value)) {
                    var val = B.dic.rowsValues(value);
                    for (var i in dictionary) {
                        for (var j in val) {
                            if (val[j] == B.dic.rowValue(dictionary[i])) {
                                found.push(dictionary[i]);
                                delete val[j];
                                break;
                            }
                        }
                        if (val.length == 0) {
                            break;
                        }
                    }
                }
                return found;
            },
            getDispNames: function (options) {
                var name = B.obj.getValue('name', options);
                if (B.str.is(name)) {
                    name = B.getDicFieldNames(name, B.obj.getValue('ecn', options));
                }
                return Array.isArray(name) ? name : B.dic.options.dispNames;
            },
            from: function (dictionary, value, options) {
                var iterate = B.obj.isIterate(value),
                    found = B.dic.find(dictionary, value);
                if((iterate && found.length > 0) || found !== null ){
                    return (typeof options === 'object' && options.name === null)
                        ? found
                        : B.dic['rowField' + (iterate ? 's' : '')](
                            found, 
                            B.dic.getDispNames(options), 
                            B.obj.getValue('def', options, '.')
                        );
                }
                return iterate ? [] : B.obj.getValue('empty', options, '');
            },
            // from: function (dictionary, value, options) {
            //     var result = B.obj.isIterate(value) ? [] : B.obj.getValue('empty', options, '');
            //     if (B.obj.isIterate(dictionary)) {
            //         var name = B.dic.getDispNames(options),
            //             def = B.obj.getValue('def', options, '.');
            //         if (B.obj.isIterate(value)) {
            //             result = B.dic.rowsFields(B.dic.findRows(dictionary, value), name, def);
            //         } else {
            //             var found = B.dic.findRow(dictionary, value);
            //             if (B.obj.is(found)) {
            //                 result = B.dic.rowField(found, name, def);
            //             }
            //         }
            //     }
            //     return result;
            // },


            // get: function(dicName, entityClassName, activeOnly) {               
            //     if (!Array.isArray(dicName)) {
            //         dicName = dicName.split('.');
            //     }
            //     if (dicName.length > 1) {
            //         entityClassName = B.entity.getChildClass(dicName[0], entityClassName);
            //         dicName = dicName[1];
            //     } else {
            //         dicName = dicName[0];
            //         entityClassName = entityClassName || dicName;
            //     }
            //     var es = B.getEntitySettings(entityClassName),
            //         dic;
            //     if (B.obj.is(es) && es.hasOwnProperty('dictionaries')) {
            //         var 
            //             childClass = B.entity.getChildClass(dicName, es.fields),
            //             names = [
            //             dicName,
            //             childClass || entityClassName,
            //             // B.obj.getValue(['fields', 'childs', dicName], es, ''),
            //             B.str.capitalise(dicName),
            //             dicName + 's',
            //             B.str.capitalise(dicName + 's')
            //         ];
            //         dic = B.obj.getValueOneOfKeys(names, es.dictionaries);
            //         if(activeOnly){
            //             dic = B.dic.filterActive( dic, childClass || entityClassName );
            //         }
            //         // for (var i = 0; i < names.length && !dic; i++) {
            //         //     dic = es.dictionaries[names[i]];
            //         // }
            //     }
            //     return dic;
            // },
            get: function (dicName, entityClassName, options) {
                var dic, es, ecn;
                options = options || {};
                if(B.str.isNotOrEmpty(dicName)){
                    dicName = entityClassName;
                }else{
                    if (dicName.indexOf('.') > 0) {
                        var n = dicName.split('.');
                        entityClassName = B.entity.getChildClass(n[0], entityClassName);
                        dicName = n[1];
                    } else if (B.str.isNotOrEmpty(entityClassName)) {
                        entityClassName =  B.str.firstUpper(dicName);
                    }
                }
                ecn = dicName === B.str.firstUpper(dicName)
                    ? dicName
                    : B.entity.getChildClass(dicName, entityClassName);
                es = B.getEntitySettings(ecn) || B.getEntitySettings(entityClassName);
                if (B.obj.is(es) && es.hasOwnProperty('dictionaries')) {
                    dic = B.obj.getValueOneOfKeys([
                        dicName,
                        ecn,
                        dicName + 's',
                        B.str.firstUpper(dicName),
                        B.str.firstUpper(dicName + 's')
                    ], es.dictionaries);
                    dic = B.dic.filter(dic, options.filters, ecn || entityClassName);
                }
                return dic;
            },
            filter: function (dictionary, filters, entityClassName) {
                // filters = {
                //     name : {
                //         not: true || false,
                //         value: val || [],
                //     }                    
                // }                
                if (B.obj.isIterate(dictionary) && B.obj.is(filters)) {
                    var filteredDic = [];
                    for (var i in dictionary) {
                        var match;
                        for (var n in filters) {
                            var filterValue = filters[n].value,
                                filterNot = filters[n].hasOwnProperty('not') ? filters[n].not : false,
                                rowValue = B.obj.getValueOneOfKeys(B.getDicFieldNames(n, entityClassName), dictionary[i], undefined);
                            if (undefined !== rowValue) {
                                if (Array.isArray(filterValue)) {
                                    for (var j = 0; !match && j < filterValue.lenght; j++) {
                                        match = rowValue == filterValue[j];
                                    }
                                } else {
                                    match = rowValue == filterValue;
                                }
                                match = (match && !filterNot) || (!match && filterNot);
                            }
                        }
                        if (match === null || match) {
                            filteredDic.push(dictionary[i]);
                        }
                    }
                    return filteredDic;
                }
                return dictionary;
            }
        }
    });
})(jQuery, Bajt);