(function($, B) {
    'use strict';

    B.app = {
        selectors:{
            mainWrapper: '#main-wrapper',
            contentBody: '#content_body'
        },
        initContent:function(wrapperSelector){
            var $wrapper = $(wrapperSelector || Bajt.app.selectors.mainWrapper);
            Bajt.app.loadSettings();
            $wrapper.initContent();
            $wrapper.initModals();
        },
        loadSettings: function(selector) {
            $.extend(true, Bajt.settings, $(selector || Bajt.app.selectors.contentBody ).data());
        },   
        // ajaxHandleError:function(responseText, textStatus, jqXHR, errorCallback, logonCallbak){
        //     if(jqXHR.status == 403){
        //         if(typeof logonCallbak === 'function'){
        //             B.app.onLogin = logonCallbak;
        //         }
        //         $('body').trigger('logout', jqXHR);                       
        //     }else{
        //         var $modal = $('#my_modal');
        //         if(B.obj.is$($modal)) {
        //             $modal.find('.modal-content').html(responseText);
        //             $modal.modal('show');
        //         } else {
        //             alert(responseText);
        //         }                            
        //     }
        //     if(typeof errorCallback === 'function'){
        //         errorCallback();
        //     }
        // },
        // ajaxLoad: function(link, wrapperSellector, successCallback, errorCallback ) {
        //     var 
        //         $wrapper = $(wrapperSellector || Bajt.app.selectors.mainWrapper),
        //         url = $(link).attr('href'),
        //         $loader = $wrapper.showLoader();
        //         // console.log('menu-ajax');
        //     $wrapper.load(
        //         url,
        //         {
        //             view: 'ajax'
        //         },
        //         function(responseText, textStatus, jqXHR) {
        //             $loader.remove();
        //             if (textStatus === 'error') {
        //                 B.app.ajaxHandleError(responseText, textStatus, jqXHR, errorCallback,
        //                     function(){
        //                         B.app.ajaxLoad(link, wrapperSellector, successCallback, errorCallback); 
        //                     }
        //                 );

        //                 // if(jqXHR.status == 403){
        //                 //     B.app.onLogin = function(){
        //                 //         B.app.ajaxLoad(link, wrapperSellector, successCallback, errorCallback); 
        //                 //     };
        //                 //     $('body').trigger('logout', jqXHR);                       
        //                 // }else{
        //                 //     var $modal = $('#my_modal');
        //                 //     if(B.obj.is$($modal)) {
        //                 //         $modal.find('.modal-content').html(responseText);
        //                 //         $modal.modal('show');
        //                 //     } else {
        //                 //         alert(responseText);
        //                 //     }                            
        //                 // }
        //                 // if(typeof errorCallback === 'function'){
        //                 //     errorCallback();
        //                 // }

        //             } else {
        //                 window.history.replaceState('', '', url);
        //                 document.title = $wrapper.find('#page_title').data('title');
        //                 B.app.initContent(wrapperSellector);
        //                 if(typeof successCallback === 'function'){
        //                     successCallback();
        //                 }
        //             }
        //         }
        //     );
        // }
    };

})(jQuery, Bajt);