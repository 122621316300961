(function($, B) {
    'use strict';
    $.widget('bajt.modalTableImportDT', $.bajt.modalField, {
        options: {
            ecn: '',
            tableName: '',
            maxCount: 4,
            unique: {
                on: false,
                name: 'id',
                showFilter: false,
                filter: false
            },
            rowSelector: '.row-pos',
            sortable: true,
            showLp: true,
            dblClick: true
        },
        _createData: function() {
            // this._data={
            //     value : []
            // };
            B.modalField._createData.call(this);
            $.extend(true, this._data, { 
                value: [],
            });
            return this;
        },
        _constrain: function(value, def) {
            if (B.json.is(value)) {
                value = JSON.parse();
            }
            if (!Array.isArray(value)) {
                value = B.obj.is(value) ? [value] : def;
            }
            return value;
        },
        _customImportData: function(dataOut, dataIn) {
            return dataOut;
        },
        _customInitValue: function() {
            var md = this._data;
            md.value = this._constrain(md.value, []);
        },
        _decode: function(value) {
            return this._superApply([this._constrain(value, [])]);
        },
        _encode: function() {
            return this._constrain(this._data.value, null);
        },
        _initialize:function(){
            B.modalField._initialize.call(this);
            var o = this.options;
            this.dtImport=this.$fieldsContainer.dtImport({
                addBtn: this.element.find('.btn-import'),
                table: '#' + o.tableName + '_table',
                ecn: o.ecn,
                importFields: o.importFields,
                sortable: o.sortable,
                showLp: o.showLp,
                maxCount: o.maxCount,
                unique: o.unique,
                animate: o.animate,
                rowSelector: o.rowSelector,
                dblClick: o.dblClick
            }).data('bajtDtImport');
            this.ownChange = false;
            return this;
        },
        read: function() {
            this._data.value = this.dtImport.read();
            return this._data.value;
        },
        write: function() {
            this.dtImport.write(this._data.value);
        }
    });
})(jQuery, Bajt);
