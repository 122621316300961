(function($, B) {
    'use strict';
    $.extend(true, B, {
        html: {
            tmpl: {
                btn: '<button type="button" class="btn"></button>',
                price: '<span class="netto px-1" data-price="netto">__v__ zł</span><span class="brutto px-1" data-price="brutto">__v__ zł</span>',
                icon: '<i class="material-icons"></i>',
                txtWrapper: '<span></span>',
                inputGroup: '<div class="input-group" />',
                inputGroupBtn: '<div class="input-group-append" />',
                loader: '<div class="loader"><div class="animate"></div><div class="msg"><span>proszę czekaj ...</span></div></div>'
            },
            get$: function(type, options) {
                var $e = null,
                    t = B.html,
                    o = $.isPlainObject(options) ? options : {};
                switch (type) {
                    case 'btn':
                        $e = $(t.tmpl.btn);
                        if (isSet(o.icon)) {
                            $e.append(t.get$('icon', B.obj.is(o.icon) ? o.icon : o));
                        }
                        if (isSet(o.text)) {
                            $e.append($(t.tmpl.txtWrapper).html(o.text));
                        }

                        break;
                    case 'icon':
                        $e = $(t.tmpl.icon).text(o.icon);
                        break;
                    default:
                        $e = $(t.tmpl[type]);
                }
                if (B.obj.is$($e)) {
                    if (isSet(o.addClass)) {
                        $e.addClass(o.addClass);
                    }
                    if (B.obj.is(o.attr)) {
                        $.each(o.attr, function(n, v) {
                            $e.attr(n, v);
                        });
                    }
                    if (B.obj.is(o.triggers)) {
                        $.each(o.triggers, function(n, opt) {
                            $e.on(n, function(e) {
                                if (opt.stop) {
                                    stopTrigger(e);
                                }
                                if (typeof opt.eFunction === 'function') {
                                    opt.eFunction(e);
                                }
                                if (opt.trigger) {
                                    $e.trigger(opt.trigger);
                                }
                            });
                        });
                    }
                }
                return $e;
            },
            get: function(type, options) {
                return B.html.get$(type, options)[0].outerHTML;
            },
            getPrototypeTmpl: function(tmpl, prototypeName, index) {
                return B.str.is(prototypeName) ? tmpl.replace(B.str.match(prototypeName, 'contains'), index) : tmpl;
            },
            genFromPrototype: function(tmpl, prototypeName, index) {
                return $(B.html.getPrototypeTmpl(tmpl, prototypeName, index)).attr('data-idx', index);
            },
            getNrFromId: function(str, prefix) {
                var nrStr = '',
                    regexp = new RegExp((prefix ? prefix + '_' : '') + '(\\d+)' ),
                    match = regexp.exec(str);
                if (match !== null && match.length == 2) {
                    nrStr = match[1];
                }
                return nrStr !== '' ? parseInt(nrStr, 10) : -1;
            },
            getNameFromId: function(str, prefix) {
                var name = '',
                    match = /_[^_.]*$/.exec(str);
                if (match) {
                    name = match[0].slice(1, match[0].length);
                }
                return name;
            },
            validateSelector: function(selector) {
                var selectorOk = '';
                if (typeof selector === 'string') {
                    if (selector[0] !== '#') {
                        selectorOk = '#' + selector;
                    } else {
                        selectorOk = selector;
                    }
                }
                return selectorOk;
            },
            selectorControl: function(selector, prefixControl) {
                prefixControl = typeof prefixControl !== 'undefined' ? prefixControl : 'btn';
                var selectorOK = Bajt.html.validateSelector(selector),
                    objPrefix = '#' + prefixControl + '_',
                    selectors = {
                        field: '',
                        control: ''
                    };
                if (selectorOK.indexOf(objPrefix) < 0) {
                    selectors.field = selectorOK;
                    selectors.control = selectorOK.replace(prefixId, objPrefix);
                } else {
                    selectors.control = selectorOK;
                    selectors.field = selectorOK.replace(objPrefix, prefixId);
                }
                return selectors;
            }
            
        }
    });


    $.fn.saveAttr=function(attrNames){
        attrNames = attrNames || ['class', 'style'];
        var attr = {};
        for(var k in attrNames){
            attr[attrNames[k]] = this.attr(attrNames[k]);
        }
        this.data('oryginalAttr', attr);
        return this;
    };

    $.fn.restoreAttr=function(){
        var attr = this.data('oryginalAttr') || {};
        for(var k in attr){
            this.attr(k, attr[k] );
        }
        this.removeData('oryginalAttr');
        return this;
    };
   
    $.fn.replaceAttr = function(attrName, replace ){
        var attr=this.attr(attrName);
        if(undefined !== attr){
            for(var i in replace){
                attr = attr.replace(replace[i][0], replace[i][1]);
            }
            this.attr(attrName, attr);
        }
        return this;
    };

    $.fn.calcOverflow = function() {
        $(this)
            .find('.overflow-x:visible')
            .each(function() {
                var sw = this.scrollWidth;
                this.firstElementChild.style.minWidth = sw - 4 + 'px';
                // var d=$container.innerWidth();
                // var s=$container.get(0).scrollWidth;
                // $container.first().css('min-width', $container.scrollWidth);
            });
    };

    $.fn.removeClassSearch = function(search, type) {
        var classMatch = B.str.match(search, type);
        if (classMatch) {
            this.removeClass(function() {
                return (this.className.match(classMatch) || []).join(' ');
            });
        }
        return this;
    };
  
    $.fn.changeBtn = function(type, btnOptions){
        var $btn=$(this);
        if(btnOptions === undefined){
            btnOptions = $btn.data('roles');
        }
        $btn
            .removeClass((function(){
                var classes=[];
                for(var i in btnOptions){
                    if(i !== type){
                        classes.push(btnOptions[i].addClass);
                    }
                }
                return classes.join(' ');
            })())
            .attr('title', btnOptions[type].title || '')
            .addClass( btnOptions[type].addClass)
            .changeIcon(btnOptions[type].icon)
            .find('span').html(btnOptions[type].label || '');
        return this;
    };

    $.fn.calcScrollChildrenWidth = function() {
        var $container=$(this);
        $container.each(function(){
            var $element = $(this),
                $childs = $element.children('div').css({ width: '', 'max-width' : ''}),
                scrollWidth = this.scrollWidth,
                clientWidth = this.clientWidth;
                $childs.each(function() {
                        // var calcWidth = this.clientWidth + scrollWidth - clientWidth;
                    if(this.clientWidth < scrollWidth){
                        $(this).css({
                            width: scrollWidth-1,
                            'max-width': scrollWidth-1
                        });
                    }
                });
        });
    };


    $.fn.toggleEdit =function(){
        var $this=$(this),
            d=$this.data(),
            $container=$this.closest(d.container || 'div'),
            $field=$container.find(d.field || 'input'),
            $show=$container.find(d.show || 'div.form-control'),
            visible=$field.is(':visible');
        if(visible){
            $field.hide();
            $show.show();
            $this.changeBtn('lock', d.roles);
        }else{
            $show.hide();
            $field.show().focus();
            $this.changeBtn('unlock', d.roles);
        }
        return this;
    };

    $.fn.showLoader = function(overlay) {
        var $loader = $(
            '<div style="display:flex;"><div class="loader"><div class="animate"></div><div class="msg"><h3>Poczekaj, wczytuję ...</h3></div></div></div>'
            )
            .hide()
            .appendTo($(this));
        if(overlay || overlay === undefined){
            $loader.addClass('overlay');
        }
        $loader.fadeIn();
        //        var $loader=$('<div class="loader"></div>');
        // $overlay.append();
        // $this.append($overlay);
        // $overlay.fadeIn();
        return $loader;
    };

    $.fn.initFormWidget = function(options) {
        var $this = $(this),
            defValues = $this.data('def-value'),
            widgetInstance = null,
            type,
            dic,
            i,
            dtpOptions;
        if (!Bajt.obj.is(options)) {
            options = $.extend( $this.data('options') || {}, $this.data('o') || {});
        }
        type = options.type || options.widget || $this.data('widget');
        switch (type) {
            case 'multiselect':
                if($this.find('option').length === 0){
                    dic= options.dic || $this.data('dic');
                    if(B.obj.is(dic)){
                        for(i in dic){
                            var l='<option value="'+ (dic[i].v || dic[i].id) +'" title="'+ (dic[i].d || '') +'">'+(dic[i].n || dic[i].name)+'</option>';
                            $this.append( '<option value="'+ (dic[i].v || dic[i].id) +'" title="'+ (dic[i].d || '') +'">'+(dic[i].n || dic[i].name)+'</option>');
                        }
                    }
                }
                if (defValues) {
                    $this.writeField(defValues);
                }
                $this.multiselect(
                    $.extend(
                        true,
                        {
                            buttonWidth: 'auto',
                            allSelectedText: 'Wybrano wszystkie',
                            nonSelectedText: 'Nie wybrano',
                            includeSelectAllOption: true,
                            selectAllText: 'wszystkie',
                            selectAllValue: '',
                            selectAllJustVisible: false,
                            numberDisplayed: 20,
                            enableFiltering: true,
                            filterPlaceholder: 'Szukaj ...',
                            enableCaseInsensitiveFiltering: true,
                            buttonTitle: function(options, select) {
                                var labels = [];
                                options.each(function() {
                                    labels.push($(this).text());
                                });
                                return labels.join(' - ');
                            },
                            templates: {}
                        },
                        options
                    )
                );
                break;
            case 'datepicker':
                var tt= $this.val();
                var startDate = moment($this.val());
                dtpOptions = $.extend(
                    true,
                    {
                        singleDatePicker: true,
                        timePicker: true,
                        timePicker24Hour: true,
                        showDropdowns: true,
                        linkedCalendars: false,
                        alwaysShowCalendars: true,
                        buttonClasses: 'btn btn-sm btn-info',
                        autoApply: true,
                        autoUpdateInput: true,
                        locale: $.extend({}, B.settings.daterange.locale)
                    },
                    options
                );
                if (startDate.isValid()) {
                    dtpOptions.startDate = startDate;
                } else if (dtpOptions.startDate === true) {
                    dtpOptions.startDate = moment();
                } //startDate.format(dtpOptions.locale.format) : moment().format(dtpOptions.locale.format);
                $this.daterangepicker(dtpOptions);
                widgetInstance = $this.data('daterangepicker');
                if (dtpOptions.autoUpdateInput === false) {
                    $this
                        .on('apply.daterangepicker', function(ev, picker) {
                            $(this).val(
                                picker.startDate.format(dtpOptions.locale.format) 
                            ).change();
                        })
                        .on('cancel.daterangepicker', function(ev, picker) {
                            $(this).val('').change();
                        })
                        .on('change', function(e, data){
                            var val = $(this).val(),
                                date = moment($(this).val(), dtpOptions.locale.format);
                            date = date.isValid() ? date : moment();
                            widgetInstance.setStartDate(date);
                            widgetInstance.setEndDate(date);
                        });
                }else{
                    $this.val(widgetInstance.startDate.format(dtpOptions.locale.format));

                }
                $this.on('keyNavigateSuccess', function(){
                    widgetInstance.hide();
                });
                break;
            case 'daterange':
                var ranges = B.settings.daterange.periods;
                    
                defValues = defValues || $this.data('def-range');
                dic = $this.data('dic');
                $this.attr('data-field-type', 'date-range');
                dtpOptions = $.extend(
                    true,
                    {
                        showDropdowns: true,
                        linkedCalendars: false,
                        alwaysShowCalendars: true,
                        buttonClasses: 'btn btn-sm btn-info',
                        autoApply: true,
                        autoUpdateInput: false,
                        locale: $.extend({}, B.settings.daterange.locale)
                    },
                    options
                );
                if ($.isArray(dic)) {
                    ranges = {};
                    for ( i = 0; i < dic.length; i++) {
                        ranges[dic[i].name] = B.momentRange(dic[i].value, dic[i].unit);
                    }
                }
                // if (defValues) {
                //     if(B.str.is(defValues) && defValues.indexOf(' - ') > 0){
                //         defValues = defValues.split(' - ');
                //     }
                //     if(B.obj.isIterate(defValues)){
                //         dtpOptions.startDate = defValues[0];
                //         dtpOptions.endDate = defValues[1];
                //     }else if(ranges.hasOwnProperty(defValues)){
                //         dtpOptions.startDate = ranges[defValues][0].format(dtpOptions.locale.format);
                //         dtpOptions.endDate = ranges[defValues][1].format(dtpOptions.locale.format);
                //     }
                // }   

                // if (ranges.hasOwnProperty(defRange)) {
                //     defRange = ranges[defRange];
                // } else {
                //     defRange = [moment().subtract(1, 'months'), moment()];
                // }

                // dtpOptions.startDate = defRange[0].format(dtpOptions.locale.format);
                // dtpOptions.endDate = defRange[1].format(dtpOptions.locale.format);
                
                dtpOptions.ranges = ranges;
                $this
                    .daterangepicker(dtpOptions)
                    .on('apply.daterangepicker', function(ev, picker) {
                        $(this).val(
                            picker.startDate.format(dtpOptions.locale.format) +
                                ' - ' +
                                picker.endDate.format(dtpOptions.locale.format)
                        );
                    })
                    .on('cancel.daterangepicker', function(ev, picker) {
                        $(this).val('');
                    });
                if (defValues) {
                    $this.writeField(defValues);
                }
                widgetInstance = $this.data('daterangepicker');
                $this.on('keyNavigateSuccess', function(){
                    widgetInstance.hide();
                });
                break;
            case 'switch':
                $this.bootstrapSwitch(
                    $.extend(
                        true,
                        {
                            onColor: 'success',
                            offColor: 'default',
                            handleWidth: '85%',
                            wigetWidth: 100,
                            onText: 'tak',
                            offText: 'nie'
                        },
                        options
                    )
                );
                break;
            case 'nettoinput':
                widgetInstance = $this
                    .nettoInput(
                        $.extend(
                            true,
                            {
                                vat: 23
                            },
                            options
                        )
                    )
                    .data('bajtNettoInput');
                break;
            case 'showDataCollection':
                widgetInstance = $this.showDataCollection($.extend(true, {}, options)).data('bajtShowDataCollection');
                break;
            case 'dtImport':
                widgetInstance = $this.dtImport($.extend(true, {}, options)).data('bajtDtImport');
                break;
            case 'upload':
                $this.upload(options);
                break;
            case 'uploads':
                $this.uploads(
                    $.extend(
                        true,
                        {
                            maxUploads: 3,
                            multiple: true
                        },
                        options
                    )
                );
                break;
            case 'combobox':
                widgetInstance = $this.combobox($.extend(true, {}, options)).data('bajtCombobox');
                break;
            //            case 'copytextarea':
            //                $this.copyTextarea(
            //                    $.extend({
            //                        entitySettings: $this.data('entity-settings')
            //                    },
            //                    options
            //                ));
            //            break;
            case 'jsonedit':
                var $container = $('<div/>').height(250),
                    editor,
                    strValue; 
                $this
                    .hide()
                    .parent()
                    .append($container);
                strValue = $this.val();
                editor = new JSONEditor(
                    $container[0],
                    {
                        mode: 'tree',
                        modes: ['code', 'form', 'text', 'tree', 'view'], // allowed modes
                        onValidate: function(json) {
                            $this.val(JSON.stringify(json)).change();
                        }
                    },
                    strValue !== '' ? JSON.parse(strValue) : null
                );
                break;
        }
        // console.log('*****widget - ');
        // console.log( type);
        // console.log($this.data());
        return widgetInstance;
    };

    $.fn.initElementToggle = function(){
       
        $('.element-toggle').each(function(){
            var $this= $(this),
                o = $.extend(true, {
                    checkedValue: 1,
                    animate: 1,
                    animateSpeed: 'fast',
                    elementSelector : null
                }, $this.data()),
                $elements = $('[data-element=' + o.elementSelector + ']'),
                isChecked = function(){
                    return o.checkedValue == $this.readField();
                },
                show = function( animate ){
                    var 
                        checked = isChecked(),
                        fn = checked
                        ? animate ? 'slideDown' : 'show'
                        : animate ? 'slideUp' : 'hide';
                    $elements[fn](o.animateSpeed);
                    if(animate){
                        var $childs = $elements.children();
                        if(checked){
                            $childs.hide().fadeIn(o.animateSpeed);
                        }else{
                            $childs.fadeOut(o.animateSpeed, function(){$childs.show();});
                        }
                    }
                };          
            show( false );
            $this.on('change', function(){
                show(o.animate);
            });
        });
    };
    

    $.fn.initFormWidgets = function() {
        this.find('[data-widget]').each(function() {
            var $this = $(this),
                widget = $this.data('widget');
            $this.initFormWidget(
                $.extend(
                    true,
                    {},
                    Bajt.obj.is(widget)
                        ? widget
                        : {
                              type: widget
                          }
                )
            );
        });
        //        this.find('[data-exp]').expBtnAction();
    };

    $.fn.initFieldBtn = function() {
        $('[data-btn]', this).each(function() {
            var $button = B.html.get$('btn', {
                    icon: 'library_books'
                }),
                $el = $(this),
                $parent = $el.parent(),
                $buttons,
                o = $el.data('btn');
            if (o.btnClass) {
                $button.addClass(o.btnClass);
            }
            if ($parent.hasClass('input-group')) {
                $buttons = $parent.find('.input-group-append');
            } else {
                $el.wrap('<div class="input-group" />');
                $parent = $el.parent();
                $buttons = $('<div class="input-group-append" />');
                $parent.append($buttons);
            }
            $buttons.append($button);
        });
    };

    $.fn.initModals = function() {
        this.initModalConfirm();
    };

    $.fn.initContent = function() {
        for (var i in contentInits) {
            if (typeof this[contentInits[i]] === 'function') {
                this[contentInits[i]]();
            }
        }
    };

    $.fn.togglePrices =function(){
        var $this=$(this),
            d=$this.data(),
            $container=$this.closest(d.container || 'div'),
            state= d.state || 'netto',
            newState= state === 'netto' ? 'brutto' : 'netto';
            $container
            .find('.prices')
            .removeClass('prices-' + state)
            .addClass('prices-' + newState);
        $container.find('[data-function=togglePrices]').each(function(){
            var $btn=$(this);
            $btn.changeBtn(newState);
            $btn.data('state', newState).attr('data-state', newState);    
        });
        return this;
    };

    $.fn.getFormWidget = function(type) {
        var $this = $(this),
            widgetInstance = null;
        switch (type) {
            case 'field':
                widgetInstance = $this.data('bajtField');
                break;
            case 'multiselect':
                widgetInstance = $this.data('multiselect');
                break;
            case 'datepicker':
                widgetInstance = $this.daterangepicker('instance');
                break;
            case 'switch':
                widgetInstance = $this.bootstrapSwitch('instance');
                break;
            case 'upload':
                widgetInstance = $this.uploads('instance');
                break;
            case 'uploads':
                widgetInstance = $this.uploads('instance');
                break;
            case 'combobox':
                widgetInstance = $this.data('bajtCombobox');
                break;
            case 'positionsForm':
                widgetInstance = $this.data('bajtPositionsForm');
                break;
            case 'copytextarea':
                widgetInstance = $this.data('bajtCopyTextarea');
                break;
            case 'jsonedit':
                break;
        }
        return widgetInstance;
    };

    // font awesome 5
    // $.fn.changeIcon=function(icon){
    //     if(typeof icon === 'string' && icon!==''){
    //         $fa=this.attr('data-icon') ? this : this.find('[data-icon]');
    //         $fa.attr('data-icon', icon.replace('fa-', ''));
    //     }
    //     return this;
    // }

    // font awesome 4.6
    //    $.fn.changeIcon=function(icon){
    //     if(typeof icon === 'string' && icon!==''){
    //         var faClass= icon.indexOf('fa-')< 0 ? 'fa-'+icon : icon,
    //             $fa=this.hasClass('fa') ? this : this.find('.fa');
    //             $fa.removeClassSearch('fa-').addClass(faClass);
    //     }
    //     return this;
    // }

    // materials
    $.fn.changeIcon = function(icon) {
        if (typeof icon === 'string' && icon !== '') {
            var $fa = this.hasClass('material-icons') ? this : this.find('.material-icons');
            $fa.text(icon);
        }
        return this;
    };

    
})(jQuery, Bajt);
