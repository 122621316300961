(function($, B) {
    'use strict';
    B.formCollectionItem = $.extend( true, {}, B.subForm, {
        options: {
            actions: [{ name: 'delete', confirm: true }],
            calc: 0,
            nrField: 'nr',
            actionPrefix: 'item',
            locale: {
                validate: {
                    item: '- wiersz nr '
                }
            },
            selectors:{
                details: '.row-info',
            },
            index: 0,
            nr: 1,
            parent: null,
            uniqueField: 'id'
        },
        _createData: function() {
            B.subForm._createData.call(this);
            this.sum = null;
            return this;
        },
        _createControls: function() {
            B.subForm._createControls.call(this);
            this._createElements(['details']);
            return this;
        },
        _initialize:function(){
            B.subForm._initialize.call(this);
            this.setNr();
            this.calc();
            return this;
        },
        setNr: function(nr) {
            var o = this.options,
                nrField = this.field(o.nrField);
            if (nr !== undefined) {
                o.nr = nr;
            }
            if(nrField){
                nrField.setValue(o.nr);
            }
        },
        _eActionDelete: function(e) {
            stopTrigger(e);
            this._trigger('itemDelete', this.element, { item: this });
        },
        _eCalc:function(e, data){               
            this.calc();
            data.item=this;
        },
        _eNavigateCtrl: function(e, data) {
            data.item = this;
            B.subForm._eNavigateCtrl.apply(this, arguments);
        },
        _initValid: function() {
            if (!this.valid.msg) {
                this.valid.init(
                    'u',
                    {
                        title: this._locale('validate.item', '- wiersz nr ') + this.options.nr,
                        message: ''
                    }
                );
            }
        },
        getUnique: function() {
            var uniqueField=this.field(this.options.uniqueField);
            return uniqueField ? uniqueField.getValue() : 'unique_error';
        },
        setDefault: function(fieldName) {
            var field = fieldName ? this.field(fieldName) : null;
            if (field) {
                field.setDefault();
            }
        },
        changeIndex: function(index) {
            this.options.index = index;
            this.element.changeIdx(this.options.name, index, true );
            this._changeFieldsIdx();
        },
        _changeFieldsIdx: B.basicForm._changeFieldsIdx
    });

    $.widget('bajt.formCollectionItem', $.extend(true, {}, B.formCollectionItem, {
        options:{

        },

    }));

})(jQuery, Bajt);