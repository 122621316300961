(function ($, B) {
    'use strict';
    B.basicForm = $.extend(true, {}, B.basicWidget, {
        _concatFieldsOptions: function(){
            var fields = {};
            for(var i in arguments){
                var ff = arguments[i];
                for (var n in ff) {
                    var f = ff[n],
                        fname = typeof f === 'object' 
                        ? f.name || n
                        : f; 
                    fields[fname] = $.extend(true, 
                        fields[fname] || {},
                        typeof f === 'object' ? f : {}
                    );
                }
            }
            return fields;
        },
        options: {
            animate: '2000',
            formFields: {},
            fieldOptions: {},
            // --- zmiana 2021-02-24
            // tableName: null,
            // dataTable: null,
            // ---
            locale: {
                form: 'Formularz',
                save: 'Zapisz',
                confirm: 'Potwierdź',
                no_change: 'Nie dokonano zmian',
                message: {
                    submit: 'Trwa zapis do bazy danych'
                }
            },
            stopWarning: true,
            stopInfo: true,
            confirmations: {
                close: {
                    content: 'Chcesz zrezygnować ze zmian?'
                }
            },
            expBtns: false,
            formBind: {
                changed: '_eChange',
                calc: '_eCalc',
                submit: '_eSubmit',
                keyNavigate: '_eKeyNavigate'
            },
            selectors: {
                modal: '.modal',
                panel: '.panel',
                wizard: null,
                messages: '.form-messages',
                errors: '.form-errors',
                container: '.form-wrapper',
                // expBtns: '[data-exp]',
                submit: '[type=submit]',
                close: '.btn[data-close]',
                // fieldData: null
            },
            init: {
                changed: false,
                changedEN: null
            },
            effects: {
                container: {
                    show: { fn: 'slideDown' }, 
                    hide: { fn: 'slideUp' }
                },
                form: {
                    show: { fn: 'slideDown' }, 
                    hide: { fn: 'slideUp' }
                }
            }

        },
        _showDataField: null,
        _statusButtons: null,
        _create: function () {
            this.state('init');
            B.basicWidget._create.call(this);
            // this._callFunction('calc');
            this.state('start');
        },
        _createWidgetOptions: function () {
            var o = this.options;
            B.basicWidget._createWidgetOptions.call(this);
            // $.extend(true, o, this.element.data());
            o.admin = this.element.data('admin') ? true : false;
            o.guest = this.element.data('guest') ? true : false;
            o.formName = this.element.attr('name');
            o.selectors.wizard = o.selectors.wizard || '[data-form-wizard=' + o.formName + ']';
            o.fieldSelectorPrefix = '#' + (o.formName ? o.formName + '_' : '');
            o.changedEN = this._getChangedEN();
            this._createFieldsOptions();
            return this;
        },
        _createFieldsOptions: function(){
            this.options.formFields = this._concatFieldsOptions(this.element.data('formFields'), this.options.formFields );            
        },
        _getChangedEN:function(){
            var changedEN = this.options.init.changedEN;
            changedEN = changedEN ? changedEN.split(',') : [];
            if(changedEN.indexOf(this.options.en) < 0){
                changedEN.push(this.options.en);
            }
            return changedEN;
        },
        _createBasicControls: function () {
            B.basicWidget._createBasicControls.call(this);
            // this._createElements(['messages', 'errors', 'expBtns', 'submit', 'close']);
            this._createElements(['messages', 'errors', 'submit', 'close', 'panel', 'modal', 'wizard']);
            // --- zmiana 2021-02-24
            // this.dataTable = this.options.dataTable || this._findDataTable();
            // ---
            this._createStatusButtons();
            return this;
        },
        _createWizard: function () {
            return $(this.options.selectors.wizard);
        },
        _createStatusButtons: function () {
            var status = this.options.statusButtons || {};
            this._statusButtons = {};
            for (var name in status) {
                // var s= B.obj.is(status[i]) ? status[i] : { name: status[i]},
                // $btn = this.$container.find(this._getActionButtonSelector(s.name));
                // if (B.obj.is$($btn)) {
                //  this._statusButtons[s.name] = $btn;
                // }
                var $btn = this.$container.find(this._getActionButtonSelector(name));
                if (B.obj.is$($btn)) {
                    this._statusButtons[name] = $btn;
                }
            }
        },
        _genStatusBtnSelector: function (name) {
            var statusPrefix = this.options.statusPrefix || this.options.en + '-status';
            return '[data-' + statusPrefix + '-action=' + name + ']';
        },
        _createSubmit: function () {
            var $submit = this._findElement('submit');
            $submit
                .attr('data-content', this._locale('no_change'))
                .popover({
                    placement: 'auto',
                    trigger: 'manual'
                })
                .on('mouseleave', function () {
                    $submit.popover('hide');
                });
            return $submit;
        },
        _createClose: function () {
            var $close = this._findElement('close');
            if ($close) {
                this._createConfirmation($close, 'close');
            }
            return $close;
        },
        _createModal: function () {
            return this._getParentElement(this.options.selectors.modal);
        },
        _createPanel: function () {
            return this._getParentElement(this.options.selectors.panel);
        },
        _createBasicOptions: function () {
            B.basicWidget._createBasicOptions.call(this);
            if (!B.obj.is(this.options.entitySettings)) {
                this.options.entitySettings = B.getEntitySettings(this.options.ecn);
            }
            this.options.locale.save = this.$submit.attr('label');
            return this;
        },
        _createData: function () {
            B.basicWidget._createData.call(this);
            this._fields = [];
            this._fieldsByName = {};
            this._wizard = null;
            this.sum = null;
            this.valid = new Valid();
            this._state = null;
            this._status = null;
            this.changed = this.options.init.changed;
            this.exp = true;
            this.confirm = false;
            this.entityId = this.element.data('entity-id') || null;
            this.entityData = this.element.data('entity-data') || null;
            return this;
        },
        _createControls: function () {
            B.basicWidget._createControls.call(this);
            console.log(this.options.formName + ' formFields');
            console.log(this.options.formFields);
            this._createFields();
            this._createWizardWidget();
            // this._createElements(['fieldData']);
            return this;
        },
        _createFields: function () {
            var o = this.options,
                ff = o.formFields;
                console.log(o.formName + ' createFields');
            for (var n in ff) {
                var f = $.extend({
                    name: n,
                    fieldWidget:'field',
                    options : {}
                }, B.obj.is(ff[n]) ? ff[n] : { name: ff[n] } );
                console.log(f);
                this._addField(
                    f.name,
                    f.selector || (o.fieldSelectorPrefix + f.name),
                    f.fieldWidget,
                    this._customFieldOptions(f.name, f.options)
                );
                if (f.showData) {
                    this._showDataField = this._fieldsByName[f.name];
                }
            }
            return this;
        },
        _addField: function (name, selector, widget, options) {
            var o = this.options,
                element = this.element.find(selector);
            if (B.obj.is$(element)) {
                this._fieldsByName[name] = element[widget](
                    $.extend(
                        true, {
                            name: name,
                            ecn: this._getFieldEntityClass(name),
                            parent: this,
                            formName: o.formName,
                            limits: this.getLimits(name)
                        },
                        options
                    )
                ).data('bajt' + B.str.firstUpper(widget));
                this._fields.push(this._fieldsByName[name]);
            }
        },
        _customFieldOptions: function (fieldName, options) {
            var fo = this.options.fieldOptions;
            if (B.obj.is(fo) && fo[fieldName]) {
                var customOptions = {};
                if (typeof fo[fieldName] === 'function') {
                    customOptions = fo[fieldName].call(this, fieldName);
                } else if (B.obj.is(fo[fieldName])) {
                    customOptions = fo[fieldName];
                }
                if (B.obj.is(customOptions)) {
                    $.extend(true, options, customOptions);
                }
            }
            return options;
        },
        _createWizardWidget: function () {
            if(this.$wizard){
                this._wizard = this.$wizard
                    .formWizard({
                        parent: this
                    })
                    .data('bajtFormWizard');
            }
            return this;
        },
        _initialize: function () {
            B.basicWidget._initialize.call(this);
            if (B.obj.is(this.options.initValues)) {
                this.setValues(this.options.initValues);
            }
            this._showData();
            this._initExpBtns();
            this.runWizard();
            return this;
        },
        _initExpBtns: function () {
            if (this.options.expBtns) {
                this.element.initExpBtns({
                    entitySettings: this.options.entitySettings
                });
            }
            return this;
        },
        runWizard:function(){
            if(this._wizard){
                this._wizard.show();
                this._toggleElement(this.$container, false, 'container');
            }
            return this;
        },
        _bind: function () {
            B.basicWidget._bind.call(this);
            this._bindForm();
            this._bindButtons(this.$statusBtns, '_eStatus');
            if(this.$wizard){
                this._on(this.$wizard, {
                    'doneWizard' : this._eDoneWizard 
                });
            }
            history.pushState(null, null, location.href);
            // $(window).on('beforeunload', function(e, data){
            //     history.go(1);
            //     // history.pushState(null, null, location.href);
            //     console.log('beforeunload');
            //     // console.log(e);
            //     console.log(history);
            //     // e.preventDefault();
            //     e.returnValue = 'stop';
            //     return "stop";
            // });
            $(window).on('popstate', function (e, data) {
                console.log('popstate');
                // console.log(e);
                console.log(history);
                var $modals = $('.modal.show');
                if (B.obj.is$($modals)) {
                    $modals.modal('hide');
                    history.go(1);
                }
            });
            $(this.element).on('invalid', function (e) {
                console.log('invalid');
                console.log(e);
                stopTrigger(e);
            });
            return this;
        },
        _bindForm: function () {
            this._on(this.element, this._addBind(this.options.formBind));
            return this;
        },
        _block: function () {
            B.basicWidget._block.call(this);
            this._blockFields();
            this._blockControls(this._blockStatus, ['$close', '$submit', '$statusBtns']);
            return this;
        },
        _blockPartial: function () {
            return this;
        },
        _blockFields: function (block, fields) {
            block = block !== undefined ? block : this._blockStatus;
            if (!Array.isArray(fields)) {
                fields = Object.keys(this._fieldsByName);
            }
            if (B.obj.isIterate(fields)) {
                for (var f in fields) {
                    this._fieldsByName[fields[f]].block(block);
                }
            }
            return this;
        },
        _blockStatus: 0,
        _calc: function () {
            this._sumShow();
        },
        _changeFieldsIdx: function () {
            for (var i = 0; i < this._fields.length; i++) {
                if (typeof this._fields[i].changeChildsIdx === 'function') {
                    this._fields[i].changeChildsIdx();
                }
            }
        },
        _eCalc: function (e, data) {
            this.summary();
        },
        _eChange: function (e, data) {
            stopTrigger(e);
            // console.log('basicForm change');
            if (!this.allowedOperation('changeForm')) {
                return;
            }
            this.state('changing');
            // if (data.calc) {
            //     this._callFunction('calc', { eventData: data });
            // }
            // if (data.summary) {
            //     this._callFunction('summary', { eventData: data });
            // }
            if (B.obj.is(data.field)) {
                this._callFunction('_changeField' + B.str.firstUpper(data.field.getName()), data);
            }
            this._callFunction('_customChange', data);
            this._setChanged(true);
            this._showData();
            //            this.$return.hide();
            this.state('normal');
        },
        _eWindowNavigate: function (e, data) {
            var $modals = $('.modal.show');
            if (B.obj.is$($modals)) {
                stopTrigger(e);
                $modals.modal('hide');
            }
        },
        _eKeyNavigate: function (e, data) {
            if (B.obj.getValue('field', data)) {
                this._eNavigateFields(e, data);
            } else {
                this._eNavigateCtrl(e, data);
            }
        },
        _eNavigateCtrl: function (e, data) {
            if (data.step === -1 || data.step === 1) {
                stopTrigger(e);
                this.element.naviElement(e.target, data);
            } else {
                data.index = this.element.getNaviIndex(e.target, data);
            }
        },
        _eNavigateFields: function (e, data) {
            stopTrigger(e);
            var step = data.step === 3 ? 1 : data.step,
                fc = this._fields.length,
                i = this._fields.indexOf(data.field) + step;
            while (i >= 0 && i < fc && !this._fields[i].option('navi')) {
                i += step;
            }
            if (B.obj.is(this._fields[i])) {
                this._fields[i].focus(data);
                data.field.element.trigger('keyNavigateSuccess');
            }
        },
        _eDoneWizard:function(e, data){
            this._wizard.hide();
            this._toggleElement(this.$container, true, 'container');
        },
        _getFieldEntityClass: function (fieldName) {
            var o = this.options,
                fields = o.entitySettings.fields;
            return B.obj.is(fields.childs) && fields.childs.hasOwnProperty(fieldName) ?
                fields.childs[fieldName] :
                o.ecn;
        },
        _initValid: function () {
            if (!this.valid.msg) {
                this.valid.init(
                    's', {
                        title: this._locale('validate.form', 'Formularz'),
                        message: ''
                    }
                );
            }
            return this.valid;
        },
        _setChanged: function (changed) {
            if (undefined !== changed) {
                this.changed = changed ? true : false;
            }
            if (B.obj.is$(this.$close)) {
                this.$close.confirmation(this.changed ? 'enable' : 'disable');
            }
        },
        _setConfirm: function (confirm) {
            this.confirm = confirm ? true : false;
            this.$submit
                .find('.text')
                .text(this.confirm ? this._locale('confirm', 'Potwierdzam') : this._locale('save', 'Zapisz'));
        },
        _setState: function (state, data) {
            this._state = state;
            switch (this._state) {
                case 'form-serialize':
                    this.block(false);
                    break;
                case 'init':
                    break;
                case 'start':
                    // console.log('state start');
                    this.block(false);
                    this._blockPartial();
                    this.$submit.popover('hide');
                    this._setChanged();
                    this._setConfirm(false);
                    this.showMessages();
                    this._showStatusBtn();
                    this._showExports();
                    break;
                case 'normal':
                    // console.log('state normal');
                    this.block(false);
                    this._blockPartial();
                    this.$submit.popover('hide');
                    this.showMessages();
                    this._showStatusBtn();
                    this._showExports();
                    break;
                case 'changing':
                    // console.log('state changing');
                    this.showMessages();
                    this._showExports('clear');
                    break;
                case 'validate':
                    // console.log('state validate');
                    break;
                case 'confirm':
                    this.changed = false;
                    this._setConfirm(true);
                    break;
                case 'submit':
                    // console.log('state submit');
                    this.block(true);
                    this.showErrors();
                    this.showMessages({
                        message: this._locale('message.submit', 'Trwa zapis do bazy danych'),
                        type: 'i'
                    });
                    break;
                case 'submitSuccess':
                    this.block(false);
                    this._blockPartial();
                    this._changeFieldsIdx();
                    this._setChanged(false);
                    this._setConfirm(false);
                    this._reloadDataTables();
                    // this._showReturn();
                    this._showStatusBtn();
                    this._showExports();
                    // --- zmiana 2021-02-24
                    this._triggerSubmited();
                    // ---
                    break;
                case 'submitError':
                    this.block(false);
                    this._setConfirm(false);
                    this.showMessages();
                    break;
            }
        },
        _showData: function () {
            if (this._showDataField) {
                var data = this._showDataField.value();
                if (B.obj.is(data)) {
                    this.$container.fill(data, this.options.ecn);
                }
            }
            // if(B.obj.is$(this.$fieldData)){
            //     var data = this.$fieldData.exportField();
            //     if(B.obj.is(data)){
            //         this.$container.fill(data, this.options.ecn);
            //     }
            // }
        },
        _showExports: function (clear) {
            // console.log('showExports' + clear);
            if (clear) {
                this.element.data('entity-data', null);
            }
            if (this.options.expBtns) {
                this.element.toggleExpBtns(this.allowedOperation('showExports'));
            }
            return this;
        },
        _showStatusBtn: function () {
            if (B.obj.isNotEmpty(this.$statusBtns)) {
                var status = this._statusRead(),
                    o = this.options;
                for (var t in this.$statusBtns) {
                    var sBtns = o.statusButtons[t];
                    if (this.allowedOperation(t + 'Status')) {
                        var opt = sBtns[status],
                            rm_class = [];
                        for (var i in sBtns) {
                            if (Number(i) !== status && sBtns[i].addClass) {
                                rm_class.push(o.sBtns[i].addClass);
                            }
                        }
                        this.$statusBtns[t].find('.fa').changeIcon(opt.icon);
                        this.$statusBtns[t].find('.text').html(opt.label);
                        this.$statusBtns[t]
                            .removeClass(rm_class.join(' '))
                            .addClass(opt.addClass)
                            .attr('title', opt.title)
                            .show();
                    } else {
                        this.$statusBtns[t].hide();
                    }
                }
            }
            return this;
        },
        // _showReturn: function() {
        //     if (this.allowedOperation('return')) {
        //         this.$return.show();
        //     } else {
        //         this.$return.hide();
        //     }
        //     return this;
        // },
        _statusRead: function () {
            this._status = B.obj.is(this._fieldsByName['status']) ? Number(this._fieldsByName['status'].value()) : 0;
            return this._status;
        },
        _statusSet: function (status) {
            this._status = status;
            if (B.obj.is(this._fieldsByName['status'])) {
                this._fieldsByName['status'].value(status);
            }
        },
        _eStatusNext: function (e) {
            this._statusSet(this._status + 1);
        },
        _eStatusPrev: function (e) {
            this._statusSet(this._status - 1);
        },
        // --- zmiana 2021-02-24
        // _findDataTable:function(){
        //     var dt = DT.getFromElement(this.$modal) || DT.getFromElement(this.$panel);
        //     if(!dt){
        //         var tableName = this.options.tableName || this.options.entitySettings.en;
        //         if(tableName){
        //             var w = window.opener || window;
        //             dt = w.dataTables ? w.dataTables[tableName] || null : null;
        //         }
        //     }
        //     return dt;
        // },
        // _reloadDataTable:function(){
        //     if (this.dataTable) {
        //         this.dataTable.ajax.reload();
        //     }            
        // },
        _reloadDataTables:function(){
            DT.reloadTables(this.options.changedEN);
            if(window.opener){
                window.opener.DT.reloadTables(this.options.changedEN);
            }            
        },
        _triggerSubmited: function(data){
            data = $.extend(true, { 
                    ecn : this.options.ecn,
                    en: this.options.en
                },
                data || {}
            );
            $('body').trigger('form.submited', data);
            return this;
        },
        // ---
        _eSubmit: function (e, data) {
            stopTrigger(e);
            var action = B.obj.is(data) && data.hasOwnProperty('action') ? data.action : 'submit';
            this._callFunction('_preSubmit');
            // valid = this.validate();
            // if (this.inState('notValid')) return;
            var that = this,
                o = this.options,
                $form = this.element,
                url = $form.attr('action'),
                fdata = B.obj.is(data) && B.obj.is(data.fdata) ? data.fdata : this.getFormData();
                if (this.state(action, data) !== 'submit') {
                    return;
                }
                $.ajax({
                    type: $form.attr('method'),
                    url: url,
                    data: fdata
                })
                .done(function (data) {
                    //                        var showMessage=(typeof data.show  !== 'undefined' && data.show === '1') ||B.obj.is$($panel);
                    that.state('changing');
                    if (!that.entityId) {
                        that.entityId = data.id;
                        $form.attr('data-entity-id', data.id);
                        if (data.toEdit) {
                            var editParam;
                            if (B.obj.is(data.editParam)) {
                                editParam = data.editParam;
                            } else {
                                editParam = {
                                    title: 'Edycja',
                                    urls: {
                                        form: B.url.modify(
                                            $form.attr('action'),
                                            '/create',
                                            '/' + data.id + '/update'
                                        ),
                                        site: B.url.modify(window.location.href, '/new', '/' + data.id + '/edit')
                                    },
                                    submit: {
                                        label: 'Aktualizuj',
                                        title: 'Aktualizuj'
                                    }
                                };
                            }
                            $form.attr('method', 'PUT').attr('action', editParam.urls.form);
                            that.$submit
                                .attr('title', editParam.submit.title)
                                .html(editParam.submit.label)
                                .val(editParam.submit.label);
                            if (!(that.$modal || that.$panel)) {
                                B.url.setPageTitle(editParam.title);
                                window.history.replaceState('', '', editParam.urls.site);
                            }
                        } else {
                            that.$submit.hide();
                        }
                    }
                    that.update(data.fields);
                    that.setEntityData(data.entity_data);
                    that._callFunction('_postSubmit', data);
                    that.state('submitSuccess');
                    $form.trigger('submited', data);
                    if (that.$modal && !(data.show || data.edit)) {
                        that.$modal.modal('hide');
                    } else {
                        that.showMessages(data.messages);
                    }
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    that.state('submitError');
                    if (typeof jqXHR.responseJSON !== 'undefined') {
                        if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
                            $form.find('.form_body').html(jqXHR.responseJSON.form_body);
                            console.log('form_errors');
                            $form.initFormWidgets();
                        }
                        that.showErrors(jqXHR.responseJSON.errors);
                    } else if (jqXHR.responseText) {
                        that.showErrors(jqXHR.responseText);
                    } else {
                        alert(errorThrown);
                    }
                });
        },
        _summary: function () {
            this._sumShow();
        },
        _sumFields: function (fields) {
            var fs = {
                sum: null
            };
            if (Array.isArray(fields)) {
                for (var i = 0; i < fields.length; i++) {
                    var field = this.field(fields[i]);
                    B.obj.addSum(fs, field ? field.value() : null);
                }
            }
            return fs.sum;
        },
        // _sumVal:function(val){
        //     var sumField=this.field(this.options.summaryField);
        //     if(sumField){
        //         return sumField.value(val);
        //     }
        // },
        _sumDisp: function () {
            return this;
        },
        _sumVal: function () {
            var i,
                that = this,
                fname = this.options.summaryField,
                _setSum = function (name, val) {
                    if (that._fieldsByName.hasOwnProperty(name)) {
                        that._fieldsByName[name].value(B.obj.is(val) ? B.obj.getValue(name, val, 0) : val || 0);
                    }
                };
            if (fname === undefined) {
                return null;
            }
            if (Array.isArray(fname)) {
                for (i in fname) {
                    _setSum(fname[i], this.sum[fname[i]]);
                }
            } else if (B.obj.is(fname)) {
                for (i in fname) {
                    _setSum(i, this.sum[fname[i]]);
                }
            } else {
                _setSum(fname, this.sum);
            }
            return this;
        },
        _sumShow: function () {
            this._sumVal();
            this._sumDisp();
            return this;
        },
        allowedOperation: function (operation, data) {
            var o = this.options,
                allow = true,
                editable = ['start', 'normal', 'confirm', 'submitSuccess', 'submitError'];
            switch (operation) {
                case 'changeState':
                    break;
                case 'showExports':
                    allow = this.entityData && !this.changed;
                    break;
                case 'change':
                    allow = this.inState(editable);
                    break;
                case 'submit':
                    if (this.changed) {
                        switch (this.validate().valid) {
                            case 'e':
                                allow = false;
                                break;
                            case 'w':
                                allow = !o.stopWarning;
                                if (o.stopWarning) {
                                    this.state('confirm');
                                }
                                break;
                            case 'i':
                                allow = !o.stopInfo;
                                if (o.stopInfo) {
                                    this.state('confirm');
                                }
                                break;
                            default:
                                allow = true;
                        }
                    } else {
                        allow = this.confirm || this.$submit.val().toUpperCase() === 'REMOVE';
                        if (!allow) {
                            this.$submit.popover('show');
                        }
                    }
                    break;
                case 'return':
                    allow = !this.changed;
                    break;
            }
            if (allow) {
                allow = this._callFunction('_customAllowedOperation', operation, data) === false ? false : allow;
            }
            return allow;
        },
        calc: function () {
            this.sum = null;
            if (this.options.calc) {
                this._calc();
            }
            return this.sum;
        },
        close: function () {
            //!!!!!!!!!!!!!!!!!!!!!!!!!!!
            this.triggerOpener('close');
            // if (window.opener) {
            //     if (
            //         B.obj.is(window.opener.dataTables) &&
            //         window.opener.dataTables.hasOwnProperty(this.options.entitySettings.en)
            //     ) {
            //         window.opener.dataTables[this.options.entitySettings.en].ajax.reload();
            //     } else {
            //         window.opener.location.reload();
            //     }
            // }
        },
        triggerOpener:function(name, data){
            if(window.opener){
                window.opener.trigger('bajt.form.' + name, $.extend(true, {
                    ecn: this.options.ecn
                }, data || {} ));
            }
            return this;
        },
        field: function (index) {
            return typeof index === 'number' ? this._fields[index] : this._fieldsByName[index];
        },
        getFormData: function () {
            var fdata,
                state = this._state;
            this.state('serialize');
            fdata = this.element.serialize();
            // console.log(this.element.serializeArray());
            this.state(state);
            return fdata;
        },
        getLimits: function (name) {
            var o = this.options,
                l = B.obj.is(o.limits) ?
                o.limits :
                B.obj.getValue('entitySettings.limits', o);
            return B.obj.is(l) && l.hasOwnProperty(name) ? l[name] : null;
        },
        getEntityData: function () {
            return B.obj.is(this.entityData) ? B.entity.fill(this.entityData, this.options.ecn) : null;
        },
        getValue: function (field) {
            return field in this._fieldsByName ?
                this._fieldsByName[field].value() :
                undefined;
        },
        getValues: function (fields) {
            var i,
                values = {};
            if (Array.isArray(fields)) {
                for (i in fields) {
                    if (this._fieldsByName.hasOwnProperty(fields[i])) {
                        values[fields[i]] = this._fieldsByName[fields[i]].value();
                    }
                }
            } else {
                for (i in this._fieldsByName) {
                    values[i] = this._fieldsByName[i].value();
                }
            }
            return values;
        },
        inState: function (checkStates) {
            if (!Array.isArray(checkStates)) {
                checkStates = [checkStates];
            }
            return checkStates.indexOf(this._state) >= 0;
        },
        setEntityData: function (entityData) {
            if (B.obj.is(entityData)) {
                this.entityData = entityData;
            } else {
                this.entityData = null;
            }
            this.element.data('entity-data', entityData);
        },
        setValues: function (values) {
            if (B.obj.is(values)) {
                for (var name in values) {
                    var field = this.field(name);
                    if (field) {
                        if (B.isFunction(field.setValues)) {
                            field.setValues(values[name]);
                        } else {
                            field.setValue(values[name]);
                        }
                    }
                }
            }
        },
        update: function (values) {
            if (B.obj.is(values)) {
                for (var name in values) {
                    var field = this.field(name);
                    if (field) {
                        field.update(values[name]);
                    }
                }
            }
        },
        showErrors: function (errors) {
            this.$errors.showMessages(errors);
        },
        showMessages: function (messages) {
            this.$messages.showMessages(messages);
        },
        state: function (state, data) {
            if (state !== undefined && this.allowedOperation(state, data)) {
                this._setState(state, data);
                this._customState(state, data);
            }
            return this._state;
        },
        _customState: function (state, data) {},
        summary: function () {
            this.sum = null;
            if (this.options.calc) {
                this._summary();
            }
            return this.sum;
        },
        validate: function () {
            if (!this.inState('valid') && this.allowedOperation('validate')) {
                this.valid.init();
                this._validateFields();
                if (this.valid.msg) {
                    this.showMessages(this.valid.msg);
                }
                this.state(this.valid.valid !== 'e' ? 'valid' : 'notValid');
            }
            return this.valid;
        },
        _validateFields: function () {
            for (var i in this._fields) {
                var fvalid = this._fields[i].validate();
                if (fvalid.msg) {
                    this._initValid();
                    this.valid.add(fvalid);
                }
            }
        }
    });

    $.widget(
        'bajt.ajaxForm',
        $.extend(true, {}, B.basicForm, {
            options: {}
            // _bind: function() {
            //     // console.log('ajaxForm - _bind');
            //     this._bindForm();
            //     if (B.obj.is$(this.$close)) {
            //         this._on(this.$close, {
            //             click: this.close,
            //             touchend: this.close

            //         });
            //     }
            // }
        })
    );


    $.fn.initAjaxForm = function () {
        var $form = $(this).find('form[data-form=ajax]');
        if (B.obj.is$($form)) {
            $form.ajaxForm();
        }
    };

    $.widget(
        'bajt.sqlForm',
        $.extend(true, {}, B.basicForm, {
            options: {}
        })
    );


    $.fn.initTestForms = function () {
        var $form = $(this).find('form[data-form=test-sql]');
        if (B.obj.is$($form)) {
            $form.sqlForm();
        }
    };


    $.widget(
        'bajt.loginForm',
        $.extend(true, {}, B.basicForm, {
            options: {
                init: {
                    changed: true
                },
                locale: {
                    form: 'Formularz',
                    save: 'Zaloguj',
                    message: {
                        submit: 'Trwa logowanie do systemu'
                    }
                }
            },
            _triggerSubmited: function(data){
                $('body').trigger('logon', data);
                return this;
            },
        })
    );

    $.fn.initLoginForm = function () {
        var $form = $(this).find('form[data-form=login]');
        if (B.obj.is$($form)) {
            $form.loginForm();
        }
    };


})(jQuery, Bajt);