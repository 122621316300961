(function($, B) {
    'use strict';
    B.formCollection = $.extend( true, {}, B.basicWidget, {
        options:{
            actions: [ { name:'import', container: 'body' }, { name: 'add' }, { name:'clear', container: 'body' } ],
            autoNew: false,
            sortable: false,
            bind: {
                // changed: '_eChange',
                calc: '_eCalc',
                itemDelete: '_eItemDelete',
                keyNavigate: '_eKeyNavigate',
                sortupdate: '_eSortUpdate'

            },
            calc: 0,
            ecn: '',
            itemOptions:{
                widget: 'formCollectionItem'
            },
            effects: {
                item: {
                    show: { fn: 'slideDown', options: { duration: 'slow' } }, 
                    hide: { fn: 'slideUp', options: { duration: 'slow' } }
                }
            },
            selectors:{
                item: '.row-pos',
                actionsContainer: '.collection-actions'
                // dataTable: '.dataTable'
            },
            parent: null,
            unique: {
                on: false,
                name: 'id',
                showFilter: false,
                filter: false
            }
        },
        _createData: function() {
            B.basicWidget._createData.call(this);
            $.extend(true, this._data, { 
                count: 0,
                index: 0,
                importQueue: 0
            });
            this._data.items = [];
            this._data.uniques = {};
            this._sum = null;
            this.valid = new Valid();
            return this;
        },        
        _createControls: function(){
            B.basicWidget._createControls.call(this);
            this._createElements(['importModal']);
            // this.$dataTable = $(this.options.selectors.table);
            return this;
        },
        _createImportModal:function(){
            return $(this.options.selectors.importModal || ('#imp_'+this.options.name+'_modal'));
        },
        _initialize: function() {
            B.basicWidget._initialize.call(this);
            var that = this;
            this._initUniques();
            this._get$Items().each(function() {
                that._$itemInit($(this));
            });
            if (this.options.sortable) {
                this.element.sortable({});
            }
            return this;
        },
        _initUniques:function(){
            var uo = this.options.unique;
            if(uo.on){
                var uniques = this.element.data('uniques');
                if(B.obj.is(uniques)){
                    for(var i in uniques){
                        this._data.uniques[i] = 'included';
                    }
                }               
            }
        },
        _get$Items:function(){
            return this.element.find(this.options.selectors.item);
        },
        _$itemInit: function($item) {
            this._itemAdd(this._itemCreate($item), 'included');
        },
        _uniqueSet: function(item, uniqueType) {
            var unique = item.getUnique();
            this._data.uniques[unique] = uniqueType || 'added';
            item.element.data('unique', unique);
            return unique;
        },
        _getUniqueValues:function(uniqueType){
            return B.obj.keys(this._data.uniques, uniqueType ? { included: true, values: [ uniqueType ] } : null );
        },
        _itemCreate:function($item, values, itemOptions){
            var io= $.extend(true, 
                    {
                        parent: this.options.parent,
                        index: this._data.index,
                        formName: this.options.formName,
                        nr: this._data.count + 1,
                        ecn: this.options.ecn,
                        templates: this.options.templates || {},
                        initValues: values
                    }, 
                    this.options.itemOptions, 
                    itemOptions || {}
                );
            return $item[io.widget](io).data(this._getItemWidgetObjectName());
        },  
        _getItemWidgetObjectName:function(){
            return this.options.itemOptions.widgetObject || ('bajt' + B.str.firstUpper(this.options.itemOptions.widget));
        },
        _uniqueCheck: function(item){
            return this._data.uniques.hasOwnProperty(item.getUnique());
        },
        _block:function(){
            B.basicWidget._block.call(this);
            this._blockItems();
            return this;
        },
        _blockItems:function(){
            for (var i in this._data.items) {
                this._data.items[i].block(this._blockStatus);
            }
            return this;
        },
        _eSortUpdate:function(e, ui){
            var that = this,
                itemObjectName = this._getItemWidgetObjectName();
            this._data.items = [];
            this._get$Items().each(function() {
                that._data.items.push($(this).data(itemObjectName));
            });
            this.changeChildsIdx();
            this._changed();
            console.log('sortowanie');
        },
        _eCalc:function(e, data){
            this.summary();
        },
        calc: B.basicForm.calc,
        summary: B.basicForm.summary,
        _summary:function(){
            var items = this._data.items;
            for (var i in items) {
                B.obj.addSum(this, items[i].sum);
            }
            this._sumDisp();
        },
        _calc:function(){
            var items = this._data.items;
            for (var i in items) {
                B.obj.addSum(this, items[i].calc());
            }
            this._sumDisp();
        },
        _sumDisp: B.basicForm._sumDisp,   
        _eKeyNavigate: B.basicForm._eKeyNavigate,
        _eNavigateFields: function(e, data) {
            stopTrigger(e);
            if (B.obj.is(data.item)) {
                this._navigateItems(data);
            } else {
                this._navigateFields(data);
            }
        },
        _navigateItems: function(eventData) {
            var o=this.options,
                items=this._data.items,
                _trigger =function(){
                if(B.obj.is(eventData.field)){
                    eventData.field.element.trigger('keyNavigateSuccess');
                }else if(B.obj.is(eventData.item)){
                    eventData.item.element.trigger('keyNavigateSuccess');                       
                }
            },
            step = eventData.step === 3 ? 1 : eventData.step,
            fieldName = eventData.step === 3 ? o.focusField || 0 : eventData.field.option('name'),
            i = items.indexOf(eventData.item) + step;
            while (i >= 0 && i <= items.length && items[i] === undefined) {
                i += step;
            }
            if (items[i]) {
                items[i].focus(fieldName, eventData);
                _trigger();
            } else if (o.autoNew && i > 0) {
                this._itemNew({}, { focusField: fieldName, eventData: eventData }, o.ecn);
                _trigger();
            }
            // _pUtils.navigate.call(this, this._positions, this.options.positionsOptions, data);
        },            
        _eNavigateCtrl: function(e, data) {
            if (B.obj.getValue('item', data)) {
                stopTrigger(e);
                var pTarget = data.item.option('nr') + (data.step < 0 ? -2 : 0);
                if (0 <= pTarget && pTarget < this._data.items.length) {
                    data.target = data.index || 0;
                    this._data.items[pTarget].element.naviElement(null, data);
                }
            }
        },  
        _eActionAdd:function(e){
            stopTrigger(e);
            this._itemNew();
        },
        _eActionImport:function(e){
            stopTrigger(e);
            this.$importModal.modalImport('show', { form: this, fnImport: this.import } );
        },
        // _eActionDtImport:function(e){
        //     stopTrigger(e);
        //     if(B.obj.is$(this.$dataTable)){
                
        //     }
        // },
        _eActionClear: function(e) {
            stopTrigger(e);
            this.clear();
        },
        clear: function() {
            var that=this,
                c=0,
                clearCallback=function(){
                    if(c > 0){
                        return;
                    }
                    c=1;
                    that.element.empty();
                    that._createData();
                    that._changed();
                    that._trigger('empty' + that.options.ecn);   
                };
            this._toggleElement(this._get$Items(), false, 'item', clearCallback);
        },
        _eItemDelete:function(e, data){
            stopTrigger(e);
            var that = this,
                item=data.item,
                $item = item.$container;
            this._itemDelete(item);
            this._toggleElement($item, false, 'item', function(){
                $item.remove();
                that.changeChildsIdx();
                that._changed();
                that._trigger('remove' + that.options.ecn);
                that._triggerCalc();
            });
        },
        _itemDelete:function(item){
            if (this.options.unique.on) {
                this._uniqueUnset(item);
            }
            this._data.items.splice(this._data.items.indexOf(item), 1);
            this._data.count--;
        },
        _uniqueUnset: function(item) {
            var unique = item.getUnique();
            if (this._data.uniques.hasOwnProperty(unique)) {
                delete this._data.uniques[unique];
            }
        },
        import: function(importData) {
            if (Array.isArray(importData) && importData.length > 0 ) {
                this._data.importQueue = importData.length;
                for (var i = 0, ien = importData.length; i < ien; i++) {
                    this._itemNew(this._itemImportData(importData[i]));
                }
            }
            return this;
        },
        _itemImportData: function(inData) {
            return inData;
        },
        _itemNew: function(values, options) {
            var that = this,
                o=this.options,
                $newItem = this._$itemCreate().hide(),
                item = this._itemCreate($newItem, $.extend( {}, values || {} ), { initDefault : true });
            if(typeof item.calc === 'function'){
                item.calc();
            }
            if (this._canAddItem(item)) {
                this._itemAdd(item);
                this.element.append($newItem);
                this._toggleElement($newItem, true, 'item', function() {
                    item.focus(B.obj.getValue('focusField', options, o.focusField));
                    that._changed();
                    that._trigger('new' + o.ecn);
                    that._triggerCalc();
                } );
            }
            this._reduceImportQue();
        },
        _$itemCreate:function(){
            return  this._createFromPrototype(this._data.index);
        },
        _canAddItem:function(item){
            return !this.options.unique.on || !this._uniqueCheck(item);
        },
        _itemAdd: function(item, uniqueType) {
            this._data.items.push(item);
            this._data.index++;
            this._data.count++;
            if (this.options.unique.on) {
                this._uniqueSet(item, uniqueType);
            }
        },   
        _reduceImportQue:function(){
            if (typeof this._data.importQueue === 'number' && this._data.importQueue > 0) {
                this._data.importQueue--;
            }
        }, 
        _changed:function(data){
            this._trigger('changed', this.element, $.extend( data || {}, { summary : this.options.calc } ));
        },
        _triggerCalc:function(){
            if(this.options.calc){
                this._trigger('calc');   
            } 
        },
        _trigger: function(name, $element, data){
            B.basicWidget._trigger.call(this, name, $element, $.extend(data || {}, { collection: this }));
        },
        validate: function(){
            this.valid.init();
            this._validateItems();
            return this.valid;       
        },
        _validateItems: function() {
            for (var i =0 ; i < this._data.items.length; i++) {
                var pvalid = this._data.items[i].validate();
                if (pvalid.msg) {
                    this._initValid();
                    this.valid.add(pvalid);
                }
            }
        },
        _initValid: function() {
            if (!this.valid.msg) {
                this.valid.init(
                    's',
                    {
                        title: this._locale('validate.collection', 'Kolekcja'),
                        message: ''
                    }
                );
            }
        },
        changeChildsIdx:function(){
            for(var i=0; i < this._data.items.length; i++){
                this._data.items[i].changeIndex(i); 
                this._data.items[i].setNr(i + 1);
            }
            this._data.index=this._data.items.length;
        },
        update:function(itemsData){
            if(Array.isArray(this._data.items) && Array.isArray(itemsData)){
                for(var i in this._data.items){
                    this._data.items[i].update(itemsData[i]);
                }
            }
        }           
    });

    $.widget('bajt.formCollection', $.extend(true, {}, B.formCollection, {
        options:{
        }

    }));

})(jQuery, Bajt);