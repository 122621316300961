Export.createExporter('xls', {
    defaultConfig: {
        extension: '.xlsx'
    },
    // _convertField: function (value, name, options) {
    // 	var val, diff;
    // 	if (Bajt.obj.is(value) && value.hasOwnProperty('Value') ) {
    // 		diff = value.diff;
    // 		value = value.Value;
    // 	}
    // 	if(typeof this.converters[name] === 'function')
    // 		val=this.converters[name](value, diff);
    // 	else if(typeof this.converters.generic === 'function'){
    // 		val=this.converters.generic(value, diff);
    // 	}else{
    // 		switch (name) {
    // 			case 'model':
    // 			case 'handle':
    // 			case 'size':
    // 			case 'cutter':
    // 			case 'doubleSide':
    // 				if (diff)
    // 					val = '* ' + value + ' *';
    // 				break;
    // 			case 'length':
    // 			case 'width':
    // 			case 'quantity':
    // 				val = value.toString();
    // 				break;
    // 			case 'area':
    // 				val = value.toFixed(3);
    // 				break;
    // 		}

    // 	}
    // 	return (typeof val !== 'undefined') ? val : value;
    // },
    _excelStrings: {
        // Excel - Pre-defined strings to build a basic XLSX file
        '_rels/.rels':
            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">' +
            '<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="xl/workbook.xml"/>' +
            '</Relationships>',

        'xl/_rels/workbook.xml.rels':
            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">' +
            '<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet" Target="worksheets/sheet1.xml"/>' +
            '<Relationship Id="rId2" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles" Target="styles.xml"/>' +
            '</Relationships>',

        '[Content_Types].xml':
            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">' +
            '<Default Extension="xml" ContentType="application/xml" />' +
            '<Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml" />' +
            '<Default Extension="jpeg" ContentType="image/jpeg" />' +
            '<Override PartName="/xl/workbook.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml" />' +
            '<Override PartName="/xl/worksheets/sheet1.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml" />' +
            '<Override PartName="/xl/styles.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml" />' +
            '</Types>',

        'xl/workbook.xml':
            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">' +
            '<fileVersion appName="xl" lastEdited="5" lowestEdited="5" rupBuild="24816"/>' +
            '<workbookPr showInkAnnotation="0" autoCompressPictures="0"/>' +
            '<bookViews>' +
            '<workbookView xWindow="0" yWindow="0" windowWidth="25600" windowHeight="19020" tabRatio="500"/>' +
            '</bookViews>' +
            '<sheets>' +
            '<sheet name="" sheetId="1" r:id="rId1"/>' +
            '</sheets>' +
            '</workbook>',

        'xl/worksheets/sheet1.xml':
            '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
            '<worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" mc:Ignorable="x14ac" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac">' +
            '<sheetData/>' +
            '</worksheet>',

        'xl/styles.xml':
            '<?xml version="1.0" encoding="UTF-8"?>' +
            '<styleSheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" mc:Ignorable="x14ac" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac">' +
            '<fonts count="5" x14ac:knownFonts="1">' +
            '<font>' +
            '<sz val="11" />' +
            '<name val="Calibri" />' +
            '</font>' +
            '<font>' +
            '<sz val="11" />' +
            '<name val="Calibri" />' +
            '<color rgb="FFFFFFFF" />' +
            '</font>' +
            '<font>' +
            '<sz val="11" />' +
            '<name val="Calibri" />' +
            '<b />' +
            '</font>' +
            '<font>' +
            '<sz val="11" />' +
            '<name val="Calibri" />' +
            '<i />' +
            '</font>' +
            '<font>' +
            '<sz val="11" />' +
            '<name val="Calibri" />' +
            '<u />' +
            '</font>' +
            '</fonts>' +
            '<fills count="6">' +
            '<fill>' +
            '<patternFill patternType="none" />' +
            '</fill>' +
            '<fill/>' + // Excel appears to use this as a dotted background regardless of values
            '<fill>' +
            '<patternFill patternType="solid">' +
            '<fgColor rgb="FFD9D9D9" />' +
            '<bgColor indexed="64" />' +
            '</patternFill>' +
            '</fill>' +
            '<fill>' +
            '<patternFill patternType="solid">' +
            '<fgColor rgb="FFD99795" />' +
            '<bgColor indexed="64" />' +
            '</patternFill>' +
            '</fill>' +
            '<fill>' +
            '<patternFill patternType="solid">' +
            '<fgColor rgb="ffc6efce" />' +
            '<bgColor indexed="64" />' +
            '</patternFill>' +
            '</fill>' +
            '<fill>' +
            '<patternFill patternType="solid">' +
            '<fgColor rgb="ffc6cfef" />' +
            '<bgColor indexed="64" />' +
            '</patternFill>' +
            '</fill>' +
            '</fills>' +
            '<borders count="2">' +
            '<border>' +
            '<left />' +
            '<right />' +
            '<top />' +
            '<bottom />' +
            '<diagonal />' +
            '</border>' +
            '<border diagonalUp="false" diagonalDown="false">' +
            '<left style="thin">' +
            '<color auto="1" />' +
            '</left>' +
            '<right style="thin">' +
            '<color auto="1" />' +
            '</right>' +
            '<top style="thin">' +
            '<color auto="1" />' +
            '</top>' +
            '<bottom style="thin">' +
            '<color auto="1" />' +
            '</bottom>' +
            '<diagonal />' +
            '</border>' +
            '</borders>' +
            '<cellStyleXfs count="1">' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" />' +
            '</cellStyleXfs>' +
            '<cellXfs count="56">' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="4" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="5" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="5" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="5" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="5" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="5" borderId="0" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="0" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="0" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="0" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="0" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="2" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="2" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="2" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="2" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="2" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="3" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="3" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="3" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="3" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="3" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="4" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="4" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="4" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="4" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="4" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="5" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="1" fillId="5" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="2" fillId="5" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="3" fillId="5" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="4" fillId="5" borderId="1" applyFont="1" applyFill="1" applyBorder="1"/>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment horizontal="left"/>' +
            '</xf>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment horizontal="center"/>' +
            '</xf>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment horizontal="right"/>' +
            '</xf>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment horizontal="fill"/>' +
            '</xf>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment textRotation="90"/>' +
            '</xf>' +
            '<xf numFmtId="0" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
            '<alignment wrapText="1"/>' +
            '</xf>' +
            '</cellXfs>' +
            '<cellStyles count="1">' +
            '<cellStyle name="Normal" xfId="0" builtinId="0" />' +
            '</cellStyles>' +
            '<dxfs count="0" />' +
            '<tableStyles count="0" defaultTableStyle="TableStyleMedium9" defaultPivotStyle="PivotStyleMedium4" />' +
            '</styleSheet>'
    },
    _serialiser: new XMLSerializer(),
    _ieExcel: undefined,
    /**
     * Create an XML node and add any children, attributes, etc without needing to
     * be verbose in the DOM.
     *
     * @param  {object} doc      XML document
     * @param  {string} nodeName Node name
     * @param  {object} opts     Options - can be `attr` (attributes), `children`
     *   (child nodes) and `text` (text content)
     * @return {node}            Created node
     */
    _createNode: function(nodeName, opts) {
        var tempNode = this.rels.createElement(nodeName);
        if (opts) {
            if (opts.attr) {
                $(tempNode).attr(opts.attr);
            }

            if (opts.children) {
                $.each(opts.children, function(key, value) {
                    tempNode.appendChild(value);
                });
            }

            if (opts.text) {
                tempNode.appendChild(this.rels.createTextNode(opts.text));
            }
        }
        return tempNode;
    },
    /**
     * Convert from numeric position to letter for column names in Excel
     * @param  {int} n Column number
     * @return {string} Column letter(s) name
     */
    _createCellPos: function(n) {
        var ordA = 'A'.charCodeAt(0);
        var ordZ = 'Z'.charCodeAt(0);
        var len = ordZ - ordA + 1;
        var s = '';

        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }

        return s;
    },
    /**
     * Get the sheet name for Excel exports.
     *
     */
    _sheetName: function() {
        var name = 'Sheet1';
        if (this.config.sheetName) {
            name = this.config.sheetName.replace(/[\[\]\*\/\\\?\:]/g, '');
        }
        return name;
    },
    /**
     * Get the width for an Excel column based on the contents of that column
     * @param  {object} data Data for export
     * @param  {int}    col  Column index
     * @return {int}         Column width
     */
    _colWidth: function(data, col) {
        var max = data.header[col].length;
        var len;
        if (data.footer && data.footer[col].length > max) {
            max = data.footer[col].length;
        }
        for (var i = 0, ien = data.body.length; i < ien; i++) {
            len = data.body[i][col].toString().length;

            if (len > max) {
                max = len;
            }

            // Max width rather than having potentially massive column widths
            if (max > 40) {
                break;
            }
        }

        // And a min width
        return max > 5 ? max : 5;
    },
    _getXml: function(type) {
        var str = this._excelStrings[type];
        //str = str.replace( /xmlns:/g, 'xmlns_' ).replace( /mc:/g, 'mc_' );
        return $.parseXML(str);
        //			return $.parseXML( str );
    },
    _cell: function(cellData, opt) {
        var cellNode, 
            rowNode, 
            cellIndex = this.current.cell.idx + 1, 
            rowIndex = this.current.row.idx, 
            cellId;
        if (Bajt.obj.is(opt)) {
            if(typeof opt.cellIndex === 'number'){
                cellIndex =  opt.cellIndex;
            }
            if(typeof opt.rowIndex === 'number'){
                rowIndex = opt.rowIndex;
            }
        } else if (typeof opt === 'number') {
            cellIndex = opt;
        }
        cellId = this._createCellPos(cellIndex) + rowIndex;
        if (cellData === null || cellData === undefined) {
            cellData = '';
        }
        // Detect numbers - don't match numbers with leading zeros or a negative
        // anywhere but the start
        if (
            typeof cellData === 'number' ||
            (cellData.match && $.trim(cellData).match(/^-?\d+(\.\d+)?$/) && !$.trim(cellData).match(/^0\d+/))
        ) {
            cellNode = this._createNode('c', {
                attr: {
                    t: 'n',
                    r: cellId
                },
                children: [
                    this._createNode('v', {
                        text: cellData
                    })
                ]
            });
        } else {
            // Replace non standard characters for text output
            var text = !cellData.replace ? cellData : cellData.replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F-\x9F]/g, '');
            cellNode = this._createNode('c', {
                attr: {
                    t: 'inlineStr',
                    r: cellId
                },
                children: {
                    row: this._createNode('is', {
                        children: {
                            row: this._createNode('t', {
                                text: text
                            })
                        }
                    })
                }
            });
        }

        if (!this.doc[rowIndex]) {
            this._row(rowIndex);
        }
        this.doc[rowIndex].cells[cellIndex] = cellNode;
        this.current.cell.node = cellNode;
        this.current.cell.id = cellIndex;
        return cellNode;
    },
    _row: function(rowIndex) {
        var rowNode;
        if (typeof rowIndex !== 'number') {
            rowIndex = this.current.row.idx;
        }
        if (this.doc[rowIndex]) {
            rowNode = this.doc[rowIndex];
        } else {
            rowNode = this._createNode('row', {
                attr: {
                    r: rowIndex
                }
            });
            this.doc[rowIndex] = {
                node: rowNode,
                cells: {}
            };
        }
        this.current.row.node = rowNode;
        this.current.row.idx = rowIndex;
        return rowNode;
    },
    blockData:function(data, options ){
        var i, 
            that=this,
            cellIndex =Bajt.obj.getValue('cellIndex', options),
            rowIndex =Bajt.obj.getValue('rowIndex', options),
            direction=Bajt.obj.getValue('direction', options, 'columns'),
            fields=Bajt.obj.getValue('fields', options),
            _cell=function(cellData){
                that._cell(cellData, {cellIndex: cellIndex, rowIndex: rowIndex });
                if(direction=== 'columns'){
                    cellIndex++;
                }else{
                    rowIndex++;
                }
            };
        if(Bajt.obj.is(fields)){
            for(i in fields){
                _cell(Bajt.obj.getValue(fields[i], data, ''));
            }
        }else{
            for(i in data){
                _cell(data[i]);
            }
        }
    },
    /**
     * Recursively add XML files from an object's structure to a ZIP file. This
     * allows the XSLX file to be easily defined with an object's structure matching
     * the files structure.
     *
     * @param {JSZip} zip ZIP package
     * @param {object} obj Object to add (recursive)
     */
    addToZip: function(zip, obj) {
        var that = this;
        if (this._ieExcel === undefined) {
            // Detect if we are dealing with IE's _awful_ serialiser by seeing if it
            // drop attributes
            this._ieExcel =
                this._serialiser
                    .serializeToString($.parseXML(this._excelStrings['xl/worksheets/sheet1.xml']))
                    .indexOf('xmlns:r') === -1;
        }
        $.each(obj, function(name, val) {
            if ($.isPlainObject(val)) {
                var newDir = zip.folder(name);
                that.addToZip(newDir, val);
            } else {
                if (that._ieExcel) {
                    // IE's XML serialiser will drop some name space attributes from
                    // from the root node, so we need to save them. Do this by
                    // replacing the namespace nodes with a regular attribute that
                    // we convert back when serialised. Edge does not have this
                    // issue
                    var worksheet = val.childNodes[0];
                    var i, ien;
                    var attrs = [];

                    for (i = worksheet.attributes.length - 1; i >= 0; i--) {
                        var attrName = worksheet.attributes[i].nodeName;
                        var attrValue = worksheet.attributes[i].nodeValue;

                        if (attrName.indexOf(':') !== -1) {
                            attrs.push({
                                name: attrName,
                                value: attrValue
                            });

                            worksheet.removeAttribute(attrName);
                        }
                    }

                    for (i = 0, ien = attrs.length; i < ien; i++) {
                        var attr = val.createAttribute(attrs[i].name.replace(':', '_dt_b_namespace_token_'));
                        attr.value = attrs[i].value;
                        worksheet.setAttributeNode(attr);
                    }
                }

                var str = that._serialiser.serializeToString(val);

                // Fix IE's XML
                if (that._ieExcel) {
                    // IE doesn't include the XML declaration
                    if (str.indexOf('<?xml') === -1) {
                        str = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' + str;
                    }

                    // Return namespace attributes to being as such
                    str = str.replace(/_dt_b_namespace_token_/g, ':');
                }

                // Both IE and Edge will put empty name space attributes onto the
                // rows and columns making them useless
                str = str.replace(/<row xmlns="" /g, '<row ').replace(/<cols xmlns="">/g, '<cols>');

                zip.file(name, str);
            }
        });
    },
    converters: {
        toInt: function(value, diff) {
            return parseInt(value, 10);
        },
        generic: function(value, diff) {
            return value;
        },
        validateBarcode:function(value){
            if(typeof value === 'string'){
                return value.replace(/\s+$/, '').replace(/[^a-zA-Z0-9_-\u00A1-\uFFFF!\(\)]/g, '_');
            }
            return '';
        }
    },
    generators: {
        header: function(title, cellIndex) {
            var xls = this.exporter;
            xls._cell(title, cellIndex || 0);
        }
    },
    generate: function() {
        this.rels = this._getXml('xl/worksheets/sheet1.xml');
        var gen = this.getGenerator(),
            relsGet = this.rels.getElementsByTagName('sheetData')[0],
            xlsx = {
                _rels: {
                    '.rels': this._getXml('_rels/.rels')
                },
                xl: {
                    _rels: {
                        'workbook.xml.rels': this._getXml('xl/_rels/workbook.xml.rels')
                    },
                    'workbook.xml': this._getXml('xl/workbook.xml'),
                    'styles.xml': this._getXml('xl/styles.xml'),
                    worksheets: {
                        'sheet1.xml': this.rels
                    }
                },
                '[Content_Types].xml': this._getXml('[Content_Types].xml')
            };
        $('sheets sheet', xlsx.xl['workbook.xml']).attr('name', this._sheetName());
        this.doc = {};
        this.current = {
            sheet: {
                node: null,
                idx: 1
            },
            row: {
                node: null,
                idx: 1
            },
            cell: {
                node: null,
                idx: 0
            }
        };
        gen.generate(this.data, this.getExportOptions());
        // gen.generate(this, this.getExportOptions());
        for (var ir in this.doc) {
            var rowNode = this.doc[ir].node,
                cells = this.doc[ir].cells;
            for (var ic in cells) {
                rowNode.appendChild(cells[ic]);
            }
            relsGet.appendChild(rowNode);
        }
        return xlsx;
    },
    createFile:function(data){
        this.setData(data);
        var filename = this.getFilename();
        var jszip = window.JSZip;
        var zip = new jszip();
        var zipConfig = {
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };
        this.addToZip(zip, this.generate());
        if (zip.generateAsync) {
            // JSZip 3+
            zip.generateAsync(zipConfig).then(function(blob) {
                Export._saveAs(blob, filename);
            });
        } else {
            // JSZip 2.5
            Export._saveAs(zip.generate(zipConfig), filename);
        }
    },
    action: function(data, options) {
        var that = this;
        this.setOptions(options);
        if(B.obj.is(data)){
            this.createFile(data);
        }else{
            B.ajax.data(options.ajax, function(data){
                that.createFile(data.entity);
            });
        }
    }
    // action: function(data, options) {
    //     Export.genConfig(this, options.config);
    //     this.data = data;
    //     this.ecn = options.ecn;

    //     var jszip = window.JSZip;
    //     var zip = new jszip();
    //     var zipConfig = {
    //         type: 'blob',
    //         mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //     };
    //     this.addToZip(zip, this.generate());
    //     //!!!!!!!!!!!!!!!!! zrobić generator nazwy pliku ?
    //     if (this.config.filename === '*' || this.config.filename === '') {
    //         this.config.filename = 'zam_' + data.number;
    //     }

    //     if (zip.generateAsync) {
    //         // JSZip 3+
    //         zip.generateAsync(zipConfig).then(function(blob) {
    //             Export._saveAs(blob, Export._filename(this.config));
    //         });
    //     } else {
    //         // JSZip 2.5
    //         Export._saveAs(zip.generate(zipConfig), Export._filename(this.config));
    //     }
    // }
});
