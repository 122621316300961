(function($, B) {
    'use strict';

    $('body').on('loaded.bs.modal', '.ajax', function() {
        console.log('loaded.bs.modal');
        var $modal = $(this);
        $modal.initFormWidgets();
        // $this.find('.btn-save').hide();
        // $this.find('.btn-ok').hide();
    });

    $('body').on('show.bs.modal', '.ajax', function() {
        console.log('show.bs.modal');
        var $modal = $(this),
            $content = B.modal.getElement($modal, 'content'),
            $body = B.modal.getElement($modal, 'body'),
            modal = $modal.data('bs.modal'),       
            url = $modal.data('url') || modal._config.url;
        $body.showLoader(false);
        $content.load(url, function(responseTxt, statusTxt, xhr) {
            if (statusTxt === 'success') {
                $content.initContent();
            } else {
                $body.html('Error: ' + xhr.status + ': ' + xhr.statusText);
            }
        });
    });

    $('body').on('shown.bs.modal', '.modal', function() {
        $(this).redrawDataTables();
    });

    $('body').on('shown.bs.modal', '.modal[data-set-focus!=""]', function() {
        var $modal = $(this),
            $toFocus = $($modal.data('set-focus'), $modal);
        if (B.obj.is$($toFocus)) {
            var $checked= $toFocus.filter(':checked');
            if($checked.length > 0){
                $checked.first().focus();
            }else{
                $toFocus.first().focus();
            }
        }
    });

    $('body').on('shown.bs.modal', '.modal', function() {
        $(this).calcOverflow();
    });


    $('body').on('hide.bs.modal', '.modal-exp', function() {
        var $modal = $(this);
        var $footer = $modal.find('.modal-footer');
        //    $($modal.data('datatable')).bootstrapTable('refresh');
        $modal
            .find('.form-errors')
            .hide()
            .find('content')
            .empty();
        $modal
            .find('.form-messages')
            .hide()
            .find('content')
            .empty();
        $footer.find('.btn-save').show();
        $footer.find('.btn-cancel').show();
        $footer.find('.btn-ok').hide();
    });


    $('body').on('hidden.bs.modal', '.customized', function() {
        $(this).restoreModalOptions();
    });

    $('body').on('hidden.bs.modal', '.ajax', function() {
        var $modal = $(this);
        $modal
            .clearModal()
            .removeData('bs.modal')
            .removeData('url');
    });
    
    $('body').on('hidden.bs.modal', '.modal', function() {
        var 
            $modal = $(this),
            $caller = $modal.data('callElement');
        console.log('modalHidden');
        if (B.obj.is$($caller)) {
            $caller.focus();
            $modal.removeData('callElement');
            console.log('callElement');
        }
    });

    $('body').on('click', '.btn[data-modal-widget]', function(e) {
        e.preventDefault();
        var $btn = $(this),
            btnData = $btn.data(),
            modal = $(btnData.target).data(btnData.modalWidget);
        modal.show(btnData.options);
    });

    $('body').on('keydown', '.modal', function(e) {
        var charCode = keyPress.getCharCode(e);       
        if (charCode === 13) {
            console.log('You pressed enter!');
            if (e.ctrlKey) {
                var $btnSave = $(this).find('.btn-save');
                if($btnSave.is(':visible')){
                    $btnSave.removeAttr('disabled').focus();
                    $btnSave.click();
                }
            }
        }
    });


})(jQuery, Bajt);
