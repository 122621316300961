(function($, B) {
    'use strict';
    B.ajax = {
        send: function(params, options) {
            options = options || {};
            var that = this,
                $form = options.$form,
                $submit = options.$submit || this.$submit,
                $content = options.$content || $form,
                $modal = options.$modal,
                $tip = options.$tip,
                dataTable = options.dataTable || this.dataTable,
                _block = function(block) {
                    if (typeof that.block === 'function') {
                        that.block(block);
                    } else if (B.obj.is$($submit)) {
                        $submit.prop('disabled', block);
                    }
                },
                _hideSubmit = function() {
                    if (B.obj.is$($submit) && B.obj.getValue('hideSubmit', options)) {
                        $submit.hide();
                    }
                },
                _showMessages = function(message) {
                    if (typeof that.showMessages === 'function') {
                        that.showMessages(message);
                    } else if (B.obj.is$($content)) {
                        $content.find('.messages').showMessages(message);
                    }
                },
                _showErrors = function(errors) {
                    if (typeof that.showErrors === 'function') {
                        that.showErrors(errors);
                    } else if (B.obj.is$($content)) {
                        $content.find('.errors').showMessages(errors);
                    }
                },
                _showTip = function(msg){
                    if($tip){
                        $tip.tooltipMessage({
                            msg: msg
                        });
                    }
                },
                _state = function(state) {
                    if (typeof that.state === 'function') {
                        that.state(state);
                    } else {
                        switch (state) {
                            case 'submit':
                                _block(true);
                                _showErrors();
                                _showMessages(
                                    B.obj.getValue('messages-submit', options, 'Wysyłam dane do serwera')
                                );
                                break;
                            case 'submitSuccess':
                                _showMessages();
                                _block(false);
                                break;
                            case 'submitError':
                                _showMessages();
                                _block(false);
                                break;
                        }
                    }
                },
                _success = function(data, textStatus, jqXHR) {
                    _state('submitSuccess');
                    if (B.obj.is$($form)) {
                        $form.trigger('submited', data);
                    } else if (B.obj.is$($content)) {
                        $content.trigger('submited', data);
                    }
                    if (dataTable) {
                        dataTable.ajax.reload();
                    }
                    if (B.obj.is$($modal) && !(data.show || data.edit)) {
                        $modal.modal('hide');
                    } else {
                        _showMessages(data.messages);
                        _hideSubmit();
                    }
                    _showTip(jqXHR.responseJSON.messages[0]);
                    if(options.afterSuccess){
                        options.afterSuccess(data, textStatus, jqXHR);
                    }
                },
                _error = function(jqXHR, textStatus, errorThrown) {
                    _state('submitError');
                    if (typeof jqXHR.responseJSON !== 'undefined') {
                        if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
                            if (B.obj.is$($form)) {
                                $form.find('.form_body').html(jqXHR.responseJSON.form_body);
                                $form.initFormWidgets();
                            }
                            // console.log('form_errors');
                        }
                        _showErrors(jqXHR.responseJSON.errors);
                        _showTip(jqXHR.responseJSON.errors[0]);
                    } else if (jqXHR.responseText) {
                        _showErrors(jqXHR.responseText);
                    } else {
                        alert(errorThrown);
                    }
                    if(options.afterSuccess){
                        options.afterError(jqXHR, textStatus, errorThrown);
                    }
                };
            _state('submit');
            $.ajax($.extend( {
                success: _success,
                error: _error,
                method: 'POST'
            }, params));
        },
        data:function(ajaxOptions, successCallback, errorCallback){       
            $.ajax($.extend({
                    success: function(data, textStatus, jqXHR ){
                        if(B.isFunction(successCallback)){
                            successCallback(data, textStatus, jqXHR);
                        }
                    },
                    error: function( jqXHR, textStatus, errorThrown ){
                        B.ajax.handleError(jqXHR, textStatus, errorThrown, errorCallback,
                            function(){
                                B.ajax.data(ajaxOptions, successCallback, errorCallback); 
                            }
                        );
                    }
                },
                ajaxOptions
            ));
            
                // .done(successCallback)
                // .fail(function(jqXHR, textStatus, errorThrown) {

                    // if (typeof jqXHR.responseJSON !== 'undefined') {
                    //    console.log(jqXHR.responseJSON.errors);
                    // } else if (jqXHR.responseText) {
                    //    console.log(jqXHR.responseText);
                    // } else {
                    //    console.log(errorThrown);
                    // }
                    // if( B.isFunction(errorCallback)){
                    //     errorCallback(jqXHR, textStatus, errorThrown);
                    // }       
                // });
        },
        handleError:function(jqXHR, textStatus, errorThrown, errorCallback, logonCallback){
            if(jqXHR.status == 403){
                if(B.isFunction(logonCallback)){
                    B.app.onLogin = logonCallback;
                }else if(B.isFunction(errorCallback)){
                    errorCallback(jqXHR);
                }
                $('body').trigger('logout', jqXHR);                       
            }else{
                var $modal = $('#my_modal');
                if(B.obj.is$($modal)) {
                    $modal.find('.modal-content').html(errorThrown);
                    $modal.modal('show');
                } else {
                    alert(errorThrown);
                }                            
                if(B.isFunction(errorCallback)){
                    errorCallback(jqXHR);
                }
            }
        },
        load: function(link, wrapperSellector, successCallback, errorCallback ) {
            var 
                $wrapper = $(wrapperSellector || Bajt.app.selectors.mainWrapper),
                url = $(link).attr('href'),
                $loader = $wrapper.showLoader();
                // console.log('menu-ajax');
            $wrapper.load(
                url,
                {
                    view: 'ajax'
                },
                function(responseText, textStatus, jqXHR) {
                    $loader.remove();
                    if (textStatus === 'error') {
                        B.ajax.handleError(jqXHR, textStatus, responseText, errorCallback,
                            function(){
                                B.ajax.load(link, wrapperSellector, successCallback, errorCallback); 
                            }
                        );
                    } else {
                        window.history.replaceState('', '', url);
                        document.title = $wrapper.find('#page_title').data('title');
                        B.app.initContent(wrapperSellector);
                        if(B.isFunction(successCallback)){
                            successCallback();
                        }
                    }
                }
            );
        }
    };

})(jQuery, Bajt);