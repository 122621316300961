(function($, B) {
    'use strict';



    // $('body').on('mouseover', '.form-control', function(e) {
    //     var p = this.parentElement,
    //         $errors = $(p).find('.info-field');
    //     if ($errors.length > 0) {
    //         $errors.slideDown();
    //     }
    // });

    // $('body').on('mouseout', '.form-control', function(e) {
    //     $(this.parentElement)
    //         .find('.info-field')
    //         .slideUp('fast');
    // });

    $.extend(true, B.events.body, {
        toBind: {
            form: ['mouseover_formcontrol', 'mouseout_formcontrol', 
                'focus_formgroup_tooltip', 'blur_formgroup_tooltip',
                'change_hiddenedit'
            ]
        },
        mouseover_formcontrol:[
            'mouseover', 
            '.form-control', 
            function(e) {
                var p = this.parentElement,
                    $errors = $(p).find('.info-field');
                if ($errors.length > 0) {
                    $errors.slideDown();
                }
            }
        ],
        mouseout_formcontrol: [
            'mouseout', 
            '.form-control', 
            function(e) {
                $(this.parentElement)
                    .find('.info-field')
                    .slideUp('fast');
            }
        ],
        focus_formgroup_tooltip: [
            'focus', 
            '.form-group.is-field-tooltip', 
            function(e){
                var $this=$(this);
                $this.find('.field-tooltip').slideDown('fast');
            }
        ],
        blur_formgroup_tooltip:[
            'blur', 
            '.form-group.is-field-tooltip', 
            function(e){
                var $this=$(this);
                $this.find('.field-tooltip').slideUp('fast');
            }
        ],
        change_hiddenedit:[
            'change',
            '[data-hidden-edit]',
            function(e) {
                stopTrigger(e);
                $(this).trigger('hidden-edit-changed');
            }
        ]
    });

})(jQuery, Bajt);
