(function($, B) {
    'use strict';
    B.subForm = $.extend( true, {}, B.basicWidget, {
        options: {
            // actionPrefix: 'sub',
            formFields: {},
            fieldOptions: {},
            locale: {
                validate: {
                    subForm: 'fromularz potomny'
                }
            },
            formBind: {
                changed: '_eChange',
                calc: '_eCalc',
                keyNavigate: '_eKeyNavigate'
            },
            parent: null
        },
        _createWidgetOptions: function() {
            var o = this.options,
                posId=this.element.attr('id');
            B.basicWidget._createWidgetOptions.call(this);
            o.name = posId.replace(o.formName+'_', '').replace(/_\d+/, '');
            // o.idx=posId.replace(o.formName + '_' + o.name + '_', '');
            o.fieldSelectorPrefix = '#' + posId + '_';
            this._createFieldsOptions();
            return this;
        },
        _createData: function() {
            B.basicWidget._createData.call(this);
            this._fields = [];
            this._fieldsByName = {};
            this.valid = new Valid();
            return this;
        },
        _createControls: function () {
            B.basicWidget._createControls.call(this);
            this._createFields();
            return this;
        },
        _concatFieldsOptions: B.basicForm._concatFieldsOptions,
        _createFieldsOptions: B.basicForm._createFieldsOptions,        
        _createFields: B.basicForm._createFields,
        _addField: B.basicForm._addField,
        _showData: B.basicForm._showData,
        _customFieldOptions: B.basicForm._customFieldOptions,
        _bind:function(){
            B.basicWidget._bind.call(this);
            this._bindForm();
            return this;
        },
        _bindForm: B.basicForm._bindForm,
        _block:function(){
            B.basicWidget._block.call(this);
            this._blockFields();
            return this;
        },
        _blockFields: B.basicForm._blockFields,
        _calc: B.basicForm._calc,
        _eCalc:function(e, data){               
            this.calc();
            data.subForm=this;
        },
        _eKeyNavigate: B.basicForm._eKeyNavigate,
        _eNavigateFields: function(e, data) {
            if (data.step === -1 || data.step === 1 || data.step === 3) {
                var fields = this._fields,
                    fc = fields.length,
                    step = data.step === 3 ? 1 : data.step,
                    i = fields.indexOf(data.field) + step;
                while (i >= 0 && i < fc && !fields[i].option('navi')) {
                    i += step;
                }
                if (fields[i]) {
                    stopTrigger(e);
                    fields[i].focus(data);
                    data.field.element.trigger('keyNavigateSuccess');                
                } else if (data.step === 3) {
                    // (pressed Enter) - not found next field -> next item
                    data.item = this;
                } else {
                    stopTrigger(e);
                }
            } else {
                data.item = this;
                data.step = data.step > 0 ? 1 : -1;
            }
        },
        _eNavigateCtrl: B.basicForm._eNavigateCtrl,
        _getFieldEntityClass: B.basicForm._getFieldEntityClass,
        _initialize:function(){
            B.basicWidget._initialize.call(this);
            if(B.obj.is(this.options.initValues)){
                this.setValues(this.options.initValues);
            }
            this._showData();            
            return this;
        },
        _initValid: function() {
            if (!this.valid.msg) {
                this.valid.init(
                    'u',
                    {
                        title: this._locale('validate.subForm', 'formularz potomny'),
                        message: ''
                    }
                );
            }
        },
        _sumFields: B.basicForm._sumFields,
        _sumVal: B.basicForm._sumVal,
        _sumDisp: B.basicForm._sumDisp,
        _sumShow: B.basicForm._sumShow,
        _validateFields: B.basicForm._validateFields,
        calc: B.basicForm.calc,
        field: B.basicForm.field,
        focus: function(index, eventData) {
            var field = this.field(index ? index : 0);
            if (field) {
                field.focus(eventData);
            }
        },
        getLimits: B.basicForm.getLimits,
        getValue: B.basicForm.getValue,
        getValues: B.basicForm.getValues,
        setValues: B.basicForm.setValues,
        update: B.basicForm.update,
        validate: function() {
            this.valid.init();
            this._validateFields();
            return this.valid;
        }
    });

    $.widget('bajt.subForm', $.extend(true, {}, B.subForm, {
        options:{

        },

    }));

})(jQuery, Bajt);
