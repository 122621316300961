(function($, B) {
    'use strict';

    B.menu = {
        activate:function($link){
            var 
                $item = $link.parent(),
                $dropdownMenu = $item.closest('.dropdown-menu'),
                $dropdown = $dropdownMenu.closest('.dropdown'),
                $menu = $item.closest('ul.nav');
            $menu.find('.active').removeClass('active');
            $item.addClass('active');
            if (Bajt.obj.is$($dropdown)) {
                $dropdownMenu.removeClass('show');
                $dropdown.removeClass('show');
                $dropdown.find('[aria-expanded=true]').attr('aria-expanded', false);
            }            
        }
    };

})(jQuery, Bajt);
