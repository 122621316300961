(function($, B) {
    'use strict';
    $.extend(true, B, {
        astring:{
            validateArray:function(array){
                var ok = true,
                    i = 0;
                while( ok && i < array.length){
                    if(!B.obj.is(array[i])){
                        if(ok = B.isNaturalNumber(array[i])){
                            array[i] = parseInt(array[i]);
                        }
                    }
                    i++;
                }
                return ok ? array : null;
            },
            is: function(str){
                var a = B.str.is(str) ? B.astring.validateArray(str.split(' ')) : null;
                return  Array.isArray(a) && a.length > 0;
            },            
            toArray: function(str){
                var a=[];
                if(Array.isArray(str)){
                    a=B.astring.validateArray(str);
                }else if(B.str.is(str)){
                    a=B.astring.validateArray(str.split(' '));
                }
                return Array.isArray(a) ? a : [];
            },        
            fromArray:function(array){
                var str = null;
                if(Array.isArray(array) && array.length > 0 ){
                    str = B.array.getValues(array).join(' ');
                }
                return str;
            },
            isEqual:function(str1, str2){
                return B.array.haveSameValues(B.astring.toArray(str1), B.astring.toArray(str2));
            }
        }
    });
})(jQuery, Bajt);