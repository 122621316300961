(function($, B) {
    'use strict';
    B.basicWidget = {
        id: null,
        options: {
            animate: 'fast',
            actions: null,
            actionPrefix: null,
            extraActions: null,
            buildActionBtn: true,
            selectors: {
                container: null,
                actionsContainer: '.actions'
            },
            bind: null,
            containerBind:null,
            customActions:[],
            locale:{},
            initData:{},
            templates: {},
            confirmations: {},
            effects: {
                element: {
                    show: { fn: 'slideDown', options: { duration: 'slow' } }, 
                    hide: { fn: 'slideUp', options: { duration: 'slow' } }
                },
                message: {
                    show: { fn: 'slideDown', options: { duration: 'fast' } }, 
                    hide: { fn: 'slideUp', options: { duration: 'fast' } }
                },
                btn: {
                    show: { effect: 'scale', options: { precent: 50 } },
                    hide: { effect: 'scale', options: { precent: 50 } }
                }
            }
        },
        _blockStatus: 0,
        _actionButtons: null,
        _create: function() {
            this._data={};
            this._custom()
                ._createWidgetOptions()
                ._createBasicControls()
                ._createBasicOptions()
                ._build()
                ._createData()
                ._createControls()
                ._initialize()                    
                ._bind();
        },
        _custom: function() {
            var oc = this.options.custom;
            if (B.obj.is(oc)) {
                for (var n in oc) {
                    this[n] = oc[n];
                }
            }
            return this;
        },
        _createWidgetOptions:function(){
            var o=this.options;
            this.id = this.element.attr('id') || null;
            $.extend(true, o, this.element.data('o') || {}, this.element.data('options') || {} );//ostatni argument do usunięcia
            o.ecn = o.ecn || this.element.data('ecn');
            if(o.actionPrefix === null){
                o.actionPrefix = this.id || o.name || o.ecn || null;
            }
            if(!B.obj.is(o.entitySettings)){
                o.entitySettings = B.getEntitySettings(o.ecn);
            }
            o.en = o.en || this.element.data('en') || this.getEntitySetting('en');
            return this;
        },
        _createBasicControls: function() {
            this._createElements(['container', 'actions']);
            //zmiana 2021-03-13
            // this.$container = this._createContainer();
            // this.$actions = this._createActions();
            //***
            return this;
        },
        _createElements:function(names){
            for(var i in names){
                var fnCreate='_create'+B.str.firstUpper(names[i]),
                    $element = typeof this[fnCreate] === 'function' 
                        ? this[fnCreate]()
                        : this._findElement(names[i]);
                this['$'+names[i]]= B.obj.is$($element) ? $element : null;                    
            }
        },
        _findElement:function(name){
            var $element = this.options.selectors[name] ? this.$container.find(this.options.selectors[name]) : null;
            return B.obj.is$($element) ? $element : null;
        },
        _createContainer: function(selector) {
            var $container = this._getParentElement(this.options.selectors.container);
            return B.obj.is$($container) ? $container : this.element;
        }, 
        _getParentElement:function(selector){
            var $parent = this.element.closest(selector);
            return B.obj.is$($parent) ? $parent : null;
        },
        _createActions: function() {
            var $actions= this.options.selectors.actionsContainer ? this.$container.find(this.options.selectors.actionsContainer) : null;
            return B.obj.is$($actions) ? $actions : this.$container;
        },
        _createBasicOptions: function(){
            $.extend(true, 
                this.options, 
                {                       
                    templates: this.element.data('templates') || {},
                    // prototype: {
                    //     tmpl: this.element.data('prototype'),
                    //     name: this.element.data('prototype-name')
                    // }
                }
            );
            return this;
        },
        _build: function() {
            return this;
        }, 
        _createData: function() {
            $.extend(true, this._data, this.options.initData || {} );
            return this;
        },
        _createControls: function() {
            this._createActionsButtons();
            return this;
        },
        _createActionsButtons: function() {
            var that = this,
                _create = function(actions){
                    for (var i in actions) {
                        that._addActionButton(B.obj.is(actions[i]) ? actions[i] : { name: actions[i]});
                    }       
                };
            this._actionButtons = {};
            _create(this.options.actions);
            _create(this.options.extraActions);
            return this;
        },
        _addActionButton:function(btn){
            var $container = btn.container ? $(btn.container) : this.$actions,
                $btn = $container.find(this._getActionButtonSelector(btn.selector || btn.name));
            if(!B.obj.is$($btn) && this.options.buildActionBtn){
                $btn=this._buildActionBtn(btn.name);
            }
            if (B.obj.is$($btn)) {
                this._actionButtons[btn.name] = $btn;
                if(btn.confirm){
                    this._createConfirmation($btn, btn.name);
                }
            }
            return this;
        },
        _getActionButtonSelector:function(name, actionPrefix){
            actionPrefix = actionPrefix || this.options.actionPrefix;
            return '[data-' + ( actionPrefix ? actionPrefix + '-' : '' ) + 'action=' + name + ']';
        },            
        _buildActionBtn:function(action){
            var fnBuild='_buildBtn'+B.str.firstUpper(action),
                $btn= (typeof this[fnBuild] === 'function') ? this[fnBuild]() : $(this.options.templates[action]);
            return B.obj.is$($btn) ? $btn.appendTo(this.$actions) : null;
        },
        _createFromPrototype:function(index){  
            return  B.html.genFromPrototype(this.element.data('prototype'), this.element.data('prototype-name'), index);
        },
        _createConfirmation:function($element, name){
            if(B.obj.is$($element)){
                $element
                .attr('data-toggle', 'confirmation')
                .attr('data-title', $element.attr('title'))
                .confirmation(this._createConfirmOptions(name) );
            }
        },
        _createConfirmOptions:function(name){
            return $.extend({}, B.confirmationOptions, B.obj.getValue( name, this.options.confirmations, {}));
        },
        _buildBtnDelete:function(){
            var $btn=$(this.options.templates.btnDelete);
            this._createConfirmation($btn, 'delete');
            return $btn;
        }, 
        _initialize :function(){
            return this;
        },
        _bind:function(){
            this._bindElement(this.element, this.options.bind);
            this._bindContainer();
            this._bindButtons(this._actionButtons, '_eAction');
            return this;
        },
        _bindContainer:function(){
            if(B.obj.is(this.options.containerBind)){
                this._on(this.$container, this._addBind(this.options.containerBind));
            }
        },
        _bindElement:function($element, bindObject){
            if(B.obj.is$($element)){
                this._on($element, this._addBind(bindObject));
            }
        },
        _bindButtons:function(btns, prefix){
            if (B.obj.is(btns)) {
                for (var a in btns) {
                    if (B.obj.is$(btns[a])) {
                        this._on(
                            btns[a],
                            this._addBind({ click: prefix + B.str.capitalise(a) })
                        );
                    }
                }
            }
            return this;
        },
        _addBind:function(bindNames, bindObject){
            if(!B.obj.is(bindObject)){
                bindObject={};
            }
            if(B.obj.is(bindNames)){
                for(var e in bindNames){
                    if(typeof bindNames[e] === 'function'){
                        bindObject[e]=bindNames[e];
                    }else if(undefined !== this[bindNames[e]]){
                        bindObject[e]=this[bindNames[e]];
                    }
                }        
            }
            return bindObject;
        },
        _isOwnEvent:function(eventData){
            return typeof eventData === 'object' && eventData.caller == this;
        },
        _trigger:function(name, $element, data){
            ($element || this.element ).trigger(name, $.extend(data || {}, { caller: this } ));
        },
        _callFunction: function(name) {
            if (typeof this[name] !== 'function') {
                return null;
            }
            var args = [];
            for (var i = 1; i < arguments.length; i++) {
                args.push(arguments[i]);
            }
            return this[name].apply(this, args);
        },
        _fromDictionary: function(value, options) {
            options = B.obj.is(options) ? options : {};
            if (!options.hasOwnProperty('name')) {
                options.name = 'n';
            }
            return B.dic.from(this.options.dictionary, value, options);
        },
        _locale: function(name, def) {
            return B.obj.getValue(name, this.options.locale, def || '');
        },
        block: function(block) {
            if( (block && this._blockStatus === 0) || (!block && this._blockStatus > 0)){
                this._blockStatus = block ? 1 : 0;
                this._block();
            }
            return this;
        },
        _block:function(){
            this._blockActionButtons();
            this.element
                .prop('disabled', this._blockStatus)
                .trigger('block', {
                    block: this._blockStatus
                });
            return this;
        },           
        _blockActionButtons:function(){
            for (var a in this._actionButtons) {
                this._actionButtons[a].prop('disabled', this._blockStatus);
            }
            return this;
        },
        _blockControls:function(block, controls){
            block = block !== undefined ? block : this._blockStatus;
            for (var c in controls){
                if(B.obj.is$(this[c])){
                    this[c].prop('disabled', block);
                }else if(B.obj.is(this[c])){
                    for(var i in this[c]){
                        if(B.obj.is$(this[c][i])){
                            this[c][i].prop('disabled', block);
                        }
                    }
                }
            }
            return this;
        },
        _toggleElement: function ($element, show, type, callback) {
            var o=this.options,
                fn = show ? 'show' : 'hide',
                effect = B.obj.getValue([ type || 'element', fn ], o.effects);
            if(o.animate && effect){
                var effectOptions=$.extend(true, {
                        duration: o.animate
                    }, 
                    effect.options || {}, 
                    typeof callback === 'function' ? { complete: callback } : {} );
                if(effect.fn){
                    $element[effect.fn](effectOptions);
                }else{
                    $element[fn]($.extend(true, effectOptions, effect.effect ? { effect: effect.effect } : {}));
                }
            }
        },
        _toggleButton:function($btn, show){
            this._toggleElement($btn, show, 'btn');
        },
        _toggleActionBtn:function(action, show){
            this._toggleButton(this._actionButtons[action], show);
        },
        getDictionary: function(dictionaryName, options) {
            options = options || {};
            var dic = B.obj.getValue(['dictionaries', dictionaryName], this.options.entitySettings);
            return dic 
                ? B.dic.filter(dic, options.filters, this.options.ecn)
                : B.dic.get(dictionaryName, this.options.ecn, options);
        },
        getEntitySetting: function(name) {
            return B.obj.getValue(name, this.options.entitySettings);
        }
    };
})(jQuery, Bajt);
