var Export = {
    prototypes:{
        exporter:{
            o: {
            },
            config: {
                filename: '*',
                message: null,
                customize: null,
                download: 'download',
                exportOptions: {}
            },
            converters:{},
            generators:{},
            setOptions: function(options) {
                this.o = options;
                this.ecn = options.ecn || '';
                $.extend(
                    this.config,
                    {
                        newline: navigator.userAgent.match(/Windows/) ? '\r\n' : '\n'
                    },
                    this.defaultConfig || {},
                    this.o.config || {}
                );
                return this;
            },       
            getFilename: function(options) {
                var o = $.extend({                   
                        addExt: true,
                        prefix: '',
                        sufix: '',
                        generator: null
                    },
                    Export.filenameOptions[this.o.ecn] || {},
                    options || {} ),
                    c=this.config,
                    filename = c.filename;
                if(filename === '*' ){
                    if(B.isFunction(o.generator)){
                        filename = o.generator.call(this);
                    }else{
                        filename = c.title !== '*' && c.title !== undefined ? c.title : '';
                    }
                }
                filename = B.str.validFilename((o.prefix || '') + filename + (o.suffix || ''), 'export');
                return o.addExt ? filename + '.' + c.extension : filename;
            },
            setData:function(data){
                this.data = Export.fillEntity(data, this.o);
                return this;
            },
            createGenerator:function(name, expType, generator){
                var g=null;
                if(!B.obj.is(this.generators[name])){
                    this.generators[name] = {};
                }
                g=this.generators[name];
                if(expType){
                    if(!B.obj.is(g[expType])){
                        g[expType] = {};
                    }
                    g=g[expType];
                }
                $.extend(true, g, Export.prototypes.generator, generator );
                g.ecn = name;
                g.exporter = this;
                return g;
            },
            addGenerators:function(name, generator){
                this._addFunctions('generators', name, generator);
            },
            _addFunctions:function(type, name, extend ){               
                if(B.obj.is(extend)){
                    if(B.str.is(name)){
                        if(!B.obj.is(this[type][name])){
                            this[type][name] = {};
                        }
                        $.extend(true, this[type][name], extend);
                    }else{
                        $.extend(true, this[type], extend);
                    }
                }else{
                    this[type][name]=extend;
                }
            },
            getGenerator:function(){
                return B.obj.is(this.generators[this.o.ecn][this.config.exp]) ? this.generators[this.o.ecn][this.config.exp] : this.generators[this.o.ecn].generic; 
            },
            getGeneratorFunction:function(name, ecn, exportType){
                var gen = null;
                if(ecn){
                    gen = this.generators[ecn];
                    if(B.obj.is(gen)){
                        gen = gen[exportType || 'generic'][name] || null;
                    }else{
                        gen = null;
                    }
                }else{
                    gen = this.generators[name];
                }
                if (typeof gen === 'function'){
                    return gen;
                }
                console.log('Generator function not found ' + arguments.join(':'));
                return null;
            },
            getExportOptions:function(){
                return B.obj.getValue(this.config.exp, this.config.exportOptions, this.config.exportOptions);
            },
            addConverters:function(name, converter){
                this._addFunctions('converters', name, converter);
            },
            getConverter:function(name, ecn){
                if (this.converters.hasOwnProperty(ecn)){
                    if(this.converters[ecn].hasOwnProperty(name)){
                        return this.converters[ecn][name];
                    }
                    if(this.converters.hasOwnProperty(name)){
                        return this.converters[name];
                    }
                    if(this.converters[ecn].hasOwnProperty('generic')){
                        return this.converters[ecn].generic;
                    }
                }
                if(this.converters.hasOwnProperty(name)){
                    return this.converters[name];
                }
                return this.converters.generic;
            },
            convertField: function(value, name, options) {
                var o= $.extend({
                            ecn: this.ecn
                        },  
                        options || {}
                    ),
                    val,
                    converter = this.getConverter(name, o.ecn);
                if (Bajt.obj.is(value) && value.hasOwnProperty('Value')) {
                    o.diff = value.diff;
                    value = value.Value;
                }
                if (B.isFunction(converter)) {
                    val = converter.call(this, value, name, o);
                }
                return typeof val !== 'undefined' ? val : typeof value === 'string' ? value : 'convert_error';
            },
            convertFields: function(data, fields, options) {
                var  o= $.extend({
                            ecn: this.ecn,
                            convertField: this.convertField,
                            data: data
                        },  
                        options || {}
                    ),
                    result = [];
                if (typeof fields === 'string') {
                    fields = [fields];
                }
                for (var k in  fields) {
                    result.push(B.isFunction(o.convertField) 
                        ? o.convertField.call(this, data[fields[k]], fields[k], o)
                        : B.obj.getValue(fields[k], data, '')
                    );
                }
                return result;
            }
            // convertFields: function(data, fields, convert, convertOptions) {
            //     var result = [],
            //         caller = Bajt.obj.getValue('caller', convertOptions, this);
            //     if(typeof convert !== 'function'){
            //         convert = this.convertField;
            //     }
            //     if (Bajt.obj.is(convertOptions)) {
            //         convertOptions.data = data;
            //     } else {
            //         convertOptions = { data: data };
            //     }
            //     if (typeof fields === 'string') {
            //         fields = [fields];
            //     }
            //     for (var k in fields) {
            //         if (typeof convert === 'function') {
            //             result.push(convert.call(caller, data[fields[k]], fields[k], convertOptions));
            //         } else {
            //             result.push(B.obj.getValue(fields[k], data, ''));
            //         }
            //     }
            //     return result;
            // }
        },
        generator:{
            ecn: null,
            exporter : null,
            exportOptions: {},
            data: null,
            generate:function(data, exportOptions){
                this._init(data, exportOptions);
                return this.genContent();
            },
            _label: function(field){
                return Export._label(field, this.ecn);
            },
            _labels: function(fields){
                return Export._labels(fields, this.ecn);
            },
            _getValue: function(fieldName){
                return  Bajt.getFieldValue(this.data, fieldName, this.ecn);
            },
            _gen :function(fn, args, ecn, exportType){
                var gen = this.exporter.getGeneratorFunction(fn, ecn, exportType );
                if(ecn){
                    args[1]=$.extend({ecn: ecn}, args[1] || {});
                }
                return gen.apply(this, args);
            },
            genContent:function(){
                return null;
            },
            _init:function(data, exportOptions){
                this.data = data;
                this.exportOptions = exportOptions;
            }        
        },
        converter:{
            ecn: null,
            exporter : null,
            exportOptions: {},
            data: null,
            init:function(data, exportOptions){
                this.data = data;
                this.exportOptions = exportOptions;
            }
        }   
    },
    createExporter:function(name, exporter){
        if(!B.obj.is(this[name])){
            this[name]={};
        }
        return $.extend( true, this[name], this.prototypes.exporter, exporter);
    },
    labels: {
        lp: 'Lp.',
        summary: 'Łącznie',
        def: 'domyślne'
    },
    fields: {},
    getFields:function(ecn, type, subtype){
        var fields = this.fields[ecn];
        type = type || 'basic';
        if(subtype){
            fields = fields[subtype];
        }
        if(fields.hasOwnProperty(type)){
            return fields[type];
        }else{
            console.log('Not found export fields ecn = ' + ecn + ', type = ' + type + ', subtype = ' + subtype);
            return fields.basic;
        }
    },
    filenameOptions:{},
    /**
     * Safari's data: support for creating and downloading files is really poor, so
     * various options need to be disabled in it. See
     * https://bugs.webkit.org/show_bug.cgi?id=102914
     *
     * @return {Boolean} `true` if Safari
     */
    _isSafari: function() {
        return (
            navigator.userAgent.indexOf('Safari') !== -1 &&
            navigator.userAgent.indexOf('Chrome') === -1 &&
            navigator.userAgent.indexOf('Opera') === -1
        );
    },
    _saveAs: (function(view) {
        'use strict';
        // IE <10 is explicitly unsupported
        if (typeof navigator !== 'undefined' && /MSIE [1-9]\./.test(navigator.userAgent)) {
            return;
        }
        var doc = view.document,
            // only get URL when necessary in case Blob.js hasn't overridden it yet
            get_URL = function() {
                return view.URL || view.webkitURL || view;
            },
            save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            can_use_save_link = 'download' in save_link,
            click = function(node) {
                var event = new MouseEvent('click');
                node.dispatchEvent(event);
            },
            is_safari = /Version\/[\d\.]+.*Safari/.test(navigator.userAgent),
            webkit_req_fs = view.webkitRequestFileSystem,
            req_fs = view.requestFileSystem || webkit_req_fs || view.mozRequestFileSystem,
            throw_outside = function(ex) {
                (view.setImmediate || view.setTimeout)(function() {
                    throw ex;
                }, 0);
            },
            force_saveable_type = 'application/octet-stream',
            fs_min_size = 0,
            // the Blob API is fundamentally broken as there is no "downloadfinished" event to subscribe to
            arbitrary_revoke_timeout = 1000 * 40, // in ms
            revoke = function(file) {
                var revoker = function() {
                    if (typeof file === 'string') {
                        // file is an object URL
                        get_URL().revokeObjectURL(file);
                    } else {
                        // file is a File
                        file.remove();
                    }
                };
                /* // Take note W3C:
				var
				  uri = typeof file === "string" ? file : file.toURL()
				, revoker = function(evt) {
					// idealy DownloadFinishedEvent.data would be the URL requested
					if (evt.data === uri) {
						if (typeof file === "string") { // file is an object URL
							get_URL().revokeObjectURL(file);
						} else { // file is a File
							file.remove();
						}
					}
				}
				;
				view.addEventListener("downloadfinished", revoker);
				*/
                setTimeout(revoker, arbitrary_revoke_timeout);
            },
            dispatch = function(filesaver, event_types, event) {
                event_types = [].concat(event_types);
                var i = event_types.length;
                while (i--) {
                    var listener = filesaver['on' + event_types[i]];
                    if (typeof listener === 'function') {
                        try {
                            listener.call(filesaver, event || filesaver);
                        } catch (ex) {
                            throw_outside(ex);
                        }
                    }
                }
            },
            auto_bom = function(blob) {
                // prepend BOM for UTF-8 XML and text/* types (including HTML)
                if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
                    return new Blob(['\ufeff', blob], {
                        type: blob.type
                    });
                }
                return blob;
            },
            FileSaver = function(blob, name, no_auto_bom) {
                if (!no_auto_bom) {
                    blob = auto_bom(blob);
                }
                // First try a.download, then web filesystem, then object URLs
                var filesaver = this,
                    type = blob.type,
                    blob_changed = false,
                    object_url,
                    target_view,
                    dispatch_all = function() {
                        dispatch(filesaver, 'writestart progress write writeend'.split(' '));
                    },
                    // on any filesys errors revert to saving with object URLs
                    fs_error = function() {
                        if (target_view && is_safari && typeof FileReader !== 'undefined') {
                            // Safari doesn't allow downloading of blob urls
                            var reader = new FileReader();
                            reader.onloadend = function() {
                                var base64Data = reader.result;
                                target_view.location.href =
                                    'data:attachment/file' + base64Data.slice(base64Data.search(/[,;]/));
                                filesaver.readyState = filesaver.DONE;
                                dispatch_all();
                            };
                            reader.readAsDataURL(blob);
                            filesaver.readyState = filesaver.INIT;
                            return;
                        }
                        // don't create more object URLs than needed
                        if (blob_changed || !object_url) {
                            object_url = get_URL().createObjectURL(blob);
                        }
                        if (target_view) {
                            target_view.location.href = object_url;
                        } else {
                            var new_tab = view.open(object_url, '_blank');
                            if (new_tab === undefined && is_safari) {
                                //Apple do not allow window.open, see http://bit.ly/1kZffRI
                                view.location.href = object_url;
                            }
                        }
                        filesaver.readyState = filesaver.DONE;
                        dispatch_all();
                        revoke(object_url);
                    },
                    abortable = function(func) {
                        return function() {
                            if (filesaver.readyState !== filesaver.DONE) {
                                return func.apply(this, arguments);
                            }
                        };
                    },
                    create_if_not_found = {
                        create: true,
                        exclusive: false
                    },
                    slice;
                filesaver.readyState = filesaver.INIT;
                if (!name) {
                    name = 'download';
                }
                if (can_use_save_link) {
                    object_url = get_URL().createObjectURL(blob);
                    setTimeout(function() {
                        save_link.href = object_url;
                        save_link.download = name;
                        click(save_link);
                        dispatch_all();
                        revoke(object_url);
                        filesaver.readyState = filesaver.DONE;
                    });
                    return;
                }
                // Object and web filesystem URLs have a problem saving in Google Chrome when
                // viewed in a tab, so I force save with application/octet-stream
                // http://code.google.com/p/chromium/issues/detail?id=91158
                // Update: Google errantly closed 91158, I submitted it again:
                // https://code.google.com/p/chromium/issues/detail?id=389642
                if (view.chrome && type && type !== force_saveable_type) {
                    slice = blob.slice || blob.webkitSlice;
                    blob = slice.call(blob, 0, blob.size, force_saveable_type);
                    blob_changed = true;
                }
                // Since I can't be sure that the guessed media type will trigger a download
                // in WebKit, I append .download to the filename.
                // https://bugs.webkit.org/show_bug.cgi?id=65440
                if (webkit_req_fs && name !== 'download') {
                    name += '.download';
                }
                if (type === force_saveable_type || webkit_req_fs) {
                    target_view = view;
                }
                if (!req_fs) {
                    fs_error();
                    return;
                }
                fs_min_size += blob.size;
                req_fs(
                    view.TEMPORARY,
                    fs_min_size,
                    abortable(function(fs) {
                        fs.root.getDirectory(
                            'saved',
                            create_if_not_found,
                            abortable(function(dir) {
                                var save = function() {
                                    dir.getFile(
                                        name,
                                        create_if_not_found,
                                        abortable(function(file) {
                                            file.createWriter(
                                                abortable(function(writer) {
                                                    writer.onwriteend = function(event) {
                                                        target_view.location.href = file.toURL();
                                                        filesaver.readyState = filesaver.DONE;
                                                        dispatch(filesaver, 'writeend', event);
                                                        revoke(file);
                                                    };
                                                    writer.onerror = function() {
                                                        var error = writer.error;
                                                        if (error.code !== error.ABORT_ERR) {
                                                            fs_error();
                                                        }
                                                    };
                                                    'writestart progress write abort'
                                                        .split(' ')
                                                        .forEach(function(event) {
                                                            writer['on' + event] = filesaver['on' + event];
                                                        });
                                                    writer.write(blob);
                                                    filesaver.abort = function() {
                                                        writer.abort();
                                                        filesaver.readyState = filesaver.DONE;
                                                    };
                                                    filesaver.readyState = filesaver.WRITING;
                                                }),
                                                fs_error
                                            );
                                        }),
                                        fs_error
                                    );
                                };
                                dir.getFile(
                                    name,
                                    {
                                        create: false
                                    },
                                    abortable(function(file) {
                                        // delete file if it already exists
                                        file.remove();
                                        save();
                                    }),
                                    abortable(function(ex) {
                                        if (ex.code === ex.NOT_FOUND_ERR) {
                                            save();
                                        } else {
                                            fs_error();
                                        }
                                    })
                                );
                            }),
                            fs_error
                        );
                    }),
                    fs_error
                );
            },
            FS_proto = FileSaver.prototype,
            saveAs = function(blob, name, no_auto_bom) {
                return new FileSaver(blob, name, no_auto_bom);
            };
        // IE 10+ (native saveAs)
        if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
            return function(blob, name, no_auto_bom) {
                if (!no_auto_bom) {
                    blob = auto_bom(blob);
                }
                return navigator.msSaveOrOpenBlob(blob, name || 'download');
            };
        }

        FS_proto.abort = function() {
            var filesaver = this;
            filesaver.readyState = filesaver.DONE;
            dispatch(filesaver, 'abort');
        };
        FS_proto.readyState = FS_proto.INIT = 0;
        FS_proto.WRITING = 1;
        FS_proto.DONE = 2;

        FS_proto.error = FS_proto.onwritestart = FS_proto.onprogress = FS_proto.onwrite = FS_proto.onabort = FS_proto.onerror = FS_proto.onwriteend = null;

        return saveAs;
    })( (typeof window !== 'undefined' && window) || this.content),
    _title: function(config) {
        var title = config.title;
        if (typeof title === 'function') {
            title = title();
        }
        return title.indexOf('*') !== -1 ? title.replace('*', $('title').text() || 'Exported data') : title;
    },
    _labels: function(fields, entityClassName) {
        var labels = [];
        if (typeof fields === 'string'){
            fields = this.fields[entityClassName][fields];
        }
        if(Array.isArray(fields)){
            for (var i = 0, ien = fields.length; i < ien; i++) {
                var label = this._label(fields[i], entityClassName);
                if(!label){
                    var names = fields[i].split('.');
                    if (names.length > 1) {
                        //                    var en=validateEntityName(name[0]);
                        label =this._label(name[1], name[0]);
                    }   
                }
                labels.push(label || '');
            }   
        }
        return labels;
    },
    _label: function(name, entityClassName) {
        return Bajt.obj.getValue([entityClassName, name], this.labels) || Bajt.obj.getValue(name, this.labels, name);
    },
    _dataToArray:function(data, fields, entityClassName, defValues, options){
        var dataArray = [],
            entityFields = Bajt.fieldsNames(entityClassName);
        options = $.extend({empty: '', key: 'name'}, options || {});
        for(var f in fields){
            var val = Bajt.getFieldValue(data, fields[f], entityFields, options.key);
            if(undefined === val){
                val = Bajt.obj.getValue(fields[f], defValues, options.empty);
            }
            dataArray.push(val);
        }
        return dataArray;
    },
    fillEntityFunctions: {},
    fillEntity:function(data, options){
        return B.isFunction(this.fillEntityFunctions[options.ecn]) 
            ? this.fillEntityFunctions[options.ecn](data, options) 
            : Bajt.entity.fill(data, options.ecn, options.fill);
    },
    getExportEntityConfig:function(expData, options){
        var config={};
        switch (options.ecn) {
            case 'Orders':
                config.filename = 'zam_' + expData.number;
                break;
            case 'Packages':
                config.filename = 'paleta_' + expData.number;
                break;
            case 'Productions':
                config.filename = 'produkcja_' + expData.number;
                break;
            default:
                config.filename = 'pdf_' + expData.id;
        }
        return config;
    }
};
