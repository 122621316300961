
(function($, B) {
        
    $.fn.loadDataPreview = function( options, successCallback, errorCallback ){
        var $preview = $(this),
            o=$preview.data(),
            $loader = $preview.find('.preview-loader'),
            $empty = $preview.find('.preview-empty'),
            _showMessages=function(msg){
                $preview.find('.messages').showMessages(msg);
            },
            _showErrors=function(msg){
                $preview.find('.errors').showMessages(typeof msg === 'string' ? { type: 'e', message: msg } : msg);
            },
            _afterResponse=function(){
                _showMessages();
                $loader.slideUp('fast', function(){ 
                    $loader.empty(); 
                });
            };
        _showErrors();
        $preview.find('.preview-content').slideUp('fast', function(){
            if(o.tmpl){
                $(this).empty();
            }
        });
        $empty.slideUp('fast');
        $loader.empty().append(B.html.get$('loader')).slideDown('fast');
        _showMessages({ message: 'pobieranie danych z serwera ...' });
        $.ajax(options.ajax)
            .done(function(data) {
                var entityData;
                _afterResponse();
                if(B.obj.is(data)){
                    if(data.errors){
                        _showErrors(data.errors);
                    }else if(data.messages){
                        _showErrors(data.errors);               
                    }else{
                        if(B.obj.isNotEmpty(data.entityData)){
                            entityData = typeof options.convertFunction === 'function'
                                ? options.convertFunction(data.entityData)
                                : data.entityData;
                        }
                    }  
                }           
                $preview.setDataPreview(entityData);
                if( B.obj.isNotEmpty(entityData)){
                    if(B.isFunction(successCallback)){
                        successCallback(entityData, data);
                    }
                }else if(B.isFunction(errorCallback)){
                    errorCallback('Error data');
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                _afterResponse();
                if (typeof jqXHR.responseJSON !== 'undefined') {
                    _showErrors(jqXHR.responseJSON.errors);
                } else if (jqXHR.responseText) {
                    _showErrors(jqXHR.responseText);
                } else {
                    _showErrors(errorThrown);
                }
                if( B.isFunction(errorCallback)){
                    errorCallback(jqXHR, textStatus, errorThrown);
                }
            });
    };

    $.fn.showPreview = function() {
        var $preview = $(this),
            $content = $preview.find('.preview-content'),
            $empty = $preview.find('.preview-empty'),
            o=$preview.data(),
            entity = B.obj.isNotEmpty(o.entity) ? B.entity.fill(o.entity, o.ecn, o.fillOptions || {} ) : null,
            _showContent=function(){
                $empty.slideUp('fast');
                if(o.tmpl){
                    $content.append($(o.tmpl).fill(entity, o.ecn));
                }else{
                    $content.fill(entity, o.ecn);
                }
                // if(!$content.is(':visible')){
                    $content.slideDown('fast');
                // }
            },
            _showEmpty=function(){
                $content.slideUp('fast', function(){
                    if(o.tmpl){
                        $content.empty();
                    } 
                });
                if(!$empty.is(':visible')){
                    $empty.slideDown('fast');
                }
            };
            console.log('show preview');
        if(B.obj.isNotEmpty(entity)){
            _showContent();
        }else{
            _showEmpty();
        }  
    };

    
    $.fn.setDataPreview=function(entityData){
        $(this).data('entity', B.obj.is(entityData) ? entityData : null).trigger('data-change');
    };

    $.fn.initPreview = function() {
        var $preview = $(this).find('[data-preview]');
        if (B.obj.is$($preview)){
            $preview.showPreview();
        }
    };

    $('body').on('data-change', '[data-preview]', function(e){
        $(this).showPreview();
    });


})(jQuery, Bajt);