(function($, B) {

    $.fn.getFieldName = function() {
        var match,
            $this = $(this),
            name = $this.data('name');
        if (name === undefined) {
            match = /\[[^\].]*]$/.exec($this.attr('name'));
            if (match) {
                name = match[0].slice(1, match[0].length - 1);
            }
        }
        return name === undefined ? '' : name; //.toLowerCase();
    };

    $.fn.getDataType = function() {
        var o = this.data('type');
        if (o !== undefined) {
            return o.toLowerCase();
        }
        o = $.extend(this.data('options') || {}, this.data('o') ||{} );
        return B.str.is(o.type) ? o.type.toLowerCase() : '';
    };

    $.fn.getFieldType = function() {
        var type = this.data('field-type') || this.prop('type') || this.prop('tagName');
        return type === undefined ? '' : type.toLowerCase();
    };

    $.fn.getFieldNavi = function() {
        var type = this.data('navi');
        return type === undefined ? 0 : type;
    };

    $.fn.getFieldControl = function() {
        var control = $(this).data('control');
        return control === undefined ? '' : control !== '1' ? control : 'ctrl';
    };

    $.fn.getField=function(){
        var $this=$(this),
            field = {
                $field : $this,
                fieldType : $this.data('field-type'),
                fn : null
            };
        if(!field.fieldType){
            if(!$this.is(':input')){
                field.$field = $this.find('input');
            }
            field.fieldType = field.$field.getFieldType();
        }
        field.fn = B.str.camelCase(field.fieldType);       
        return field;
    };

    $.fn.readField = function(options) {
        var $this=$(this),
            field = $this.getField(),
            // // dataType = this.getDataType(),
            // fieldType = $field.getFieldType(),
            // fn= B.str.camelCase(fieldType),
            value = '',
            _read = {
                def: function(){
                    value=field.$field.val();
                },
                checkbox:function(){
                    if (field.$field.length > 1) {
                        value = [];
                        field.$field.filter(':checked').each(function() {
                            value.push($(this).val());
                        });
                    } else {
                        value = field.$field.is(':checked') ? field.$field.val() || 1 : 0;
                    }                   
                },
                radio:function(){
                    value = field.$field.filter(':checked').val();
                },
                selectOne:function(){
                    value = field.$field.find('option:selected').val();   
                },
                dateRange:function(){
                    value= field.$field.val();                   
                    if(value && B.obj.getValue('customRead', options)){
                        var w = field.$field.data('daterangepicker');

                        for (var range in w.ranges) {
                            if (w.timePicker) {
                                if (w.startDate.isSame(w.ranges[range][0]) && w.endDate.isSame(w.ranges[range][1])) {
                                    value = range;
                                    break;
                                }
                            } else {
                                if (w.startDate.format('YYYY-MM-DD') == w.ranges[range][0].format('YYYY-MM-DD') && w.endDate.format('YYYY-MM-DD') == w.ranges[range][1].format('YYYY-MM-DD')) {
                                    value = range;
                                    break;
                                }
                            }
                        }                            
                    }
                },
                inputCollection: function(){
                    value = [];
                    field.$field.find(field.$field.data('inputs') || 'input').each(function() {
                        value.push($(this).readField());
                    });   
                }
            };    


        if(B.isFunction(_read[field.fn])){
            _read[field.fn]();
        }else{
            _read.def();
        }
        return value !== undefined ? value : null;
    };

    $.fn.exportField = function(exportType){
        var $this=$(this);
        return B.convert($this.readField(), { 
            name: exportType || $this.getDataType(),
            options: $this.data()
        });
    };

    $.fn.writeField = function(value, change) {
        var $this=$(this),
            field = $this.getField(),
            // fieldType = this.getFieldType(),
            // fn= B.str.camelCase(fieldType),
            // dataType = this.getDataType(),
            nVal = B.getValue(value, ['v', 'id']),
            _write = {
                def: function(){
                    if(B.obj.is(value)){
                        field.$field.data('n', value.n || null);
                        field.$field.data('d', value.n || null);
                        value=JSON.stringify(value);
                    }
                    field.$field.val(value);
                },
                dateRange:function(){
                    var w = field.$field.data('daterangepicker');
                    if(B.str.is(value) && value.indexOf(' - ') > 0){
                        value = value.split(' - ');
                    }
                    if(B.obj.isIterate(value)){
                        w.setStartDate(value[0]);
                        w.setEndDate(value[1]);
                        w.clickApply();
                    }else if(w.ranges.hasOwnProperty(value)){
                        w.setStartDate(w.ranges[value][0]);
                        w.setEndDate(w.ranges[value][1]);
                        w.clickApply();
                    }

                },
                checkbox:function(){
                    if (field.$field.length > 1) {
                        field.$field.filter(':checked').prop('checked', false);
                        var selected = [];
                        for( var i in nVal){
                            selected.push('[value=' + nVal[i] + ']');
                        }
                        field.$field.filter(selected.join(',')).prop('checked', true);
                    } else {
                        field.$field.prop('checked', nVal);
                    }
                },
                radio:function(){
                    field.$field.filter(':checked').prop('checked', false);
                    if (nVal !== null && nVal !== '') {
                        field.$field.filter('[value=' + nVal + ']').prop('checked', true);
                    }
                },
                selectMultiple:function(){
                    field.$field.val(value);
                },
                // selectOne:function(){
                // },
                inputCollection: function(){
                    var prototype = field.$field.data('prototype'),
                        prototypeName = field.$field.data('prototype-name') || '__name__',
                        inputSelector=field.$field.data('inputs') || 'input[data-input]';
                    if(Array.isArray(value) && prototype){
                        field.$field.empty();
                        for(var i in value){
                            var $row=B.html.genFromPrototype(prototype, prototypeName, i),
                                fieldName=$row.data('field-name'),
                                d = B.json.is(value[i]) ? JSON.parse(value[i]) : value[i];
                            if(fieldName){
                                var n={};
                                n[fieldName]=d;
                                d=n;
                            }
                            $row.find(inputSelector).writeField(value[i]);
                            $row.fill(d);
                            field.$field.append($row);
                        }
                    }
                }
            };
            // _convert = {
            //     def: function(){
            //     },
            //     astring:function(){ //!Uwaga
            //         value =  Array.isArray(value) && value.length > 0 ? value.join() : '';
            //     },
            //     json:function(){
            //         if(B.json.is(value)){
            //             value = JSON.parse(value);                                            
            //         }else if(!B.obj.is(value)){  
            //             value={
            //                 v : value,
            //                 n : null,
            //                 d : null
            //             };
            //         }
            //     },
            //     ajson:function(){
            //         value = B.obj.toArray(value);
            //     },
            //     cjson:function(){
            //         value = B.obj.toArray(value);
            //     },
            //     float:function(){
            //         value = B.str.toFloat(dataType === 'json' ? B.json.getValue(value) : value);
            //     },
            //     number:function(){
            //         value = parseInt(dataType === 'json' ? B.json.getValue(value) : value, 10);
            //     }
            // };      
        
        // if(typeof _convert[dataType] === 'function'){
        //     _convert[dataType]();
        // }else{
        //     _convert.def();
        // }
        if(typeof _write[field.fn] === 'function'){
            _write[field.fn]();
        }else{
            _write.def();
        }
    
        // if (change) {
        //     this.change();
        // }
        return this;
    };

    // $.fn.changeChildsIdx=function(name, idx){
    //     var $this=$(this),
    //         prefix=name+'_';
    //     $this.find('[id*='+prefix+']').each(function(){
    //         this.changeIdx(name, idx);
    //     });
    //     $this.find('label[for*='+prefix+']').each(function(){
    //         var $label=$(this);
    //         $label.attr('for', $label.attr('for').replace(new RegExp(prefix + '[\\d]*', 'g'), prefix+idx));
    //     });
    // };

    // $.fn.changeAttr =function(options){
    //     var i, 
    //         _changeAttr=function(k, element){
    //             $(element).replaceAttr(options[i].attr, options[i].replace);
    //         };
    //     for(i in options){
    //         _changeAttr(0, this);
    //         this.find(options[i].selector).each(_changeAttr);
    //     }
    //     return this;
    // };

    $.fn.changeIdx =function(name, idx, saveIdx){
        var 
            oidx=this.getIdx(name);           
        if(idx != oidx){           
            var i,
                oid=name+'_'+oidx,
                nid=name+'_'+idx,
                on='['+name+']['+oidx+']',
                nn='['+name+']['+idx+']',
                change = {
                    id: { attr: 'id', selector: '[id*='+oid+']', replace: [[ oid, nid ]] },
                    name: { attr: 'name', selector: '[name*=\\['+name+'\\]\\['+oidx+'\\]]', replace: [[ on, nn ]] },
                    prototype: { attr: 'data-prototype', selector: '[data-prototype]', replace: [[ oid, nid ], [ on, nn ]] },
                    label: { attr: 'for', selector: 'label[for*='+oid+']', replace: [[oid, nid]] }
                },
                _changeAttr=function(k, element){
                    $(element).replaceAttr(change[i].attr, change[i].replace);
                };
            for(i in change){
                _changeAttr(0, this);
                this.find(change[i].selector).each(_changeAttr);
            }
            if(saveIdx){
                this.attr('data-idx', idx);
            }
        }
        // this.changeChildsIdx(name, idx);
        return this;
    };
   
    $.fn.getIdx=function(prefix){
        var idx=this.attr('data-idx');
        if(idx === undefined){
            idx= B.html.getNrFromId(this.attr('id'), prefix);
        }
        return idx;
    };
    
    B.basicField = $.extend( true, {}, B.basicWidget, {
        options: {
            selectors: {
                container: 'div',
            },
            parent: null,
            name: null,
            ecn: null,
            type: null,
            navi: 0,
            calc: 0,
            formName: null,
            signalClass: ['signal-0', 'signal-1', 'signal-2'],
            control: {
                type: 0,
                disp: 0,
                modal: null,
                modalWidget: 'modalField',
                signal: 0
            },
            disp: {
                type: 0,
                visible: 1, //1-always, 2-switch
                empty: ''
            },
            show:{
                type: 0,
                label: '',
                signal: 0,
                def: ''
            },
            templates: {
                show: '<span class="show-value">__v__</span>',
                dispWrapper: '<div/>',
                disp: '__v__',
                btnDetails:
                    '<button type="button" class="ml-2 btn btn-sm btn-default" style="display:none;"><i class="material-icons">details</i></button>',
                ctrl: '<button class="btn" type="button"></button>',
                message: '<div style="display:none;"></div>'
            },
            details: false,
            widget: null,
            label: '',
            precision: 2,
            // setField: null,
            fieldDefault: null,
            format: 'YYYY-MM-DD HH:mm',
            compare: null,
            limits: null,
            readonly: false,
            bind: {
                change: '_eChange',
                focus: '_eFocus',
                blur: '_eBlur'
            },
            // Uwaga może coś nie działać 04-03-2020
            // containerBind: {
            //     change: '_eContainerChange'
            // }
        },
        _createBasicControls: function() {               
            B.basicWidget._createBasicControls.call(this);                
            this.$form=this.element.closest('form');
            return this;
        },
        _createBasicOptions: function() {               
            B.basicWidget._createBasicOptions.call(this);                
            var o = this.options;
            if (!o.name) {
                o.name = this.element.getFieldName();
            }
            if (!o.type) {
                o.type = this.element.getDataType();
            }
            if (this.element.attr('readonly')) {
                o.readonly = true;
            }
            // o.setField = o.setField == 1 ? o.name : o.setField;
            if (!o.label) { 
                var $label = this.$container.find('label');
                if (B.obj.is$($label)) {
                    o.label = $label.html();
                }
            }
            if (o.dictionary && !B.obj.is(o.dictionary)) {
                o.dictionary = this.getDictionary(typeof o.dictionary === 'string' ? o.dictionary : o.name, o.dictionaryOptions);
            }
            if (o.check_key && typeof keyPress[o.type] === 'function') {
                o.bind.keypress=keyPress[o.type];
            }
            if (o.type === 'number') {
                o.bind.keydown= keyPress.number_inc;
            }
            o.required = o.required || (this.element.attr('required') === 'required');
            return this;
        },
        _build:function(){
            var o = this.options;
            B.basicWidget._build.call(this);
            this._buildMessage();                  
            return this;
        },
        _buildMessage:function(){
            // if (B.obj.is(this.options.limits)) {
            //         this.element.popover({
            //         trigger: 'focus',
            //         html: true
            //     }).popover('disable');
            // }            
            return this;
        },
        _createData:function() {
            B.basicWidget._createData.call(this);
            var o = this.options;
            $.extend(true, this._data, { 
                value: null,
                valueStr: null,
                isDefault: false,
                show: null,
                details: null,
                status: 0 //-2: error, -1: warning, 0: not change, 1:ok    
            });
            this.message='';
            if (o.details) {
                if (B.obj.is(o.parent)) {
                    this._data.details = B.obj.getValue(o.name, o.parent.getEntityData());
                }
                if (!B.obj.is(this._data.details)) {
                    this._data.details = this.element.data('show-data') || null;
                }
            }
            this.valid = new Valid();
            return this;
        },
        _createControls:function(){
            B.basicWidget._createControls.call(this);
            this._createElements(['control', 'btnDetails', 'modal', 'shows', 'disp']);
            return this;
        },
        _createControl:function(){
            var o = this.options,
                $ctrl = this._findRelatedElement('ctrl');
            if (o.control.type && !B.obj.is$($ctrl)) {
                $ctrl = $(o.templates.ctrl).attr('title', this.element.attr('title'));
                this._setAttrRelatedElement($ctrl, 'ctrl');    
                if (o.control.disp){
                    this._setAttrRelatedElement($ctrl, o.control.disp);    
                }
                $ctrl.appendTo(this.$container);
            }
            if(o.control.navi){
                $ctrl.attr('data-navi', '1');
            }
            return $ctrl;
        },
        _createBtnDetails:function(){
            var o = this.options,
                $btn;
            if (o.details) {
                console.log(o.name + ' _buildDetails');
                $btn = $(o.templates.btnDetails).insertAfter(this.$container.find('label'));
                if (B.obj.is(this._data.details)) {
                    $btn.popover({
                        content: $(o.templates.details).fill(this._data.details),
                        // trigger: 'focus',
                        html: true
                    });
                    $btn.show();
                }
            }
            return $btn;
        },        // _createControl:function(){
        //     var co = this.options.control;
        //     var $control = this._findRelatedElement('ctrl');
        //     if(this.options.control.navi){
        //         $control.attr('data-navi', '1');
        //     }
        //     return $control;
        // },
        _createModal:function(){
            var o = this.options;
            return o.control.modal ? $( '#' + (String(o.control.modal) === '1' ? o.name : o.control.modal) + '_modal') : null;
        },
        _createShows:function(){
            return this._findRelatedElement('show');
        },
        _createDisp:function(){               
            var o = this.options,
                $disp = this._findRelatedElement('disp');
            if (o.disp.type && !B.obj.is$($disp)) {
                $disp = $(o.templates.dispWrapper).addClass('form-control').attr('title', this.element.attr('title'));
                this._setAttrRelatedElement($disp, 'disp'); 
                if(o.disp.addClass){
                    $disp.addClass(o.disp.addClass);
                }   
                $disp.appendTo(this.$container);
            }
            if (o.disp.hidden){
                this.$disp.hide();
            }
            return $disp;
        },
        _findRelatedElement:function(type){
            return this.options.parent.element.find( '[data-field-' + type + '=' + this.element.attr('id') + ']');
        },
        _setAttrRelatedElement:function($element, type){
            $element.attr('data-field-' + type, this.element.attr('id'));
        },

        // _createDisp:function(){               
        //     var o=this.options,
        //         dispId = this.element.attr('id').replace(o.formName, o.disp.prefix);
        //     this.$disp = o.parent.element.find('#' + dispId);
        //     if (o.disp.type && !B.obj.is$(this.$disp)) {
        //         this.$disp = $(o.templates.disp)
        //             .addClass('form-control')
        //             .attr('id', dispId)
        //             .appendTo(this.$container);
        //     }
        //     if (o.disp.hidden){
        //         this.$disp.hide();
        //     }
        //     return this;
        // },
        _initialize:function(){              
            B.basicWidget._initialize.call(this);
            this._initFormWidget();
            if(this.options.name=='uploads'){
                var pp=1;
                pp++;
            }
            this._read();
            this.readonly();
            if (this.options.type === 'cjson') {
                this._saveID(); //zapis starych id (minimalizacja rekordów w bazie)
            }                           
            if (this.options.initDefault || (this.isEmpty() && this.options.required)) {
                this.setDefault(true);
            } else {
                this._checkDefault(true);
            }
            this.disp();
            return this;
        }, 
        _read: function() {
            return this._setValue(this._transform(this.element.readField()));
        },
        _setValue: function(value, set) {
            var d = this._data,
                o=this.options,
                val = this._constrain(value);
            d.status=0;
            if (val !== undefined){                  
                if (set || !this._isEqualValue(val)){
                    d.value = val;
                    d.status = 1;
                    this._toStr();
                    this._setDefaultFieldValueIfEmpty();            
                    if (o.type === 'cjson' && Array.isArray(d.oldID)) {
                        $.each(d.value, function(idx, val) {
                            if (d.oldID[idx] !== undefined) {
                                val.id = d.oldID[idx];
                            } else {
                                return false;
                            }
                        });
                    }
                }
            } else {                    //dopisać obsługę messages
                console.log({
                    name: o.name,
                    fn: '_setValue',
                    value: value,
                    val: val,
                    options: o
                } );
                this._removeMessage();
                d.status = -1;
            }
            return d.status;
        },
        _refresh:function(){
            this.validate();
            this._checkDefault(true);
            this.disp();
        },
        _saveID: function() {
            var d = this._data;
            d['oldID'] = [];
            if (Array.isArray(d.value)) {
                $.each(d.value, function(idx, val) {
                    d.oldID.push(val.id);
                });
            }
        },
        getName:function(){
            return this.options.name;
        },
        isEmpty: function() {
            var value = this._data.value,
                isVal = isSet(value);
            switch (this.options.type) {
                case 'json':
                    isVal = isVal && (value.v || value.id);
                    break;
                case 'astring':
                case 'ajson':
                case 'cjson':
                    isVal = isVal && Array.isArray(value) && value.length > 0;
                    break;
            }
            return !isVal;
        },
        _initFormWidget:function(){
            var that=this,
                o=this.options,
                _initWidget=function(w){
                    return B.obj.is(w) ?
                        that.element.initFormWidget($.extend(true, 
                            w.options || {}, 
                            {
                                widget: w.type,
                                dictionary: o.dictionary,
                                fieldName: o.name,
                                field: that,
                                parent: o.parent,
                                formName: o.formName
                            }
                        ))
                        : null;
                };
            if (Array.isArray(o.widget)){
                this.formWidget=[];
                for(var i in o.widget){
                    this.formWidget.push(_initWidget(o.widget[i]));
                } 
            }else{
                this.formWidget=_initWidget(o.widget);
            }
        },
        _bind: function() {
            B.basicWidget._bind.call(this);
            var o = this.options;
            // this._on( this.$form, { submited : this._eFormSubmited });
            if (this.$control){
                this._bindElement(this.$control, { 
                    click: '_eControlClick',
                    // touchend: '_eControlClick' 
                });
                if(this.$control.data('navi')){
                    this.$control.on('keydown', keyPress.navi);
                }
            }
            if (o.fieldDefault){
                this._bindElement(o.fieldDefault.element, { changed: '_eDefaultChange' });
            }
            if (o.navi) {
                var $el = o.navi === 'control' ? this.$control : this.element;
                if (B.obj.is$($el)) {
                    $el.on('keydown', keyPress.navi);
                    this._bindElement($el, { keyNavigate: '_eKeyNavigate'} );
                }
            }
            return this;
        },
        _eChange: function(e, data) {
            if(this._isOwnEvent(data) ){
                return;
            }
            if(this._read()){
                if (this.options.autocorrect) {
                    this._write();
                }
                this._refresh();
                this._changed();
            }
        },
        _eFocus:function(e){
            this._toggleMessage(true);
            if (this.options.selectOnFocus){
                this.element[0].select();
            }
        },
        _eBlur:function(e){
            this._toggleMessage(false);
        },
        _changed:function(){
            if(this.options.calc){
                this._trigger('calc');   
            }
            this._trigger('changed');
        },
        _setDefaultFieldValueIfEmpty: function(){
            var fd=this.options.fieldDefault;
            if (B.obj.is(fd) && fd.isEmpty() && !this.isEmpty()) {
                this._data.isDefault = true;
                this._defaultSetter = true;
                fd.value(this.exportValue(fd.options.type));
            }
            return this;
        },                 
        _eControlClick: function(e, data) {
            stopTrigger(e);//??
            switch(this.options.control.type){
                case 'switch':
                    this.value(!this.value());
                break;
                default:
                    if (B.obj.is$(this.$modal)){
                        this.$modal[this.options.control.modalWidget]('show', this, this.$control);
                    }
            }
        },
        _eDefaultChange:function(e, data){
            if(!this._defaultSetter){
                this.setDefault();
            }else{
                this._defaultSetter=false;
            }
        },
        _eKeyNavigate: function(e, data) {
            data.field = this;
        },
        _eContainerChange:function(e, data){
            stopTrigger(e);
        },
        _eDblClick: function(e, data) {
            var find = B.dic.next(this.options.dictionary, this.getValue());
            this.value(find.v);
        },
        // _eFormSubmited:function(e, data){
        //     if(typeof data === object && typeof data.fields === object && data.fields.hasOwnProperty(this.options.name)){
        //         console.log( { 
        //             fn: '_eFormSubmited',
        //             value: data.fields[this.options.name]

        //         });
        //     }
        // },

        _trigger: function(name, $element, data){
            B.basicWidget._trigger.call(this, name, $element, $.extend(data || {}, { field: this }));
        },
        getValue: function() {
            return this.options.type === 'json' ? B.obj.getValueOneOfKeys([ 'v', 'id'], this._data.value ) : this._data.value;
        },  
        setDefault: function(set) {
            if (B.obj.is(this.options.fieldDefault)) {
                this._data.isDefault = set ? set : this._data.isDefault;
                if(this._data.isDefault){
                    this.value(this.options.fieldDefault.exportValue(this.options.type));
                }else{
                    this._refresh();
                }
                
            }
            return this;
        },

        value: function(value) {
            if (value !== undefined && this._setValue(value)) {
                this._write();
                this._refresh();
                this._trigger('change');
                this._changed();
            }
            return this._data.value;
        },
        setValue:function(value){
            if (this._setValue(value, true)) {
                this._write();
                this._refresh();
                this._trigger('change');
            }
            return this._data.status;
        },
        _constrain: function(value) {
            var o=this.options,
                _constrain={
                float: function(v){
                    var out=B.str.toFloat(v, o.precision, o.round);
                    out = isNaN(out) ? null : out;
                    return out;
                },
                number:function(v){
                    var out = parseInt(v, 10);
                    out = isNaN(out) ? null : out;
                    return out;
                },
                astring: function(v){
                    return Array.isArray(v) ? v : [];
                },
                bool:function(v){
                    return v ? true : false;
                },
                json:function(v){
                    return B.obj.is(v)
                        ? v 
                        : B.json.isObj() 
                            ? JSON.parse(v)
                            : { v: v, n: null, d: null };
                },
                array:function(v){
                    return Array.isArray(v) ? v : [];
                },
                ajson:function(v){
                    return _constrain.array(v);
                },
                cjson:function(v){
                    return _constrain.array(v);
                },
                def:function(v){
                    return v !== undefined ? v : null;
                },
                date:function(v){
                    return v ? new Date(v) : null;
                }
            };
            value=B.obj.clone(value);
            if(B.isFunction(_constrain[o.type])){
                return _constrain[o.type](value);
            }
            return _constrain.def(value);
        },
        _toStr: function() {
            var o=this.options,
                _convert={
                    bool:function(v){
                        return v ? '1' : '0';
                    },
                    date: function(v){
                        return B.str.date(v, o);
                    },
                    number: function(v){
                        return $.isNumeric(v) ? v.toString() : '';
                    },
                    float: function(v){
                        return $.isNumeric(v) ? B.str.fixed(v, o) : '';
                    },
                    json: function(v){
                        var out=B.obj.getValueOneOfKeys(['v', 'id'], v);
                        return out !== null ? _convert.def(out) : '';
                    },
                    astring: function(v){
                        return B.obj.isIterate(v) ? v.join(' ') : '';
                    },
                    ajson: function(v){
                        return B.obj.isIterate(v) ? JSON.stringify(v) : '[]';
                    },
                    cjson: function(v){
                        return _convert.ajson(v);
                    },
                    def:function(v){
                        return v ? (B.obj.is(v) ? JSON.stringify(v) : String(v)) : '';
                    }
                };
            this._data.valueStr = B.isFunction(_convert[o.type]) 
                ? _convert[o.type](this._data.value) 
                : _convert.def(this._data.value);
            return this._data.valueStr;
        },
        update: function(value){
            this.setValue(value);
        },            
        _update:function(){
            this._write();
        },
        // _transform:function(value){
        //     var o=this.options,
        //         _convert={
        //             bool:function(v){
        //                 return v == '1' ? true : false;
        //             },
        //             number:function(v){
        //                 var out=parseInt(v, 10);
        //                 return isNaN(out) ? null : out;
        //             },
        //             float:function(v){
        //                 var out= B.str.toFloat(v, o.precision, o.round);
        //                 return isNaN(out) ? null : out;
        //             },
        //             astring:function(v){
        //                 return B.astring.toArray(v);
        //             },
        //             json: function(v){
        //                 return B.json.isObj(v) ? JSON.parse(v) : { v: null };
        //             },
        //             ajson: function(v){
        //                 var out = [];
        //                 if(Array.isArray(v)){
        //                     for(var i=0; i < v.length; i++){
        //                         out.push(_convert.json(v[i]));
        //                     }
        //                 }
        //                 return out;
        //             },
        //             cjson:function(v){
        //                 var out = B.json.isArray(v) ? JSON.parse(v) : [];
        //                 if(Array.isArray(out)){
        //                     for(var i=0; i<out.length; i++){
        //                         if(!B.obj.is(out[i])){
        //                             out[i] = { v: null };
        //                         }
        //                     }
        //                 }
        //                 return out;
        //             },

        //         };
        //     if(B.isFunction(_convert[o.type])){
        //         return _convert[o.type](value);
        //     }
        //     return value;
        // },
        _transform:function(value){
            return B.convert(value, { 
                name: this.options.type, 
                options: this.options
            });
        },
        _reverseTransform:function(value){
            var o=this.options,
                _convert={
                    bool:function(v){
                        return v ? 1 : 0;
                    },
                    number:function(v){
                        return Number.isInteger(v) ? v.toString() : '';
                    },
                    date:function(v){
                        return B.str.date(v, o);
                    },
                    float:function(v){
                        return B.str.fixed(v, o);
                    },
                    astring:function(v){
                        return v.join(' ');
                    },
                    json: function(v){
                        return B.json.getValue(v) !== null ? JSON.stringify(v) : '';
                    },
                    ajson: function(v){
                        var out = [];                           
                        for(var i=0; i < v.length; i++){
                            out.push(_convert.json(v[i]));
                        }
                        return out;
                    },
                    cjson:function(v){                           
                        return v.length > 0 ? JSON.stringify(v) : ''; 
                    },

                };
            if(B.isFunction(_convert[o.type])){
                return _convert[o.type](value);
            }
            return value;
        },
        _write: function() {
            this.element.writeField(this._reverseTransform(this._data.value));
        },
        getDefaultValue: function() {
            var o = this.options;
            return o.fieldDefault ? this._constrain(o.fieldDefault.exportValue(o.type)) : null;
        },
        exportValue: function(expType) {
            var o = this.options,
                d = this._data,
                value = d.value;
            if (o.type !== expType) {
                switch (expType) {
                    case 'json':
                        switch (o.type) {
                            case 'ajson':
                            case 'cjson':
                                value = [d.value];
                                break;
                            default:
                                if (o.dictionary) {
                                    value = B.dic.from(o.dictionary, value);
                                } else {
                                    value = this.element.exportField(expType);
                                }
                        }
                        break;
                    case 'astring': //!Uwaga: ewnetualna rozbudowa
                        switch (o.type) {
                            case 'json':
                                value = [d.value.v];
                                break;
                            case 'string':
                                value = d.value.split(' ');
                                break;
                            default:
                                value = this.element.exportField(expType);
                        }
                        break;
                    case 'ajson':
                    case 'cjson':
                        switch (o.type) {
                            case 'json':
                                value = [d.value];
                                break;
                            case 'string':
                                value = JSON.stringify(d.value);
                                break;
                            default:
                                value = this.element.exportField(expType);
                        }
                        break;
                    case 'float':
                        switch (o.type) {
                            case 'bool':
                                value = d.value > 0 ? 1 : 0;
                                break;
                            case 'string':
                            case 'number':
                                value = parseFloat(d.value);
                                break;
                            case 'json':
                                value = this.getValue();
                                break;
                            default:
                                value = this.element.exportField(expType);
                        }
                        break;
                    case 'number':
                        switch (o.type) {
                            case 'bool':
                                value = d.value > 0 ? 1 : 0;
                                break;
                            case 'string':
                            case 'float':
                                value = parseInt(d.value, 10);
                                break;
                            case 'json':
                                value = this.getValue();
                                break;
                            default:
                                value = this.element.exportField(expType);
                        }
                        break;
                    default:
                        switch (o.type) {
                            case 'json':
                                value = this.getValue();
                                break;
                            default:
                                value = this.element.exportField(expType);
                        }
                }
            }
            return B.obj.clone(value);
        },
        checkValue: function() {
            return this.isEmpty()
                ? 0
                : !this.options.fieldDefault || this._checkDefault() 
                    ? 1 
                    : 2;
        },
        _checkDefault: function(set) {
            if (set && this.options.fieldDefault) {
                this._data.isDefault = this._isEqualValue(this.getDefaultValue());
            }
            return this._data.isDefault;
        },
        _isEqualValue:function(value){
            var o=this.options,
                _isEqual={
                    def:function(v1, v2){
                        return B.obj.haveSameValues(B.json.isObj(v1) ? JSON.parse(v1) : v1, 
                            B.json.isObj(v2) ? JSON.parse(v2) : v2);
                    },
                    astring:function(v1, v2){
                        return B.astring.isEqual(v1, v2);
                    },
                    json:function(v1, v2){
                        return B.json.getValue(v1) === B.json.getValue(v2);
                    },
                    options:function(v1, v2){
                        return B.array.haveSameValues(v1, v2);
                    },
                    ajson:function(v1, v2){
                        return B.array.haveSameValues(v1, v2);
                    },
                    cjson:function(v1, v2){
                        return B.array.haveSameValues(v1, v2);
                    }
                };
            if(B.isFunction(_isEqual[o.type])){
                return _isEqual[o.type](this._data.value, value);
            }
            return _isEqual.def(this._data.value, value);
        },
        status: function() {
            return this._data.status;
        },
        _getFormElement:function(){
            return $('form[name='+this.options.formName+']');
        },
        _setOption: function(key, value) {
            // console.log('!!!!!field - ' + key);
            return this;
        },
        addToDictionary: function(entry) {
            if (!B.obj.isIterate(this.options.dictionary)) {
                this.options.dictionary = [];
            }
            this.options.dictionary.push(entry);
            this._trigger( 'changeDictionary', this.element, { entry: entry});
        },
        disp: function() {
            var that = this,
                o = this.options;
            this._disp(this.$disp, o.templates.disp, o.disp);
            this._changeSignal(this.$control, o.control);
            if(this.$shows){
                this.$shows.each(function(){
                    that._disp($(this), o.templates.show, o.show);
                });
            }
        },
        _disp:function($element, tmpl, options){
            if(!B.obj.is$($element)){ return; }
            var o=$.extend(true, {},
                    B.obj.is(options) ? options : {},
                    $element.data('options') || {},
                    $element.data('o') || {}
                ),
                disp;
            this._changeSignal($element, o);
            if(!o.type){ return; }
            $element.empty();
            if(o.label){
                $element.append(o.label);
            }
            disp = this._getDispData(o);
            if (isSet(disp)) {
                var i, 
                    html='',
                    ecn = this.options.ecn,
                    _html = function(value) {
                        // if(o.convert){
                        //     if (B.obj.is(o.convert)) {
                        //         value = B.convert(value, o.convert);
                        //     } else if (typeof window[o.convert] === 'function') {
                        //         value = window[o.convert](value);
                        //     }
                        // }
                        if (B.obj.is(value)) {
                            var $row = $(tmpl).fill(value, ecn);
                            $element.append($row);
                        } else {
                            html+= (tmpl ? tmpl.replace('__v__', value) : value) + ' ';
                        }
                    };
                if (Array.isArray(disp)) {
                    for (i = 0; i < disp.length; i++) {
                        _html(disp[i]);
                    }
                } else {
                    _html(disp);
                }
                if (html) {
                    $element.append(html);
                }
            }
        },
        _getDispData:function(dispOptions){             
            var that = this,
                d = this._data,
                disp = '';
            if( dispOptions.text ){
                return dispOptions.text;
            }
            if(this.isEmpty()){
                return dispOptions.empty || '';
            }
            if(dispOptions.convert){
                return B.convert(d.value, dispOptions.convert);
            }
            switch (dispOptions.type) {
                case 1:
                case '1':
                case 'str':
                    disp = d.valueStr;
                break;
                case 'bool':
                    disp = d.valueStr === '1' ? 'tak' : 'nie'; 
                break;
                case 'astring':
                case 'ajson':
                case 'cjson':
                    disp = d.value;
                break;
                case 'v':
                    disp = this.getValue();
                break;
                default:
                    var dic = typeof dispOptions.type === 'string' ? dispOptions.type.indexOf('dic') === 0 : false,
                        key = dic ? dispOptions.type.split('.')[1] || null : dispOptions.type,
                        _convert = function(value){
                                return  dic  
                                    ? that._fromDictionary(value, { name: key })
                                    : B.getValue(value, key);
                            };
                    if (Array.isArray(d.value)) {
                        disp = [];
                        for (var i = 0; i < d.value.length; i++) {
                            disp.push(_convert(d.value[i]));
                        }
                    } else {
                        disp = _convert(d.value);
                    }
            }
            return disp;
        },
        _changeSignal:function($element, options){
            if(options.signal && B.obj.is$($element)){
                var sc = options.signalClass || this.options.signalClass,
                    signal = sc[this.checkValue()];
                $element.removeClassSearch('signal-');
                if (signal) {
                    $element.addClass(signal);
                }
            }
        },
        focus: function(data) {
            if (this.element.is(':hidden')) {
                this.element.trigger('to_focus', data);
            } else {
                this.element.focus();
                if (this.options.selectOnFocus){
                    this.element[0].select();
                }
            }
        },
        _block:function(){
            B.basicWidget._block.call(this);
            if (B.obj.is$(this.$control)){
                this.$control.prop('disabled', this._blockStatus);
            }
            return this;
        },
        readonly: function(readonly) {
            if (readonly === undefined) {
                readonly = this.options.readonly;
            }else{
                this.options.readonly = readonly ? true : false;
            }
            this._toggleDisp(readonly);
            this._toggleWiget(readonly);
        },
        _toggleDisp: function(show) {
            var so = this.options.disp;
            if (so.type && so.visible > 1) {
                if (show) {
                    this.$disp.show();
                } else {
                    this.$disp.hide();
                }
            }
            return this;
        },
        _toggleWiget: function(disable){
            var fn= disable ? 'disable' : 'enable',
                _toggle=function(w){
                    if (B.obj.is(w) && typeof w[fn] === 'function') {
                            w[fn]();
                    }
                },
                widget = this.formWidget ? this.formWidget : this.element.getFormWidget();
            if(Array.isArray(widget)){
                for(var i in widget){
                    _toggle(widget[i]);
                }
            }else{
                _toggle(widget);
            }
            return this;
        },
        validate: function() {
            var o = this.options;
            this.valid.init();
            if(o.required && this.isEmpty()){
                this.valid.init('e', { 
                    label: o.label || o.name, 
                    message: this._locale('validation.required', 'Wartość wymagana') 
                } );
            }
            if(!B.obj.is(this.valid.msg)){
                this._checkLimits();
            }
            this._removeMessage();
            this.$container.removeClassSearch('is-');
            var validClass=this.valid.getClass();
            if(validClass){
                this.$container.addClass('is-' + validClass);
            }
            this._addMessage();
            return this.valid;
        },
        _checkLimits:function(){
            var that = this,
                o = this.options,
                limits=o.limits,
                types = ['error', 'warning', 'info'],
                _getFormValue=function(name){
                    return o.parent.getValue(name);
                };         
            if(B.obj.is(limits)){ 
                for (var i = 0; i < types.length; i++) {
                    if (limits.hasOwnProperty(types[i])) {
                        var limit = B.checkLimits(limits[types[i]], this._data.value, _getFormValue);
                        if (B.obj.is(limit)) {
                            this.valid.init(types[i].slice(0, 1), {
                                label : o.label || o.name,
                                message: limit.msg
                            });
                            break;
                        }
                    }
                }
            }
        },
        _removeMessage:function(){
            var $msg=this.$container.find('.field-tooltip');
            this.$container.removeClass('is-field-tooltip');
            if(B.obj.is$($msg)){
                $msg.remove();
            }
        },
        _addMessage: function() {
            if(this.valid.msg){
                var $msg= $(this.options.templates.message),
                    validClass=this.valid.getClass();
                $msg
                    .addClass('field-tooltip ' + (validClass ? validClass : '') )
                    .append(this.valid.msg.str());
                this.$container
                    .append($msg)
                    .addClass('is-field-tooltip');
            }
            // if (B.obj.is(this.options.limits)) {
            //     var message= messages ? messages.html() : null;
            //     this.element.attr('data-content', message);
            //     this.element.popover( message ? 'enable': 'disable');
            // }
        },
        _toggleMessage:function(show, callback){
            this._toggleElement(this.$container.find('.field-tooltip'), show, 'message', callback);
        }
    });

    $.widget('bajt.field', B.basicField );

    $.fn.showData = function(options){
        var $this = $(this),
            o = $.extend(true, {
                showSelector: '.row-pos'
            }, $this.data(), options || {} ),
            data = B.entity.fill($this.exportField()),
            $show = $this.parent(o.showSelector);
        if(!B.obj.is$($show)){
            $show = $(o.template).insertBefore($this);
            $show.append($this);
        }
        $show.fill(data, o.ecn);
    };
    
    $.widget('bajt.showDataCollection', $.extend(true, {}, B.basicWidget, {
        options: {
            selectors: {
                field: 'input',
                show: '.row-pos'
            }            
        },
        _createControls:function(){
            B.basicWidget._createControls.call(this);
            this._createElements(['field']);
            return this;
        },
        _initialize :function(){
            B.basicWidget._initialize.call(this);
            this._showData();
            return this;
        },
        _showData:function(){
            var o = this.options,
                showOptions = {
                    ecn: o.ecn,
                    template: o.templates.show,
                    showSelector: o.selectors.show
            };
            this.$field.each(function(){
                $(this).showData(showOptions);
            });
        }
    } ));

})(jQuery, Bajt);
