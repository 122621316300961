(function($, B) {
    'use strict';
    $.extend(true, B, {
        array:{
            areArraysSameLength:function(array1, array2){
                return Array.isArray(array1) && Array.isArray(array2) &&
                    array1.length === array2.length;               
            },
            getValues:function(array){
                var a=[];
                if(Array.isArray(array)){
                    for(var i=0; i<array.length; i++){
                        a.push(B.obj.getValueIfObject(array[i]));
                    }   
                }
                return a;
            }, 
            isEqual:function(array1, array2){
                return B.array.areArraysSameLength(array1, array2) 
                    && JSON.stringify(array1) == JSON.stringify(array2);                     
            },  
            findValue:function(array, value){
                var idx = -1,
                    carray = B.array.getValues(array);
                for(var i=0; idx < 0 && i < carray.length; i++){
                    if(carray[i] == value){
                        idx = i;
                    }
                } 
                return idx;
            },         
            haveSameValues:function(array1, array2){
                var equal=B.array.areArraysSameLength(array1, array2);
                if(equal){
                    var carray1 = B.array.getValues(array1),
                        carray2 = B.array.getValues(array2);
                    for(var i=0; equal && i<carray1.length; i++){
                        var idx = 0;
                        while(idx < carray2.length && carray2[idx] != carray1[i]){
                            idx++;
                        }
                        if(idx < carray2.length){
                            carray2.splice(idx, 1);
                        }else{
                            equal = false;
                        }
                    }
                    equal = equal && carray2.length === 0;
                }    
                return equal;
            }

        }
    });
})(jQuery, Bajt);