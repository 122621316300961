(function ($, B) {
    'use strict';
    B.modal = {
        getElement: function ($modal, name) {
            return $modal.find('.modal-' + name);
        },      
        //before modalLoadData
        loadData: function( ajaxOptions, callback, modalOptions ){
            console.log('!!! to check B.modal.loadData');
            var $modal = $('#my_modal'),
                title=B.obj.getValue('title', modalOptions, 'Pobieranie danych'),
                $modalContent = $modal.find('.modal-content .content-body'),
                _showMessages=function(msg){
                    $modal.find('.content-footer > .content-messages').showMessages(msg, '<div class="col-12"></div>');
                },
                _showErrors=function(msg){
                    $modal.find('.content-footer > .content-errors').showMessages(msg, '<div class="col-12"></div>');
                };
            $modal.attr('title', title);
            $modal.find('.modal-title').html(title);
            _showMessages(B.obj.getValue('msg', modalOptions, 'Pobieranie danych'));
            $modalContent
                .empty()
                .append(B.obj.getValue('content', modalOptions, ''))
                .append(B.html.get$('loader'));
            $modal.modal('show');
            $.ajax(ajaxOptions)
                .done(function(data) {
                    if(B.obj.is(data)){
                        if(data.errors){
                            _showErrors(data.errors);
                        }else if(data.messages){
                            _showErrors(data.errors);               
                        }else{
                            $modal.modal('hide');
                            if(typeof callback === 'function'){
                                callback(data);
                            }
                        }  
                    }else{
                        _showErrors({ type: 'e', message: 'brak danych z serwera'} );               
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    if (typeof jqXHR.responseJSON !== 'undefined') {
                        _showMessages(jqXHR.responseJSON.errors);
                    } else if (jqXHR.responseText) {
                        $modalContent.html(jqXHR.responseText);
                    } else {
                        $modalContent.html(errorThrown);
                    }
                });
        }

    };

    var _getEl = B.modal.getElement;


    $.fn.customizeModalOptions = function (options) {
        options = $.extend(true, {
            class: null,
            style: null,
            title: null,
            dialog: {
                class: null,
                style: null
            }
        }, options || {});
        var
            $dialog = _getEl(this, 'dialog');
        this.saveAttr();
        $dialog.saveAttr();
        if (options.class) {
            this.attr('class', 'modal ' + (this.hasClass('fade') ? 'fade ' : '') + options.class);
        }
        if (options.style) {
            this.attr('style', options.style);
        }
        if (options.title) {
            this.setModalTitle(options.title);
        }
        if (options.dialog.class) {
            $dialog.attr('class', 'modal-dialog ' + options.dialog.class);
        }
        if (options.dialog.style) {
            this.attr('style', options.dialog.style);
        }
        this.addClass('customized');
        return this;
    };

    $.fn.restoreModalOptions = function () {
        if (this.hasClass('customized')) {
            this
                .restoreAttr()
                .removeClass('customized');
            _getEl(this, 'dialog').restoreAttr();
        }
        return this;
    };

    $.fn.clearModal = function (elements) {
        elements = $.extend({
            title: true,
            body: true,
            footer: true
        }, elements || {});
        for (var k in elements) {
            if (elements[k]) {
                _getEl(this, k).empty();
            }
        }
        return this;
    };

    $.fn.showContent = function (content) {
        var 
            $content =  _getEl(this, 'content');
        $content.html(content);
        $content.initContent();
        //     $modal.initFormWidgets();
        this.modal('show');
    };

    $.fn.ajaxModalLoad = function (url, options) {
        options = $.extend({
            title: 'Pobieranie danych'
        }, options || {});
        // var $modal = $(this),
        //     $content = _getEl($modal, 'content'),
        //     $body = _getEl($modal, 'body');
        // options.class = options.class ? options.class + ' ' + ''
        this.emptyModal()
            .customizeModalOptions(options)
            .addClass('ajax')
            .data('url', url)
            .modal('show');

        // $body.showLoader(false);
        // $content.load(url, function(){
        //     $content.initContent();
        //     $modal.initFormWidgets();
        // });
        // if(options){
        //     $modal.customizeModal(options);
        // }
        // $modal.modal('show');
        return this;
    };

    $.fn.setModalTitle = function (title) {
        B.modal.getElement(this, 'title').html(title);
        return this;
    };

    $.fn.emptyModal = function () {
        var elements = ['title', 'body', 'footer'];
        for (var k in elements) {
            B.modal.getElement(this, elements[k]).empty();
        }
        return this;
    };



})(jQuery, Bajt);